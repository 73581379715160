<div class="option-bar-root-container" [style.width]="show? 'var(--left-side-optionbar-width)':'0px'">
    <div class="menu-list-container"></div>
    <div class="filter-container">
        <!-- Segments List -->
         <div class="section-container segment-list-container" style="display: none;"> <!-- hide now -->
            <div class="title" (click)="showSegment=!showSegment">
                <mat-icon [style.transform]="showSegment ? 'rotate(180deg)' : 'rotate(0)'">arrow_drop_down</mat-icon>
                <span>Segments</span>
                <div *ngIf="this.selectedSegment!==undefined" class="close-btn"
                    (click)="resetQuickFilters(true);$event.stopPropagation();"><svg xmlns="http://www.w3.org/2000/svg"
                        width="18" viewBox="0 0 92 92" id="tag">
                        <path style="fill: #ff0057 "
                            d="M88 20H29c-1 0-1.9.4-2.6 1l-25 22c-.9.8-1.4 1.9-1.4 3 0 1.2.5 2.2 1.4 3l25 22c.7.6 1.7 1 2.6 1h59c2.2 0 4-1.8 4-4V24c0-2.2-1.8-4-4-4zm-4 44H30.5L10.1 46l20.5-18H84v36zM44.1 51.4l5.4-5.4-5.4-5.4c-1.4-1.4-1.4-3.6 0-4.9 1.4-1.4 3.6-1.4 4.9 0l5.4 5.4 5.4-5.4c1.4-1.4 3.6-1.4 4.9 0 1.4 1.4 1.4 3.6 0 4.9L59.4 46l5.4 5.4c1.4 1.4 1.4 3.6 0 4.9-.7.7-1.6 1-2.5 1s-1.8-.3-2.5-1l-5.4-5.4-5.4 5.4c-.7.7-1.6 1-2.5 1-.9 0-1.8-.3-2.5-1-1.2-1.3-1.2-3.5.1-4.9z">
                        </path>
                    </svg></div>
            </div>
            <div class="content item-content" [style.max-height]=" showSegment ? '350px' : '0px' ">
                <ng-container *ngFor="let segment of segments">
                    <div [ngClass]=" {'selected-option' : segment.id===selectedSegment?.id}" class="item-container"
                        (click)="onSegmentSelected(segment)">
                        <div class="item-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"
                                viewBox="0 0 24 24" id="dashboard">
                                <path style="fill: #ff0057 !important"
                                    d="M10.5,13h-7C3.2,13,3,13.2,3,13.5v7C3,20.8,3.2,21,3.5,21h7c0.3,0,0.5-0.2,0.5-0.5v-7C11,13.2,10.8,13,10.5,13z M10,20H4v-6h6V20z M10.5,3h-7C3.2,3,3,3.2,3,3.5v7C3,10.8,3.2,11,3.5,11h7c0.3,0,0.5-0.2,0.5-0.5v-7C11,3.2,10.8,3,10.5,3z M10,10H4V4h6V10z M20.5,3h-7C13.2,3,13,3.2,13,3.5v7c0,0.3,0.2,0.5,0.5,0.5h7c0.3,0,0.5-0.2,0.5-0.5v-7C21,3.2,20.8,3,20.5,3z M20,10h-6V4h6V10z M20.5,16.5h-3v-3c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v3h-3c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h3v3c0,0.3,0.2,0.5,0.5,0.5h0c0.3,0,0.5-0.2,0.5-0.5v-3h3c0.3,0,0.5-0.2,0.5-0.5S20.8,16.5,20.5,16.5z">
                                </path>
                            </svg>
                        </div>
                        <span class="item-name">{{segment.code}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
        <!-- Tag List -->
        <!-- <div class="section-container tag-list-container">
            <div class="title" (click)="showTag=!showTag">
                <mat-icon [style.transform]="showTag ? 'rotate(180deg)' : 'rotate(0)'">arrow_drop_down</mat-icon>
                <span>Tags</span>
                <div *ngIf="this.selectedTag!==undefined" class="close-btn"
                    (click)="resetQuickFilters(true);$event.stopPropagation();"><svg xmlns="http://www.w3.org/2000/svg"
                        width="18" viewBox="0 0 92 92" id="tag">
                        <path style="fill: #ff0057 !important"
                            d="M88 20H29c-1 0-1.9.4-2.6 1l-25 22c-.9.8-1.4 1.9-1.4 3 0 1.2.5 2.2 1.4 3l25 22c.7.6 1.7 1 2.6 1h59c2.2 0 4-1.8 4-4V24c0-2.2-1.8-4-4-4zm-4 44H30.5L10.1 46l20.5-18H84v36zM44.1 51.4l5.4-5.4-5.4-5.4c-1.4-1.4-1.4-3.6 0-4.9 1.4-1.4 3.6-1.4 4.9 0l5.4 5.4 5.4-5.4c1.4-1.4 3.6-1.4 4.9 0 1.4 1.4 1.4 3.6 0 4.9L59.4 46l5.4 5.4c1.4 1.4 1.4 3.6 0 4.9-.7.7-1.6 1-2.5 1s-1.8-.3-2.5-1l-5.4-5.4-5.4 5.4c-.7.7-1.6 1-2.5 1-.9 0-1.8-.3-2.5-1-1.2-1.3-1.2-3.5.1-4.9z">
                        </path>
                    </svg></div>
            </div>
            <div class="content item-content" [style.max-height]=" showTag ? '350px' : '0px' ">
                <ng-container *ngFor="let tag of tags">
                    <div [ngClass]=" {'selected-option' : tag.id===selectedTag?.id}" class="item-container"
                        (click)="onTagSelected(tag)">
                        <div class="item-icon">
                            <svg viewBox="0 0 100 80">
                                <path d="M0 0 L100 0 L80 40 L100 80 L0 80 L0 0 Z" [attr.fill]="tag.bg_color"></path>
                            </svg>
                        </div>
                        <span class="item-name">{{tag.code}}</span>
                    </div>
                </ng-container>
            </div>
        </div> -->
        <!-- Filter List -->
        <div class="section-container filter-list-container">
            <div class="title" (click)="showFilter=!showFilter">
                <mat-icon [style.transform]="showFilter ? 'rotate(180deg)' : 'rotate(0)'">arrow_drop_down</mat-icon>
                <span>Filters</span>
                <div *ngIf="this.selectedFilter!==undefined" class="close-btn"
                    (click)="resetQuickFilters(true);$event.stopPropagation();"><svg xmlns="http://www.w3.org/2000/svg"
                        width="18" viewBox="0 0 92 92" id="tag">
                        <path style="fill: #ff0057 !important"
                            d="M88 20H29c-1 0-1.9.4-2.6 1l-25 22c-.9.8-1.4 1.9-1.4 3 0 1.2.5 2.2 1.4 3l25 22c.7.6 1.7 1 2.6 1h59c2.2 0 4-1.8 4-4V24c0-2.2-1.8-4-4-4zm-4 44H30.5L10.1 46l20.5-18H84v36zM44.1 51.4l5.4-5.4-5.4-5.4c-1.4-1.4-1.4-3.6 0-4.9 1.4-1.4 3.6-1.4 4.9 0l5.4 5.4 5.4-5.4c1.4-1.4 3.6-1.4 4.9 0 1.4 1.4 1.4 3.6 0 4.9L59.4 46l5.4 5.4c1.4 1.4 1.4 3.6 0 4.9-.7.7-1.6 1-2.5 1s-1.8-.3-2.5-1l-5.4-5.4-5.4 5.4c-.7.7-1.6 1-2.5 1-.9 0-1.8-.3-2.5-1-1.2-1.3-1.2-3.5.1-4.9z">
                        </path>
                    </svg></div>
            </div>
            <div class="content item-content" [style.max-height]=" showFilter ? '350px' : '0px' ">
                <ng-container *ngFor="let filter of filters">
                    <div [ngClass]=" {'selected-option' : filter.id===selectedFilter?.id}" class="item-container"
                        (click)="onFilterSelected(filter)">
                        <div class="item-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" id="filter">
                                <path style="fill: #018786  !important"
                                    d="m1 10.399 19 20v18.405l10-6.25V30.399l19-20V1H1v9.399zM3 3h44v6.601l-19 20v11.845l-6 3.75V29.601l-19-20V3z">
                                </path>
                            </svg>
                        </div>
                        <span class="item-name">{{filter.name}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<h1 mat-dialog-title class="edit-form-title fixed-title">{{ browseBy == 'product' ? 'Products' : 'Category' }}</h1>
<mat-dialog-content class="edit-form-content">
  <mat-form-field  class="fixed-filter">
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
  </mat-form-field>
  <mat-checkbox [(ngModel)]="showSelected" (change)="showSelectedItems()">Show selected items only</mat-checkbox>

  <div class="scrollable-content">
    <table mat-table [dataSource]="dataSource">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef style="width: 50px;">
        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let item"> {{ item.name }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer">
  <button mat-raised-button color="primary" (click)="save()">OK</button>
  <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { DashboardEditComponent } from './dashboard-edit/dashboard-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from './dashboard.service';
import { DashboardModel } from './model/dashboard.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
 encapsulation:ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  infoList?: DashboardModel[];
  showSearch: boolean = false;

  menuOption: FloatingMenuOption = {
    add: true,
    search: false,
    export: true,
    more: true
  }

  constructor(private dashboardService: DashboardService, public dialog: MatDialog, private snackBar: MatSnackBar, private snackbarService: SnackBarService) {

  }

  ngOnInit(): void {
    this.infoList = this.dashboardService.getInfoList();
  }

  searchClicked(): void {
    this.showSearch = !this.showSearch
  }

  addClicked(): void {
    let dashBoardInfo: DashboardModel = { active: true };
    const dialogRef = this.dialog.open(DashboardEditComponent, {
      width: '400px',
      height: '400px',
      data: dashBoardInfo
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('New item:', result);
        this.infoList?.push(result);
        this.openSnackBar("Dashboard information has been update.", 'Dismiss');
      } else {
        console.log('New canceled');
      }
    });
  }

  openSnackBar(message: string, action: string) {

    let SnackBarData:SnackBarData= {
      title:"Added",
      type:"warn",
      message:"Data has been saved successfully."
    }
    this.snackbarService.openSnackBar(SnackBarData);

  }

}

import { Component } from '@angular/core';
import { RfmSettingsService } from './rfm-settings.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';

@Component({
  selector: 'app-rfm-settings',
  templateUrl: './rfm-settings.component.html',
  styleUrls: ['./rfm-settings.component.scss']
})
export class RfmSettingsComponent {
  title: string = "RFM Settings";
  recencyDataList: any; // Variable to store the retrieved data
  frequencyDataList: any; // Variable to store the retrieved data
  monetaryDataList: any; // Variable to store the retrieved data
  dataList: any[] = []; // Variable to store the retrieved data

  displayedColumns = ['from', 'to','score', 'rating'];
  constructor( 
    private rfmSettingsService: RfmSettingsService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private router: Router) {

    this.dataList = [];
  }
  /**
   * initilaize the device model
   */
  ngOnInit(): void {
     

    this.loadData();
  }

  
   

   /**
   * loads the segment data based on the conditions
   */
   loadData(): void {
    
    let response = this.rfmSettingsService.getList().subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.dataList   = response.data.rfm_settings;
        this.recencyDataList = this.dataList.find(item => item.rfm_type =="RECENCY");
        this.frequencyDataList = this.dataList.find(item => item.rfm_type =="FREQUENCY");
        this.monetaryDataList = this.dataList.find(item => item.rfm_type =="MONETARY");

      }   
    });

  }

}

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { RequestData } from 'src/app/common/models/request-data.model';
import { environment } from 'src/environments/environment';
import { CustomerModel } from './model/customer.model';
import { FilterListItemModel } from 'src/app/common/components/search-filter/search-filter.component';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  /**
   * 
   * @returns The list of customers
   * Calls the API to get the list of customers from server
   */
  getList(requestData: RequestData): Observable<any> {

    return this.http.post(environment.baseUrl + '/customer/get', requestData);

  }
  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.baseUrl + '/customer/export-customer-list', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }



  


  getId(id: number): Observable<any> {
    return this.http.post(environment.baseUrl + '/customer/getById/' + id, '');

  }

  /**
   * 
   * @param customerInfo 
   * Calls the update API to insert/update the record
   */
  update(customerInfo: CustomerModel): Observable<any> {
    if (customerInfo.hasOwnProperty("id")) {
      return this.http.post(environment.baseUrl + '/customer/update', customerInfo);
    } else {
      return this.http.post(environment.baseUrl + '/customer/addCustomer', customerInfo);
    }
  }


  /**
   * 
   * @param customerInfo 
   * Calls the API to delete the record
   */
  delete(customerInfo: CustomerModel): Observable<any> {
    return this.http.post(environment.baseUrl + '/customer/delete/' + customerInfo.id, customerInfo);
  }
  // updateCustomerTag(customerInfo: any): Observable<any> {
  //   if(customerInfo.hasOwnProperty("id")){
  //     return this.http.post(environment.baseUrl+'/customertag/update', customerInfo);
  //   } else{
  //     return this.http.post(environment.baseUrl+'/customertag/add', customerInfo);
  //   }
  // }
  /**
   * 
   * @param customerInfo 
   * Calls the API to update the segment  of selected customers
   */
  updateCustomerSegment(customerInfo: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/customer/segment/update', customerInfo);

  }
  /**
   * 
   * @param customerInfo 
   * Calls the API to update the tags  of selected customers
   */
  updateCustomerTag(customerInfo: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/customer/tag/update', customerInfo);

  }


  /**
  * 
  * @param customerEmail
  * Calls the API to update the customer email
  */
  updateCustomerEmail(customerEmail: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/customer/updateCustomerEmail', customerEmail);

  }

  /**
  * 
  * @param customerNote
  * Calls the API to update the customer notes
  */


  updateCustomerNote(customerNote: any): Observable<any> {

    return this.http.post(environment.baseUrl + '/customertag/add-note', customerNote);
  }

  /**
   * Define the customer filter criteria
   * @returns 
   */
  getFilter(): FilterListItemModel[] {
    return [
      {
        title: "Customer",
        column: "CONCAT_WS('',first_name, ' ', last_name )",
       
      },
      {
        title: "Phone",
        column: "phone_number",
        type: "string",
        operators: [
          { title: "is", operator: "=" },
        ],
      },

      {
        title: "Email",
        column: "email",
        type: "string",
        operators: [
          { title: "is", operator: "=" },
          { title: "like", operator: "like" },

        ],
      },
      {
        title: "Recency(RFM)",
        column: "recency",
        type: "number",
        operators: [
          { title: "is", operator: "=" },
          { title: ">", operator: ">=" },
          { title: "<", operator: "<=" },

        ],
        
        
        
      },
      {
        title: "Frequency(RFM)",
        column: "frequency",
        type: "number",
        operators: [
          { title: "is", operator: "=" },
          { title: ">", operator: ">=" },
          { title: "<", operator: "<=" },

        ],
      },
      {
        title: "Amount(RFM)",
        column: "monetary",
        type: "number",
        operators: [
          { title: "is", operator: "=" },
          { title: ">", operator: ">=" },
          { title: "<", operator: "<=" },

        ],
      },
      {
        title: "Order Count",
        column: "frequency",
        type: "number",
        operators: [
          { title: "is", operator: "=" },
          { title: ">", operator: ">=" },
          { title: "<", operator: "<=" },

        ],
      },
      {
        title: "Top Purchases Time",
        column: "top_day_part",
        type: "list",
        operators: [
          { title: "is", operator: "=" },
        ],
        values: [
          { title: "BREAKFAST", value: "BREAKFAST" },
          { title: "LUNCH", value: "LUNCH" },
          { title: "TEA", value: "TEA" },
          { title: "DINNER", value: "DINNER" },

        ]
      },
      {
        title: "Member Since",
        column: "mem_since",
        type: "string"
      },
      {
        title: "Birth Month",
        column: "dob_month",
        //type: "number",
        operators: [
          { title: "is", operator: "=" },
        ],
        values: [
          { title: "January", value: "01" },
          { title: "February", value: "02" },
          { title: "March", value: "03" },
          { title: "April", value: "04" },
          { title: "May", value: "05" },
          { title: "June", value: "06" },
          { title: "July", value: "07" },
          { title: "August", value: "08" },
          { title: "September", value: "09" },
          { title: "October", value: "10" },
          { title: "November", value: "11" },
          { title: "December", value: "12" },
        ]
      },

      {
        title: "Birth Day",
        column: "dob_day",
        operators: [
          { title: "is", operator: "=" },
        ],
        values: [
          { title: "1", value: "1" },
          { title: "2", value: "2" },
          { title: "3", value: "3" },
          { title: "4", value: "4" },
          { title: "5", value: "5" },
          { title: "6", value: "6" },
          { title: "7", value: "7" },
          { title: "8", value: "8" },
          { title: "9", value: "9" },
          { title: "10", value: "10" },
          { title: "11", value: "11" },
          { title: "12", value: "12" },
          { title: "13", value: "13" },
          { title: "14", value: "14" },
          { title: "15", value: "15" },
          { title: "16", value: "16" },
          { title: "17", value: "17" },
          { title: "18", value: "18" },
          { title: "19", value: "19" },
          { title: "20", value: "20" },
          { title: "21", value: "21" },
          { title: "22", value: "22" },
          { title: "23", value: "23" },
          { title: "24", value: "24" },
          { title: "25", value: "25" },
          { title: "26", value: "26" },
          { title: "27", value: "27" },
          { title: "28", value: "28" },
          { title: "29", value: "29" },
          { title: "30", value: "30" },
          { title: "31", value: "31" },
        ]

      },
      {
        title: "Gender",
        column: "gender",
        type: 'string',
        operators: [
          { title: "is", operator: "=" },
        ],
        values: [
          { title: "Male", value: "m" },
          { title: "Female", value: "f" },
        ]
      },
      {
        title: "Active",
        column: "customer.is_valid",
        type: "boolean",
        operators: [
          { title: "is", operator: "=" },
        ],
        values: [
          { title: "Active", value: "1" },
          { title: "Not Active", value: "0" },
        ]
      },
      {
        title: "Customer Type",
        column: "customer_type",
        type: "list",
        operators: [
          { title: "is", operator: "=" },
        ],
        values: [
          { title: "Guest", value: "2" },
          { title: "Registered", value: "1" },
          { title: "Social", value: "0" },

        ]
      },
      {
        title: "Date of Joining",
        column: "doj",
        type: "date",
        operators: [
          { title: "is", operator: "=" },
          { title: ">", operator: ">=" },
          { title: "<", operator: "<=" },

        ],
      },
      
    ];
  }
}


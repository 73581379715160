<h1 mat-dialog-title class="edit-form-title">Change Password</h1>
<mat-dialog-content class="edit-form-content">
    <form class="edit-form" [formGroup]="editForm" (ngSubmit)="save()">
        <div class="content-container">
            <mat-form-field class="user-input password">
                <mat-label>New Password</mat-label>
                <input matInput formControlName="password" type="password" maxlength="50">
                <mat-error *ngIf="editForm.controls['password'].hasError('required')">Password should be entered</mat-error>

            </mat-form-field>
            <mat-form-field class="user-input confirm_password">
                <mat-label>Confirm Password</mat-label>
                <input matInput formControlName="confirm_password" type="password" maxlength="50">
                <mat-error *ngIf="editForm.controls['confirm_password'].hasError('required')">Confirm Password should be entered</mat-error>

            </mat-form-field>      
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer">
    <button mat-raised-button color="primary" (click)="save()">Update</button>
    <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
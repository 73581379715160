<div class="edit-form-container">
    <h2 class="edit-form-title">{{getTitle()}}</h2>
    <div class="edit-form-content">
        <form class="edit-form" [formGroup]="editForm" (ngSubmit)="save()">
            <div class="content-contianer">
                <mat-form-field class="title">
                    <mat-label>Title</mat-label>
                    <input matInput formControlName="title" [(ngModel)]="item.title">
                </mat-form-field>
                <mat-form-field class="content">
                    <mat-label>Contect</mat-label>
                    <input matInput formControlName="content" [(ngModel)]="item.content">
                </mat-form-field>
                <div class="status-toggle-container">
                    <span class="status-toggle-title">Active</span>
                    <mat-slide-toggle formControlName="active" class="status-toggle" [(ngModel)]="item.active" ></mat-slide-toggle>
                </div>
            </div>
            <div class="action-container">
                <button mat-raised-button color="primary">Save</button>
                <button mat-button (click)="cancel()">Cancel</button>
            </div>
        </form>
    </div>
</div>
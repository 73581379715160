<h1 mat-dialog-title>
    {{ data.title }}
  </h1>
  <div mat-dialog-content>
    <p>{{ data.message }}</p>
  </div>
  
  <div mat-dialog-actions class="mdc-action-container">
    <button mat-button (click)="onCloseClick()">No</button>
    <button mat-button  mat-raised-button color="primary" color="primary" (click)="onConfirmClick()">Yes</button>
  </div>
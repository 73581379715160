<h1 mat-dialog-title>
    {{title}}
  </h1>
  
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>
  
  <div mat-dialog-actions class="mdc-action-container">
    <button *ngIf="showNoButton" mat-raised-button color="primary"(click)="onDismiss()">{{noButtonCaption}}</button>
    <button mat-button	(click)="onConfirm()">{{yesButtonCaption}}</button>
  </div>
<div class="summary-page-root-container reward-category-summary-root-container"
    [style.width]=" show ? '320px' : '0px' ">
    <div [ngClass]="{ 'close-btn' : show , 'open-btn' :!show  }">
        <button mat-icon-button (click)="close()">
            @if(show){
                <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 24 24" enable-background="new 0 0 24 24"
                    xml:space="preserve" fill="#5F6368">
                    <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"></path>
                    <path fill="none" d="M0,0h24v24H0V0z"></path>
                </svg>
                }@else{
                <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" style="transform: rotate(180deg);"
                    xml:space="preserve" fill="#5F6368">
                    <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"></path>
                    <path fill="none" d="M0,0h24v24H0V0z"></path>
                </svg>
                }
        </button>
    </div>
    @if(show && rewardCategory!==undefined){
    <div class="page-container">

        <div class="page-header">
            <div class="title">{{rewardCategory.code}} </div>

        </div>
        <div class="page-content">
            <div class="section description-container">
                <span>{{rewardCategory.description}}</span>
            </div>

            <div class="section status-container">
                <div class="reward-category-actieve-status">
                    
                    <!-- <div *ngIf="rewardCategory.is_valid==1" class="status active">
                        {{ rewardCategory.is_valid}}
                    </div>
                    <div *ngIf="rewardCategory.is_valid!==1" class="status inactive">
                        {{ rewardCategory.is_valid}}
                    </div> -->
                    
                        <div class="status active">
                            @if(rewardCategory.is_valid === "1"){ 
                                Active}@else{ Inactive}
                        </div>
                   
                        <!-- <div class="status inactive">
                            @if(rewardCategory.is_valid === "0"){    }
                        </div>  -->
           
                </div>
            </div>
            <div class="section-content" style="margin-left: 10px; margin-top: 10px">
                <ng-container>
                    <strong>Created</strong><br /> Admin &nbsp;&nbsp;&nbsp;&nbsp;
                    {{rewardCategory.created_at | date : dateTimeFormat }} </ng-container><br /><br />
                <ng-container>
                    <strong>Updated</strong><br /> Admin &nbsp;&nbsp;&nbsp;&nbsp;
                    {{rewardCategory.updated_at| date : dateTimeFormat}}
                </ng-container>
            </div>

        </div>
        <div class="page-footer">
            <button mat-button color="primary" class="btn-edit"
                (click)="onEditAction()"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button color="accent" class="btn-delete" (click)="onDeleteAction()">
                <mat-icon>delete</mat-icon>Delete</button>
        </div>
    </div>
    }
    @else if(show && rewardCategory==undefined){
    <div class="page-container">
        <img src="assets/images/side-bar-no-data.png">
        <span class="no-data-info">Nothing to show. Please select a reward category.</span>
    </div>
    }@else{
    }

</div>
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  BillDiscountAmountModel,
  BillDiscountItemModel,
  SaleItemDiscountModel,
  BuyXGetYDicountModel as BuyXGetYDicountModel,
  RewardModel,
  CustomerFilter,
  SaleChannels, AccessMode
} from "../model/reward.model";
import { RewardService } from "../reward.service";
import { SnackBarData } from "src/app/common/components/snackbar/model/snackbar.model";
import { SnackBarService } from "src/app/common/components/snackbar/snackbar.service";
import { DomSanitizer } from '@angular/platform-browser';
import { PermissionService } from "src/app/auth/permission.service";

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DirtyCheck } from "src/app/common/guards/form-deactivate.guard";
import { SegmentService } from "../../segment/segment.service";
import { RewardCategoryService } from "../../reward-category/reward-category.service";
import { RewardSegmentEditDialogComponent } from "../dialogs/reward-segment-edit-dialog/reward-segment-edit-dialog.component";
import { RewardProductBrowseDialogComponent } from "../dialogs/reward-product-browse-dialog/reward-product-browse-dialog.component";
import { RewardProductItemsDialogComponent } from "../dialogs/reward-product-items-dialog/reward-product-items-dialog.component";
import { DiscountModel } from "../../discount/model/discount.model";
import { DatePipe } from "@angular/common";
import { forkJoin } from "rxjs";
import { ImageUploadComponent } from "../dialogs/image-upload/image-upload.component";
import { environment } from "src/environments/environment";
import { getMatIconFailedToSanitizeLiteralError } from "@angular/material/icon";
import { CustomerService } from "../../customer/customer.service";
import { RewardCustFilterDialogComponent } from "../dialogs/reward-cust-filter-dialog/reward-cust-filter-dialog.component";
import { trigger } from "@angular/animations";
import { RewardProductsDialogComponent } from "../dialogs/reward-products-dialog/reward-products-dialog.component";
import { formatDate } from '@angular/common';
import { ConfirmDialogComponent, ConfirmDialogModel } from "src/app/common/components/confirm-dialog/confirm-dialog.component";
import { SystemSettingsService } from "src/app/common/services/system-settings.service";

@Component({
  selector: "app-reward-edit",
  templateUrl: "./reward-edit.component.html",
  styleUrls: ["./reward-edit.component.scss"],
  providers: [
    DatePipe, // Add DatePipe to providers
  ],
  animations: [
    trigger("if", [
      // ... animation states and styles
    ]),
  ],
})
export class RewardEditComponent implements DirtyCheck, OnInit {
  static readonly CUSTOMER_ELIGIBILITY_ALL = 1;
  static readonly CUSTOMER_ELIGIBILITY_FILTER = 2;

  title: string = "New ";
  editForm!: FormGroup;
  currentDate: Date = new Date(); // Initialize it with the current date.
  isInsertMode: boolean = true; // Set this to false when updating a record.
  isAutoDistribute: boolean = false;
  value!: number;
  radioButtonValue!: string;
  duplicateItems: any[] = [];
  @ViewChild("fileInput") fileInput!: ElementRef;

  rewardInfoModel: RewardModel = {
    id: 0,
    code: "",
    name: "",
    description: "",
    short_description: "",
    status: 0,
    reward_type: 5,
    discount_id: 0,
    discount: {
      id: 0,
      code: "",
      name: "",
      description: "",
      activation_mode: 1,
      buy: undefined,
      offer: undefined,
      parent: 0,
    },
    crm_reward_category_id: 0,
    display_order: 0,
    customer_filter: undefined,
    shop_filter: undefined,
    sales_chanels: [],
    image_file_name: "",
    published_on: undefined,
    is_active: 1,
    valid_for: 0,
    is_event_driven: 0,
    crm_event_id: undefined,
    reward_eligibility: 1,
    validity_type: 0,
    show_in_offers: 1,
    use_within: 0,
    enabled_for: [],
    type: "html",
    url_content: "",
    external_url: "html|",
    apply_customer_filter: 2
  };

  billDiscountByOrderValue: BillDiscountAmountModel = {
    buy: {
      based_on: "order_amount",
      trigger_on: {
        amount: 0,
      },
    },
    offer: {
      type: "discount",
      offer: {
        is_percentage: 1,
        variance: 0,
      },
    },
  };

  billDiscountItemModel: BillDiscountItemModel = {
    buy: {
      based_on: "order_amount",
      trigger_on: {
        amount: undefined,
      },
    },
    offer: {
      type: "sale_item",
      offer: [],
    },
  };

  saleItemDiscountModel: SaleItemDiscountModel = {
    buy: {
      based_on: "sale_item",
      trigger_on: [],
    },
    offer: {
      type: "discount",
      offer: {
        is_percentage: 1,
        variance: 10,
      },
    },
  };

  buyXGetYDicountModel: BuyXGetYDicountModel = {
    buy: {
      based_on: "sale_item",
      trigger_on: [],
    },
    offer: {
      type: "sale_item",
      offer: [],
    },
  };

  saleItemByDiscountParam = { buy: { qty: 1 } };
  buyXgetYDiscountParam = {
    buy: { qty: 1 },
    offer: { qty: 1, isPercentage: 1, variance: 0 },
  };
  publishOn!: Date;
  endDate!: Date;
  selectedValidityOption!: string;



  isFormDirty: boolean = false;
  // selectedCustomerEligibilityOption: number =
  //   RewardEditComponent.CUSTOMER_ELIGIBILITY_ALL;

  discountList: any;
  segmentList: any;
  rewardCategoryList: any[] = [];
  selectedEventOption: string = "1";
  mailTemplateList: any;
  productList: any;
  itemCategoryList: any;
  isDropdownOpen: boolean = false;
  assetsUrl: string;
  refreshTime: any;
  systemEvent: any;
  selectedSystemEvent: any;
  status: any;
  isStatusZero!: boolean;
  isEmptyStatus!: boolean;
  isStatusNotOneOrTwo: boolean = true; // Default value, adjust as needed
  isNewRecord!: boolean;
  filteredCategoryList: any;
  showRadioBtns!: boolean;
  enabledRewards!: number[];
  dateFormat: string;
  allowPublishManually: boolean = false;
  permission: any;

  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'align': [] }],       // toggled buttons
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      // [{ 'script': 'sub' }, { 'script': 'super' }], // Subscript, superscript
      [{ 'color': [] }, { 'background': [] }],
      ['link', 'image'],
      // [{ 'color': [] }, { 'background': [] }],  // Text color and background color
    ]
  };

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  private uploadedFileName: string = "";

  constructor(
    private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    public rewardService: RewardService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private segmentService: SegmentService,
    private rewardCategoryService: RewardCategoryService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private systemSettingService: SystemSettingsService,
    private sanitizer: DomSanitizer,
    private permissionService: PermissionService
  ) {
    this.assetsUrl = environment.assetsUrl;
    this.dateFormat = systemSettingService.getDefaultDateFormat();

    this.permission = permissionService.getPagePermission('reward');

  }


  ngOnInit(): void {
    this.loadRewardCategoryList();
    this.loadDiscountList();
    this.loadProducts();
    this.loadMailTemplateList();
    this.loadItemCategoryList();
    this.loadSystemEvents();
    this.calculateEndDate(); // Add this line
    this.endDate = new Date();
    // Set 'html' as default if external_url is null
    if (!this.rewardInfoModel.external_url) {
      this.rewardInfoModel.external_url = 'html|';
    }

    let rewardId = this.route.snapshot.paramMap.get("id");

    this.editForm = this.builder.group({
      code: [
        this.rewardInfoModel.code,
        [Validators.required, Validators.maxLength(50)],
      ],
      name: [this.rewardInfoModel.name, [Validators.required]],
      description: [this.rewardInfoModel.description],
      short_description: [this.rewardInfoModel.short_description, [Validators.maxLength(100)]],

      crm_reward_category_id: [
        this.rewardInfoModel.crm_reward_category_id,
        [],
      ],
      display_order: [this.rewardInfoModel.display_order, [Validators.required]],
      brevo_mail_template_id: [
        this.rewardInfoModel.brevo_mail_template_id,
        [],
      ],
    });

    this.editForm.valueChanges.subscribe(() => {
      if (this.status !== 0) {
        this.isFormDirty = false;

      } else {
        this.isFormDirty = !this.editForm.pristine
      }
    });

    if (rewardId !== null && parseInt(rewardId) !== 0) {
      this.loadSavedReward(rewardId);
    }
  }

  isDirty(): boolean {
    return (this.permission.can_edit && this.isFormDirty);
  }

  isDateInFuture() {
    return this.publishOn >= this.currentDate;
  }
  // Format a date using DatePipe
  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy') || '';
  }


  // Function to handle the change when the checkbox is clicked
  onAutoDistributeChanged() {
    this.rewardInfoModel.is_event_driven = this.rewardInfoModel.is_event_driven
      ? 1
      : 0;

    // If auto-distribute is checked, reset the selected event
    if (!this.rewardInfoModel.is_event_driven) {
      this.rewardInfoModel.crm_event_id = 0;
    }
    this.isFormDirty = true;
  }

  // Function to handle the change when an event is selected in the dropdown
  onEventSelected(eventId: any) {
    this.rewardInfoModel.reward_type = 5; // Assuming the default reward is 5
  
    this.selectedSystemEvent = this.systemEvent.find(((sc: any) => sc.id == eventId));

    // Check if selectedSystemEvent is defined and has enabled_rewards property
    if (this.selectedSystemEvent && this.selectedSystemEvent.enabled_rewards !== undefined) {
      try {
        // Parse enabled_rewards assuming it's a JSON string
        this.selectedSystemEvent.enabled_rewards = JSON.parse(this.selectedSystemEvent.enabled_rewards);

        // Assign the first reward as default if enabled_rewards has at least one reward
        if (Array.isArray(this.selectedSystemEvent.enabled_rewards) && this.selectedSystemEvent.enabled_rewards.length > 0) {
          this.rewardInfoModel.reward_type = this.selectedSystemEvent.enabled_rewards[0];
        } else {
          // Handle case when there are no rewards
          console.error("No rewards found for the event.");
        }
      } catch (error) {
        // Handle JSON parsing error
        console.error("Error parsing enabled_rewards:", error);
      }
    } else {
      // Handle case when enabled_rewards is undefined
      console.error("enabled_rewards is undefined.");
      // Set default reward
      this.rewardInfoModel.reward_type = 5; // Assuming the default reward is 5
    }


    // Assign other properties
    
    this.rewardInfoModel.use_within = this.selectedSystemEvent ? this.selectedSystemEvent.use_within : 0;
    this.billDiscountItemModel.buy.trigger_on.amount = this.selectedSystemEvent.buy_amount;
    this.buyXgetYDiscountParam.buy.qty = this.selectedSystemEvent.buy_quantity;
    this.buyXgetYDiscountParam.offer.qty = this.selectedSystemEvent.offer_quantity;
    this.buyXgetYDiscountParam.offer.variance = this.selectedSystemEvent.offer_amount;
    this.buyXgetYDiscountParam.offer.isPercentage = this.selectedSystemEvent.is_offer_percentage;

  }


  /**
   * Returns the display label for segment
   */
  getSegmentLabel(id: number): string {
    let segment = this.segmentList.find((s: any) => s.id == id);
    return segment.code;
  }

  navBack(): void {
    this.router.navigate(["/reward"]);
  }
  onFileSelected(event: Event) {
    const inputElement = this.fileInput?.nativeElement as HTMLInputElement; // Add a null check
    const files = inputElement?.files; // Add a null check

    if (files && files.length > 0) {
      // Check if files is not null
      const selectedFile = files[0];
      this.uploadFile(selectedFile);
    }
  }

  getImageUrl(): string {
    let imageUrl = this.assetsUrl + this.rewardInfoModel.image_file_name;
    return imageUrl;
  }

  /**
   * Selecting Image
   */
  selectImage() {
    // Get the rewardid value from the URL or set it to zero if not found
    const dialogRef = this.dialog.open(ImageUploadComponent, {
      width: "400px", // Adjust the width as needed
      // Pass the rewardid as data to the dialog component
      data: { rewardId: this.rewardInfoModel.id },
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.uploadedFileName = result;
        this.refreshTime = Date.now();
        this.rewardInfoModel.image_file_name = "";
        this.rewardInfoModel.image_file_name = result;
        // Handle the uploaded image (result) here
      }
    });
    this.isFormDirty = true;
  }
  /**
   * Delete Image
   */
  /**
   * On delete image selected
   * Confirm the user action and call the API to delete the image
   */
  deleteImage(rewardId: any): void {
    let imageFileName = this.rewardInfoModel.image_file_name; // Assuming you have the file name stored in your itemInfo

    const message = `Are you sure you want to delete the image associated with ` + (this.rewardInfoModel.name + "?");
    const dialogData = new ConfirmDialogModel("Confirm Deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {

        // Call the API to delete the image
        this.rewardService.deleteImage(rewardId).subscribe({
          next: (response) => {
            if (response.status == 'SUCCESS') {
              // handle success
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Image Deleted',
                message: 'The image associated with ' + this.rewardInfoModel.name + " has been deleted successfully."
              }
              this.snackBarService.openSnackBar(snckBarData);
              // Optionally, you might want to update the itemInfo object to remove the image file name
              this.rewardInfoModel.image_file_name = "";

            } else {
              let snackBarData: SnackBarData = {
                message: response.message,
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
            }
          },
          error: (error) => {
            let snackBarData: SnackBarData = {
              message: 'Failed to delete image. Connection to the server failed',
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        });
      }
    });
  }

  publishReward(rewardId: any): void {
    let response = this.rewardService.publishReward(rewardId).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Success",
            type: "success",
          };
          this.snackBarService.openSnackBar(snackBarData);
          this.allowPublishManually = false;
          this.router.navigate(['reward']);

          // Set isStatusNotOneOrTwo based on your condition
        } else if (response.status == "NO_DATA_FOUND") {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);

        }
        else {
          let snackBarData: SnackBarData = {
            message: 'This reward/offer can not be published now.',
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },

    });
  }
  onTypeChange() {
    if (this.rewardInfoModel.type === 'html') {
      this.rewardInfoModel.url_content = ''; // Clear value if switching to text
    } else if (this.rewardInfoModel.type === 'url') {
      this.rewardInfoModel.url_content = ''; // Clear value if switching to URL
    }
    this.isFormDirty = true;
  }

  // Method to save data to external_url field in the required format


  /**
   * Load the reaward info from api and set to form
   *
   * @param rewardId
   */

  loadSavedReward(rewardId: any): void {
    let response = this.rewardService.getRewardById(rewardId).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          this.status = response.data.reward[0].status;
          this.allowPublishManually = response.data.allow_publish_manually;

          // this.isStatusZero = this.status === 0; // Track if status is zero
          this.buildRewardFromResponse(response);

          // Show notification if status is not '0'
          if (this.status !== 0) {
            const notificationElement = document.getElementById("notification");
            if (notificationElement) {
              notificationElement.style.display = "block";
            }
            this.isFormDirty = false;
          }

          // Set isStatusNotOneOrTwo based on your condition
          this.isStatusNotOneOrTwo = this.status !== 1 && this.status !== 2;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message:
            "Failed to get data from server. Connection to the server failed.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }


  /**
   * Set the variable from response
   * @param response
   */
  buildRewardFromResponse(response: any): void {
    let rewardInfo = response.data.reward[0];
    this.rewardInfoModel.id = rewardInfo.id;
    this.rewardInfoModel.code = rewardInfo.code;
    this.rewardInfoModel.name = rewardInfo.name;
    this.rewardInfoModel.description = rewardInfo.description;
    this.rewardInfoModel.short_description = rewardInfo.short_description;
    this.rewardInfoModel.crm_reward_category_id =
      rewardInfo.crm_reward_category_id;
    this.rewardInfoModel.brevo_mail_template_id =
      rewardInfo.brevo_mail_template_id;
    this.rewardInfoModel.image_file_name = rewardInfo.image_file_name;
    this.uploadedFileName = this.rewardInfoModel.image_file_name;
    this.rewardInfoModel.customer_filter = rewardInfo.customer_filter;

    this.editForm.patchValue(this.rewardInfoModel);
    if (rewardInfo.sales_chanels != null) {
      for (let sc of this.saleChannelsReward) {
        sc.selected = rewardInfo.sales_chanels.includes(sc.value);
      }
    }
    if (rewardInfo.enabled_for != null) {
      for (let am of this.acessModesReward) {
        am.selected = rewardInfo.enabled_for.includes(am.value);
      }
    }

    if (rewardInfo.customer_filter !== null) {
      this.rewardInfoModel.reward_eligibility =
        RewardEditComponent.CUSTOMER_ELIGIBILITY_FILTER;
      this.rewardInfoModel.customer_filter = rewardInfo.customer_filter;

    }
    this.rewardInfoModel.reward_eligibility = rewardInfo.reward_eligibility;
    this.rewardInfoModel.show_in_offers = rewardInfo.show_in_offers;
    this.rewardInfoModel.show_special_offer_badge = rewardInfo.show_in_offers;
    this.rewardInfoModel.discount_id = rewardInfo.discount.id;
    this.rewardInfoModel.discount = rewardInfo.discount;
    this.rewardInfoModel.reward_type = rewardInfo.reward_type;
    this.rewardInfoModel.display_order = rewardInfo.display_order;
    this.rewardInfoModel.apply_customer_filter = rewardInfo.apply_customer_filter;

    this.loadRewardDiscount();
    this.publishOn = new Date(rewardInfo.published_on);
    this.rewardInfoModel.validity_type = rewardInfo.validity_type;

    // Determine the selectedValidityOption based on the valid_for value
    if (rewardInfo.validity_type === 0) {
      this.rewardInfoModel.validity_type = 0;  // Set valid_for when "forever" is selected
      this.rewardInfoModel.valid_for = 0;
    } else if (rewardInfo.validity_type === 1) {
      this.calculateValidFor();   // Set valid_for when "endDate" is selected 
    } else if (rewardInfo.validity_type === 2) {
      this.rewardInfoModel.valid_for = rewardInfo.valid_for; // Set valid_for when "no of days" is selected

    } else {

    }
    this.rewardInfoModel.valid_for = rewardInfo.valid_for; // Set valid_for when "no of days" is selected
    this.calculateEndDate();

    this.rewardInfoModel.is_event_driven = rewardInfo.is_event_driven;
    this.rewardInfoModel.crm_event_id = rewardInfo.crm_event_id;
    if (this.rewardInfoModel.crm_event_id !== null && this.rewardInfoModel.crm_event_id != 0) {
      this.onEventSelected(this.rewardInfoModel.crm_event_id);
    }

    this.rewardInfoModel.is_external = rewardInfo.is_external;
    // this.onTypeChange();
    if (this.rewardInfoModel.type = '') {
      this.rewardInfoModel.type = 'html';

    }
    this.rewardInfoModel.type = rewardInfo.type;


    this.rewardInfoModel.url_content = rewardInfo.url_content;
    this.rewardInfoModel.use_within = rewardInfo.use_within;
    this.isFormDirty = false;
  }

  /**publishOn
   * Sets the discount details to binding variables
   */
  loadRewardDiscount(): void {
    switch (this.rewardInfoModel.reward_type) {
      case 1:
        this.billDiscountByOrderValue = {
          buy: this.rewardInfoModel.discount.buy,
          offer: this.rewardInfoModel.discount.offer,
        };
        break;
      case 2:
        break;
      case 3:
        this.saleItemByDiscountParam.buy.qty =
          this.rewardInfoModel.discount.buy.trigger_on[0].qty;
        this.saleItemDiscountModel = {
          buy: this.rewardInfoModel.discount.buy,
          offer: this.rewardInfoModel.discount.offer,
        };
        break;
      case 4:
        this.buyXgetYDiscountParam.buy.qty =
          this.rewardInfoModel.discount.buy.trigger_on[0].qty;
        this.buyXgetYDiscountParam.offer.qty =
          this.rewardInfoModel.discount.offer.offer[0].qty;
        this.buyXgetYDiscountParam.offer.variance =
          this.rewardInfoModel.discount.offer.offer[0].variance;
        this.buyXgetYDiscountParam.offer.isPercentage =
          this.rewardInfoModel.discount.offer.offer[0].is_percentage;
        this.buyXGetYDicountModel = {
          buy: this.rewardInfoModel.discount.buy,
          offer: this.rewardInfoModel.discount.offer,
        };
        break;
      case 5:
        this.billDiscountItemModel = {
          buy: this.rewardInfoModel.discount.buy,
          offer: this.rewardInfoModel.discount.offer,
        };
        break;
      default:
        break;
    }
  }

  /**
   * Sets the title
   * @returns the title based on mode new/editing
   */
  getTitle(): string {
    let title =
      this.rewardInfoModel.id == 0
        ? "New Promotion"
        : "Editing: " + this.rewardInfoModel.name;
    return title;
  }
  showRewardOption(rewardValue: number): boolean {
    return this.selectedSystemEvent.enable_rewards.includes(rewardValue);
  }

  onValidForChange(event: Event) { }

  // If reward eligibility is not 1, ensure is_external is unchecked

  onExternalChange(event: any) {
    // If reward eligibility is not 1, ensure is_external is unchecked
    if (this.rewardInfoModel.reward_eligibility != 1) {
      this.rewardInfoModel.is_external = 0;
    }
  }
  /**
   * Validate the form data
   * Validate the other controls
   * Update the json
   * Call the API to save
   * @returns
   */
  uploadFile(file: File) {
    // Capture the uploaded file name
    this.uploadedFileName = file.name;
  }
  updateVariance(value: number) {
    this.buyXgetYDiscountParam.offer.variance = value;
  }
  // Function to check if an item is duplicate
  isDuplicateItem(item: any): boolean {
    return this.duplicateItems.some(duplicateItem => duplicateItem === item);
  }
  save(): void {
    if (!this.editForm.valid) {
      this.editForm.markAllAsTouched();
      let snackBarData: SnackBarData = {
        message: "Some of fields are empty or conatains invalid data.",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }
    if (!this.rewardInfoModel.is_external) {
      if (!this.validateReward()) return;
    }
    // Additional validation for HTML type
    if (this.rewardInfoModel.reward_eligibility === 1 && this.rewardInfoModel.is_external) {
      if (this.rewardInfoModel.type === 'html' && !this.rewardInfoModel.url_content) {
        let snackBarData: SnackBarData = {
          message: "Please enter a message for the HTML content.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
        return;
      }
    }   // Check if the start date is selected
    if (!this.publishOn) {
      let snackBarData: SnackBarData = {
        message: "Please choose a start date.",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }
    if (this.rewardInfoModel.reward_eligibility == 2 && this.rewardInfoModel.brevo_mail_template_id == undefined) {
      let snackBarData: SnackBarData = {
        message: "Please choose a mail template",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }


    const formattedDate: string | null = this.datePipe.transform(
      this.publishOn,
      "yyyy-MM-dd"
    );

    this.rewardInfoModel.code = this.editForm.get("code")?.value;
    this.rewardInfoModel.name = this.editForm.get("name")?.value;
    this.rewardInfoModel.display_order = this.editForm.get("display_order")?.value;
    this.rewardInfoModel.description = this.editForm.get("description")?.value;
    this.rewardInfoModel.short_description = this.editForm.get("short_description")?.value;

    this.rewardInfoModel.crm_reward_category_id = this.editForm.get(
      "crm_reward_category_id"
    )?.value;
    // this.rewardInfoModel.brevo_mail_template_id = this.editForm.get(
    //   "brevo_mail_template_id"
    // )?.value;
    this.rewardInfoModel.reward_eligibility = this.rewardInfoModel.reward_eligibility;

    this.rewardInfoModel.image_file_name = this.uploadedFileName;
    if (
      this.rewardInfoModel.reward_eligibility ==
      RewardEditComponent.CUSTOMER_ELIGIBILITY_FILTER
    ) {
      this.rewardInfoModel.customer_filter =
        this.rewardInfoModel.customer_filter;
    } else {
      this.rewardInfoModel.customer_filter = undefined;
    }

    this.rewardInfoModel.customer_filter;
    this.rewardInfoModel.discount = this.rewardInfoModel.discount;
    this.rewardInfoModel.discount.code = this.rewardInfoModel.code;
    this.rewardInfoModel.discount.name = this.rewardInfoModel.name;
    this.rewardInfoModel.discount.description =
      this.rewardInfoModel.description;
    let discinfo = this.getSelectedDiscountInfo();
    this.rewardInfoModel.discount.buy = discinfo.buy;
    this.rewardInfoModel.discount.offer = discinfo.offer;
    this.rewardInfoModel.discount.activation_mode = this.rewardInfoModel.discount.activation_mode;

    this.rewardInfoModel.sales_chanels = this.getSelectedSalesChannels();
    this.rewardInfoModel.enabled_for = this.getSelectedTypes();
    //this.rewardInfoModel.external_url = this.onTypeChange();

    this.rewardInfoModel.published_on = formattedDate;
    this.rewardInfoModel.type = this.rewardInfoModel.type;
    if (this.rewardInfoModel.url_content === undefined || this.rewardInfoModel.url_content?.trim() === '') {
      this.rewardInfoModel.external_url = '';  // Save null if url_content is undefined or blank
    } else {
      this.rewardInfoModel.external_url = this.rewardInfoModel.type + "|" + this.rewardInfoModel.url_content;
    }
    this.rewardInfoModel.apply_customer_filter = this.rewardInfoModel.apply_customer_filter;


    // this.rewardInfoModel.is_event_driven = this.isAutoDistribute ? 1 : 0;

    // this.rewardInfoModel.crm_event_id = parseInt(this.selectedSystemEvent, 10);
    // this.rewardInfoModel.is_event_driven = rewardInfo.is_event_driven;
    // this.rewardInfoModel.crm_event_id =rewardInfo.crm_event_id;

    this.rewardService.update(this.rewardInfoModel).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          this.isFormDirty = false;
          this.buildRewardFromResponse(response);
          let snackBarData: SnackBarData = {
            type: "success",
            title: "Saved",
            message:
              "The record for " +
              this.rewardInfoModel.name +
              "  " + response.message,
          };
          this.snackBarService.openSnackBar(snackBarData);
          this.router.navigate(['reward']);

        }
        else {

          if (response.status == "REWARD_ITEM_DUPLICATE") {
            // Extract duplicate items from the response
            const existingItems = response.data.existing_items;
            // Push duplicate items to the array
            this.duplicateItems.push(...existingItems);
            let snackBarData: SnackBarData = {
              message: "Items already exists in other rewards",
              title: "Duplicate Items Found!.",
              type: "error",
            };
            this.snackBarService.openSnackBar(snackBarData);

          }
          else if (response.status == "DUPLICATE_EVENT_ID") {
            let snackBarData: SnackBarData = {
              message: "The Selected Event for reward already exist in other rewards ",
              title: "Duplicate Event Found!!!",
              type: "error",
            };
            this.snackBarService.openSnackBar(snackBarData);

          }
          else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: "Failed!!!",
              type: "error",
            };

            this.snackBarService.openSnackBar(snackBarData);
          }

        }
      },
      error: (_error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to update. Connection to the server failed",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }

  /**
   * Combine the selected chanels as coma seperated value
   * @returns
   *
   */
  getSelectedSalesChannels(): number[] {
    let selectedChannels: number[] = [];
    let chanel: any;
    for (chanel of this.saleChannelsReward) {
      if (chanel.selected) {
        selectedChannels.push(chanel.value);
      }
    }

    return selectedChannels;
  }
  getSelectedTypes(): number[] {
    let selectedModes: number[] = [];
    let mode: any;
    for (mode of this.acessModesReward) {
      if (mode.selected) {
        selectedModes.push(mode.value);
      }
    }

    return selectedModes;
  }

  /**
   * Based on the reward type returns the buy option
   */
  getSelectedDiscountInfo(): any {
    let option;
    switch (this.rewardInfoModel.reward_type) {
      case 1:
        option = this.billDiscountByOrderValue;
        break;
      case 3:
        // Upadate the quantity.
        for (let item of this.saleItemDiscountModel.buy.trigger_on) {
          item.qty = this.saleItemByDiscountParam.buy.qty;
        }
        option = this.saleItemDiscountModel;
        break;
      case 4:
        // Update the buying qty
        for (let item of this.buyXGetYDicountModel.buy.trigger_on) {
          item.qty = this.buyXgetYDiscountParam.buy.qty;
        }

        // Update the offer
        for (let item of this.buyXGetYDicountModel.offer.offer) {
          item.qty = this.buyXgetYDiscountParam.offer.qty;
          item.is_percentage = this.buyXgetYDiscountParam.offer.isPercentage;
          item.variance = this.buyXgetYDiscountParam.offer.variance;
        }
        option = this.buyXGetYDicountModel;
        break;
      case 5:
        option = this.billDiscountItemModel;
        break;
      default:
        undefined;
        break;
    }
    return option;
  }

  /***
   * Validate the data in various fields
   */
  validateReward(): boolean {
    // Get the selected reward type from the form
    const rewardType = this.rewardInfoModel.reward_type;

    // Apply validation rules based on the selected reward type
    switch (rewardType) {
      case 1: // Based on Order Value
        return this.validateOrderValueDiscountAmount();
      case 3: // Sale Item Discount
        return this.validateSaleItemDiscount();
      case 4: // Buy X Get Y Discount
        return this.validateBuyXGetYDiscount();
      case 5: // Free/Discounted Item based on order value
        return this.validateFreeOrDiscountedItem();
      // Add more cases for other reward types
      default:
        // Handle invalid reward type
        return true;
    }
  }
  validateOrderValueDiscountAmount(): boolean {
    const orderValue = this.billDiscountByOrderValue.buy.trigger_on.amount;
    const discountAmount = this.billDiscountByOrderValue.offer.offer.variance;

    // Check if order value and discount amount are missing or invalid
    if (
      !orderValue ||
      orderValue <= 0 ||
      !discountAmount ||
      discountAmount <= 0
    ) {
      // Display an error message for missing or invalid values
      this.displayValidationError(
        "Order value and discount amount are required and must be greater than 0."
      );
      return false;
    }

    return true; // Valid
  }

  validateSaleItemDiscount(): boolean {
    const selectedItems = this.saleItemDiscountModel.buy.trigger_on;

    if (!selectedItems || selectedItems.length === 0) {
      // Display a Snackbar or error message indicating that at least one item must be selected.
      console.log("Sale Item Discount Validation Failed: No items selected");

      let snackBarData: SnackBarData = {
        message: "Please select at least one item",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);

      return false; // Invalid
    }

    for (const offer of selectedItems) {
      if (!offer.qty) {
        // Display a Snackbar or error message indicating that qty and variance are required.
        console.log(
          "Sale Item Discount Validation Failed: Qty or Variance missing"
        );

        let snackBarData: SnackBarData = {
          message: "Quantity and Variance are required for all selected items",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);

        return false; // Invalid
      }
    }

    // if (
    //   !this.billDiscountItemModel.buy.trigger_on.amount &&
    //   this.billDiscountItemModel.buy.trigger_on.amount !== 0
    // ) {
    //   // Display a Snackbar or error message indicating that amount is required.
    //   console.log("Sale Item Discount Validation Failed: Amount missing");

    //   let snackBarData: SnackBarData = {
    //     message: "Amount is required",
    //     title: "Failed!!!",
    //     type: "error",
    //   };
    //   this.snackBarService.openSnackBar(snackBarData);

    //   return false; // Invalid
    // }

    // Additional validation checks for 'amount' can be added here if needed.

    // If all checks pass, the data is valid.
    return true;
  }

  validateBuyXGetYDiscount(): boolean {
    // Check if at least one item is selected for Buy and Offer sections
    const selectedBuyItems = this.buyXGetYDicountModel.buy.trigger_on;
    const selectedOfferItems = this.buyXGetYDicountModel.offer.offer;

    if (
      selectedBuyItems &&
      selectedBuyItems.length > 0 &&
      selectedOfferItems &&
      selectedOfferItems.length > 0
    ) {
      return true; // Valid
    } else {
      // Display a Snackbar or error message indicating that items must be selected for both Buy and Offer sections.
      let snackBarData: SnackBarData = {
        message:
          "Please select at least one item for both Buy and Offer sections of Buy X Get Y Discount.",
        title: "Validation Error",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return false; // Invalid
    }
  }
  validateFreeOrDiscountedItem(): boolean {

    const amount = this.billDiscountItemModel.buy.trigger_on.amount;
    // Check if order value (amount) is missing or invalid

    // if (amount==null) {
    //   // Display an error message for missing or invalid amount
    //   this.displayValidationError(
    //     "Order value is required."
    //   );
    //   return false;
    // }
    if (!this.rewardInfoModel.is_external) {
      // if (!amount || amount <= 0) {
      //   // Display an error message for missing or invalid amount
      //   this.displayValidationError(
      //     "Order value is required and must be greater than 0."
      //   );
      //   return false;
      // }
    }

    const selectedItems = this.billDiscountItemModel.offer.offer;
    // Check if at least one item is selected
    if (!selectedItems || selectedItems.length === 0) {
      // Display an error message for no item selected
      this.displayValidationError("Please select at least one item.");
      return false;
    }

    // Flag to track if any item is invalid
    let isValid = true;

    for (const offer of selectedItems) {
      if (!offer.qty || offer.qty <= 0) {
        // Display an error message for missing or invalid qty
        this.displayValidationError(
          "Quantity is required and must be greater than 0."
        );
        isValid = false;
        break; // Exit the loop, as further validation is not needed
      }

      if (!offer.variance || offer.variance <= 0) {
        // Display an error message for missing or invalid variance
        this.displayValidationError(
          "Variance is required and must be greater than 0."
        );
        isValid = false;
        break; // Exit the loop, as further validation is not needed
      }
    }

    return isValid;
  }

  private displayValidationError(message: string): void {
    console.log("Free/Discounted Item Validation Failed");
    const snackBarData: SnackBarData = {
      message,
      title: "Validation Error",
      type: "error",
    };
    this.snackBarService.openSnackBar(snackBarData);
  }

  //dropdown saleitem
  getSaleItem(id: number): any {
    let item = this.productList.find((item: any) => item.id == id);
    return item;
  }

  saleChannelsReward: SaleChannels[] = [
    { title: 'Online', value: 1, selected: true },
    { title: 'Instore', value: 2, selected: false },
  ];
  acessModesReward: AccessMode[] = [
    { title: 'Guest', value: 2, selected: true, disabled: false },
    { title: 'Login', value: 1, selected: true, disabled: true }
    ,
  ]

  // Function to handle Sales Channels checkboxes
  onSalesChannelChange($event: any): void {

    this.isFormDirty = true;
  }
  onRewardEligibilityChange($event: any): void {
    if ($event.value == 2) {
      this.saleChannelsReward[0].selected = true;
      this.saleChannelsReward[1].selected = true;
    } else {
      this.saleChannelsReward[0].selected = true;
      this.saleChannelsReward[1].selected = false;
    }
  }



  // atLeastOneCheckboxSelected(): boolean {
  //   return this.saleChannels.some((channel) => channel.selected);
  // }
  /**
   *
   * @param id Remove the selected segment from list
   */
  removeSegment(id: any): void {
    if (this.rewardInfoModel.customer_filter !== undefined) {
      const index = this.rewardInfoModel.customer_filter.id.indexOf(id);
      if (index !== -1) {
        this.rewardInfoModel.customer_filter.id.splice(index, 1);
      }
    }
  }

  clearSegments(): void {
    this.rewardInfoModel.customer_filter = { type: "segment", id: [] };
  }

  /**
   * Choose the segments.
   */
  // openRewardSegmentDialog(): void {

  //   let selectedSegments = this.segmentList.filter((s: any) => this.rewardInfoModel.customer_filter?.id.includes(s.id));
  //   const dialogRef = this.dialog.open(RewardSegmentEditDialogComponent, {
  //     width: "400px",
  //     data: {
  //       segments: this.segmentList,
  //       selectedSegments: selectedSegments,
  //     }, // Pass the selectedSegments
  //   });

  //   dialogRef.afterClosed().subscribe((selectedSegments: any[]) => {
  //     let custFiler: CustomerFilter = { type: "segment", id: [] };
  //     if (selectedSegments !== undefined) {
  //       custFiler.id = selectedSegments.map((s: any) => s.id);
  //     }
  //     this.rewardInfoModel.customer_filter = custFiler;
  //   });
  // }

  /**
   * Show the filter dialog to create selection criteria
   */
  openCustFilter(): void {
    let filter = this.rewardService.getFilter();
    let columFilter =
      this.rewardInfoModel.customer_filter == undefined
        ? []
        : this.rewardInfoModel.customer_filter;
    const dialogRef = this.dialog.open(RewardCustFilterDialogComponent, {
      width: "99vw",
      maxWidth: "900px",
      height: "80vh",
      data: {
        module: "customer",
        filterOptions: filter,
        columnFilter: columFilter,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.rewardInfoModel.customer_filter = result;
      } else {
      }
    });
  }

  /**
   * Loads the sale items
   */
  loadProducts(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 0, offset: 0 },
        },
        sort: [{ column: "name", order: "asc" }],
      },
    };

    this.rewardService.getProducts(requestData).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          // Exclude products where item_type is 3
          this.productList = response.data.items.filter(
            (product: { item_type: number }) => product.item_type !== 3
          );
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to load products.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }

  /**
   * Loads the items categories
   */
  loadItemCategoryList(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 0, offset: 0 },
        },
        sort: [{ column: "name", order: "asc" }],
      },
    };

    this.rewardService.getItemCategories(requestData).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.itemCategoryList = response.data.items;

          // Fetch products from the API
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to load item categories.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }

  openSelectionTypeDialog(triggerBy: any): void {
    const dialogRef = this.dialog.open(RewardProductBrowseDialogComponent, {
      width: "400px",
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.openProductBrowseDialog(result.type, triggerBy);
      }
    });
  }

  // Initialize it with an empty array or with any default values if needed
  selectedItems: any[] = [];
  /**
   * Filter the item category list based on items
   * @returns 
   */
  getFilteredCategoryList(): any[] {
    if (this.filteredCategoryList == undefined || this.filteredCategoryList.length == 0) {
      this.filteredCategoryList = this.itemCategoryList.filter((ic: any) =>
        this.productList.some((si: any) => si.oo_item_category_id == ic.id && si.item_type !== 3)
      );
    }
    return this.filteredCategoryList;
  }


  openProductBrowseDialog(browseBy: any, triggerBy: any, singleSelection: boolean = false): void {
    let browseItems = [];
    if (browseBy == "product") {
      browseItems = this.productList;
    } else {
      browseItems = this.getFilteredCategoryList();
    }

    // Assuming you have information about items to pre-select, update selectedItems accordingly
    // For example, if you have a variable preSelectedItems with the IDs of items to pre-select
    // this.selectedItems = browseItems.filter(item => preSelectedItems.includes(item.id));
    this.selectedItems = this.getSelectedItemList(triggerBy);
    var dialogRef: any;
    if (singleSelection) {
      dialogRef = this.dialog.open(RewardProductsDialogComponent, {
        width: "30%",
        height: "80%",
        data: {
          items: browseItems,
          selectedItems: this.selectedItems,
          browseBy: browseBy,
        },
      });
    } else {
      dialogRef = this.dialog.open(RewardProductItemsDialogComponent, {
        width: "30%",
        height: "80%",
        data: {
          items: browseItems,
          selectedItems: this.selectedItems,
          browseBy: browseBy,
        },
      });
    }

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.selectedItems = result.selectedItems; // Store selected items in the component property

        let selectedProducts = [];
        if (browseBy == "product") {
          selectedProducts = result.selectedItems;
        } else {
          let categoryIds = result.selectedItems.map(
            (category: any) => category.id
          );
          selectedProducts = this.productList.filter((product: any) =>
            categoryIds.includes(product.oo_item_category_id)
          );
        }

        if (triggerBy == "buy") {
          if (this.rewardInfoModel.reward_type == 3) {
            this.saleItemDiscountModel.buy.trigger_on = selectedProducts.map(
              (product: any) => {
                return {
                  id: product.id,
                  qty: 1,
                };
              }
            );
          } else if (this.rewardInfoModel.reward_type == 4) {
            this.buyXGetYDicountModel.buy.trigger_on = selectedProducts.map(
              (product: any) => {
                return {
                  id: product.id,
                  qty: 1,
                };
              }
            );
          }
        } else {
          if (this.rewardInfoModel.reward_type == 5) {
            // Loop through selectedProducts and map to offer structure for get trigger
            this.billDiscountItemModel.offer.offer = selectedProducts.map(
              (product: any) => {
                if (this.selectedSystemEvent != undefined) {
                  return {
                    id: product.id,
                    qty: this.selectedSystemEvent.offer_quantity,
                    is_percentage: this.selectedSystemEvent.is_offer_percentage,
                    variance: this.selectedSystemEvent.offer_amount,
                  };

                } else {
                  return {
                    id: product.id,
                    qty: 1,
                    is_percentage: 1,
                    variance: 100,
                  };

                }
              }
            );
          } else if (this.rewardInfoModel.reward_type == 4) {
            this.buyXGetYDicountModel.offer.offer = selectedProducts.map(
              (product: any) => {
                return {
                  id: product.id,
                  qty: 1,
                  is_percentage: 1,
                  variance: 10,
                };
              }
            );
          }
          // this.selectedOfferProducts = selectedProducts;
        }
      }
    });
  }

  getSelectedItemList(triggerBy: any): any {
    var selectedItemList: number[] = [];

    if (triggerBy == "buy") {
      if (this.rewardInfoModel.reward_type == 3) {
        selectedItemList = this.saleItemDiscountModel.buy.trigger_on.map(
          (product: any) => product.id
        );
      } else if (this.rewardInfoModel.reward_type == 4) {
        selectedItemList = this.buyXGetYDicountModel.buy.trigger_on.map(
          (product: any) => product.id
        );
      }
    } else {
      if (this.rewardInfoModel.reward_type == 5) {
        selectedItemList = this.billDiscountItemModel.offer.offer.map(
          (product: any) => product.id
        );
      } else if (this.rewardInfoModel.reward_type == 4) {
        selectedItemList = this.buyXGetYDicountModel.offer.offer.map(
          (product: any) => product.id
        );
      }
    }

    return this.productList.filter((product: { id: number, item_type: number }) =>
      selectedItemList.includes(product.id) && product.item_type !== 3
    );
  }


  openProductsDialog(triggerBy: any): void {
    this.openProductBrowseDialog("product", triggerBy, true);

    // this.selectedItems = this.getSelectedItemList(triggerBy);
    // let browseItems = [];

    // browseItems = this.productList;
    // const dialogRef = this.dialog.open(RewardProductsDialogComponent, {
    //   width: "400px",
    //   height:"500px",
    //   data: {
    //     items: browseItems,
    //     selectedItems: this.selectedItems,
    //     browseBy: "product",
    //   },
    // });

    // // You can subscribe to the dialog's afterClosed event to perform actions after the dialog is closed
    // dialogRef.afterClosed().subscribe((result: any) => {
    //   if (result) {
    //     // Assuming 'selectedRow' is the correct key
    //     let selectedProducts = [];
    //     selectedProducts = [result.selectedRow]; // Store selected items in the component property



    //     if (triggerBy == "buy") {
    //       // this.selectedBuyingProducts = selectedProducts;
    //       if (this.rewardInfoModel.reward_type == 3) {
    //         this.saleItemDiscountModel.buy.trigger_on = selectedProducts.map(
    //           (product: any) => {
    //             return {
    //               id: product.id,
    //               qty: 1,
    //             };
    //           }
    //         );
    //       } else if (this.rewardInfoModel.reward_type == 4) {
    //         this.buyXGetYDicountModel.buy.trigger_on = selectedProducts.map(
    //           (product: any) => {
    //             return {
    //               id: product.id,
    //               qty: 1,
    //             };
    //           }
    //         );
    //       }
    //     } else {
    //       if (this.rewardInfoModel.reward_type == 5) {
    //         // Loop through selectedProducts and map to offer structure for get trigger
    //         this.billDiscountItemModel.offer.offer = selectedProducts.map(
    //           (product: any) => {
    //             return {
    //               id: product.id,
    //               qty: 1,
    //               is_percentage: 1,
    //               variance: 100,
    //             };
    //           }
    //         );
    //       } else if (this.rewardInfoModel.reward_type == 4) {
    //         this.buyXGetYDicountModel.offer.offer = selectedProducts.map(
    //           (product: any) => {
    //             return {
    //               id: product.id,
    //               qty: 1,
    //               is_percentage: 1,
    //               variance: 10,
    //             };
    //           }
    //         );
    //       }
    //       // this.selectedOfferProducts = selectedProducts;
    //     }
    //   }
    // });
  }



  /**
   * Calculates End Date
   */

  calculateEndDate() {
    if (this.publishOn !== undefined && this.rewardInfoModel.valid_for !== undefined) {
      const startDate = new Date(this.publishOn);
      const validForDays = +this.rewardInfoModel.valid_for; // Convert to number using '+'
      const endDate = new Date(startDate.getTime() + validForDays * 24 * 60 * 60 * 1000);

      this.endDate = endDate; // Assign the Date directly
    }
  }
  /**
   * Calculates Start Date
   */
  calculateStartDate() {
    if (this.endDate !== undefined && this.rewardInfoModel.valid_for !== undefined) {
      const endDate = new Date(this.endDate);
      const validForDays = +this.rewardInfoModel.valid_for; // Convert to number using '+'
      const startDate = new Date(endDate.getTime() - validForDays * 24 * 60 * 60 * 1000);

      this.publishOn = startDate;
    }
  }
  /**
 * Calculates Valid For 
 */
  calculateValidFor() {
    // Your calculation logic here
    if (this.publishOn !== undefined && this.endDate !== undefined) {
      const startDate = new Date(this.publishOn);
      const endDate = new Date(this.endDate);

      // Calculate the difference in milliseconds
      const validForMilliseconds = endDate.getTime() - startDate.getTime();

      // Convert milliseconds to days and round to the nearest integer
      const validForDays = Math.round(validForMilliseconds / (24 * 60 * 60 * 1000));

      this.rewardInfoModel.valid_for = validForDays;
    }
  }


  isDisabled(): boolean {
    // Add your conditions here to determine if inputs should be disabled
    const canOverride = this.selectedSystemEvent && this.selectedSystemEvent.can_override === 0;
    const isSelectedSystemEvent = this.selectedSystemEvent !== null;

    return canOverride && isSelectedSystemEvent;
  }


  /**
   * Loads the reward category master data
   */
  loadRewardCategoryList(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 0, offset: 0 },
        },
        sort: [{ column: "name", order: "asc" }],
      },
    };

    this.rewardCategoryService.getCategoryList(requestData).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.rewardCategoryList = response.data.categories;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to load reward categories.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }
  loadMailTemplateList(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 0, offset: 0 },
        },
        sort: [{ column: "name", order: "asc" }],
      },
    };

    this.rewardService.getMailTemplateList(requestData).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.mailTemplateList = response.data.template_list;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to load mail template lists.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }
  /**
   * Loads pre defined deiscounts
   */
  loadDiscountList(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 0, offset: 0 },
        },
        sort: [{ column: "name", order: "asc" }],
      },
    };

    this.rewardService.getDiscount(requestData).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.discountList = response.data.discounts;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to load discounts.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }
  /** Loads crm events */
  loadSystemEvents(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 0, offset: 0 },
        },
        sort: [{ column: "name", order: "asc" }],
      },
    };

    this.rewardService.getEvents().subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.systemEvent = response.data.events;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error: any) => {
        let snackBarData: SnackBarData = {
          message: "Failed to retrieve events from the server.",
          title: "Error",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }

  removeFromBillDiscountItemModel(id: number): void {
    if (id == 0) {
      this.billDiscountItemModel.offer.offer.length = 0;
    } else {
      if (this.billDiscountItemModel.offer.offer !== undefined) {
        const index = this.billDiscountItemModel.offer.offer.findIndex(
          (i: any) => i.id == id
        );
        if (index !== -1) {
          this.billDiscountItemModel.offer.offer.splice(index, 1);
        }
      }
    }
  }

  /**
   * Remove from saleItemDiscountModel.buy.trigger_on
   * @param id when 0 clear the array, when !0 then remove that item
   */
  removeFromSaleItemDiscountModel(id: number): void {
    if (id == 0) {
      this.saleItemDiscountModel.buy.trigger_on.length = 0;
    } else {
      if (this.saleItemDiscountModel.buy.trigger_on !== undefined) {
        const index = this.saleItemDiscountModel.buy.trigger_on.findIndex(
          (i: any) => i.id == id
        );
        if (index !== -1) {
          this.saleItemDiscountModel.buy.trigger_on.splice(index, 1);
        }
      }
    }
  }

  /**
   * Remove from saleItemDiscountModel.buy.trigger_on
   * @param id when 0 clear the array, when !0 then remove that item
   */
  removeFromBuXGetYDiscountModel(src: string, id: number): void {
    let srcList =
      src == "buy"
        ? this.buyXGetYDicountModel.buy.trigger_on
        : this.buyXGetYDicountModel.offer.offer;
    if (id == 0) {
      srcList.length = 0;
    } else {
      if (srcList !== undefined) {
        const index = srcList.findIndex((i: any) => i.id == id);
        if (index !== -1) {
          srcList.splice(index, 1);
        }
      }
    }
  }


  isChecked(): boolean {
    return Number(this.rewardInfoModel.discount.activation_mode) === 0;
  }

  onCheckboxChange(event: any): void {
    this.rewardInfoModel.discount.activation_mode = event.checked ? 0 : 1;
  }
  

}


<mat-card class="info-card">
    <mat-card-title-group class="dashboard-item-title-container">
        <mat-card-title class="dashboard-item-title">
           {{dashBoardInfo.title}}
        </mat-card-title>
        <a mat-icon-button [matMenuTriggerFor]="menu" class="dashboard-item-menu">
            <mat-icon>more_vert</mat-icon>
        </a>
    </mat-card-title-group>
    <mat-card-content >
        <div class="dashboard-item-content" (click)="openEditDialog()">
            {{dashBoardInfo.content}}
        </div>
        <div class="status-toggle-container">
            <span class="status-toggle-title">Active</span>
            <mat-slide-toggle class="status-toggle" [(ngModel)]="dashBoardInfo.active" (change)="onStatusChange();" ></mat-slide-toggle>
        </div>
    </mat-card-content>
</mat-card>

<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="openEditDialog()">Edit</button>
    <button mat-menu-item (click)="item2_click()">Item 2</button>
    <button mat-menu-item (click)="item3_click()">Item 3</button>
</mat-menu>
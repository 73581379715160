<!-- <div class="row">
    <span class="closex" (click)="actionClose()"> X </span>
</div> -->
<div style="display: flex;flex-direction: column;">
    <div style="background: #fff; padding: 0px; padding-left: 10px;padding-right: 10px;
    overflow: hidden; display: flex;flex-direction: column;">

        <h3 style="font-size: 20px !important;
            font-weight: 500 !important;margin-top: 10px;">{{data.title}} - Edit</h3>
        <div style="height: 80%;width: 100%;">
            <quill-editor [modules]="editorConfig" class="editor" style="height:70vh;width: 100%;" [(ngModel)]="data.data" *ngIf="data.params==='terms'">
            </quill-editor>
            <quill-editor [modules]="editorConfig" [(ngModel)]="data.data"  style="height:70vh;width: 100%;" *ngIf="data.params==='privacy'"></quill-editor>
        </div>


    </div>

    <div class="action_block" >
        <button mat-raised-button color="accent" class="cancel" (click)="actionCancel()">Cancel</button>
        @if (isPublished) {
        <button mat-raised-button color="primary" class="save" (click)="actionSave()"> Save </button>
        }
    </div>
</div>
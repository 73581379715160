<h1 mat-dialog-title class="filter-load-dialog-title">FILTERS </h1>
<mat-dialog-content class="filter-load-dialog-content">
    <mat-form-field class="filter-cls">
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource">

      
        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element" > {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="is_favorite">


          <th mat-header-cell *matHeaderCellDef> FAVORITE </th>
          
          <td mat-cell *matCellDef="let element" >
            <ng-container *ngIf="element.is_favorite==1"><mat-icon>done</mat-icon></ng-container>  </td>
      </ng-container>
      <ng-container matColumnDef="no_of_filters">
        <th mat-header-cell *matHeaderCellDef> NO.FILTERS </th>
        <td mat-cell *matCellDef="let element" > {{element.filter_cnt}}</td>
    </ng-container>
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>  </th>
        <td mat-cell *matCellDef="let element" ><span mat-icon-button class="delete-button" (click)="onDelete(element)">
            <mat-icon mat-button>delete</mat-icon>
        </span></td>
    </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true" ></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"  class="table-element-row"  (click)="onRowSelected(element)" [ngClass]=" {'row-selected' : element.id===selectedFilter?.id}" >
        </tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end" class="filter-load-dialog-footer">
    
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="save(selectedFilter)">OK</button>
</mat-dialog-actions>
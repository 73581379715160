<div class="selected-item-action-container">
    <!-- <div class="action-btn">
        <button mat-icon-button matTooltip="Include" (click)="onIncludeClick()">
            <img src="assets/images/icons/winner-include.png">
          

        </button>
    </div> -->
    <div class="action-btn">
        <button mat-icon-button matTooltip="Exlude" (click)="onExcludeClick()">
            <img src="assets/images/icons/winner-exclude.png">
        </button>
    </div>
   
</div>

<h1 mat-dialog-title class="edit-form-title">{{title}}</h1>
<mat-dialog-content class="edit-form-content">
    <form class="edit-form" [formGroup]="editForm" (ngSubmit)="save()">
        <div class="content-container">
            <ng-container *ngIf="searchInfo?.id!==0">

                <div class="isoverwrite-container">
                    <mat-label>You want to overwrite</mat-label>
                    <mat-checkbox class="example-margin" formControlName="is_overwrite"></mat-checkbox>

                </div>
            </ng-container>

            <mat-form-field class="user-input example-full-width name">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" maxlength="50">
                <mat-error *ngIf="editForm.controls['name'].hasError('maxlength')">Maximum length is 50.</mat-error>
            </mat-form-field>


            <div class="status-toggle-container">
                <span class="status-toggle-title">Favorite</span>
                <mat-slide-toggle formControlName="is_favorite" class="status-toggle"></mat-slide-toggle>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer">

    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" (click)="save()">Save</button>
</mat-dialog-actions>
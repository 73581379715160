<div class="edit-page-root-container reward-edit-root-container">
  <div class="page-content">
    <div class="page-header ">
      <button mat-button class="nav-button" (click)="navBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="title">
        {{ getTitle() }}
      </div>
      <!-- <div class="action-btn-container ">
        <button mat-flat-button class="action-btn btn-discard"  [hidden]="!isStatusZero" (click)="navBack()">
          DISCARD
        </button>
        <button mat-flat-button class="action-btn btn-save"  [hidden]="!isStatusZero" (click)="save()">
          SAVE
        </button>
      </div> -->
      <div class="action-btn-container">
       @if(permission.can_publish){
          
        <button mat-flat-button class="action-btn btn-browse" (click)="publishLuckyDraw(luckyDrawInfoModel.id)"
          *ngIf="allowPublishManually == true && isStatusNotOneOrTwo" [hidden]="!isStatusNotOneOrTwo">
          PUBLISH
        </button>
      }
        <button mat-flat-button class="action-btn btn-discard" (click)="navBack()" *ngIf="isStatusNotOneOrTwo"
          [hidden]="!isStatusNotOneOrTwo">
          DISCARD
        </button>

        <button mat-flat-button class="action-btn btn-save" (click)="save()" *ngIf="isStatusNotOneOrTwo"
          [hidden]="!isStatusNotOneOrTwo">
          SAVE
        </button>
      </div>

    </div>
    <!-- Add a notification element at the top -->
    <div id="notification" class="notification"
      style="display: none; text-align: center; margin-top:10px; background: yellow; color:red; padding:15px;">
      This Draw has already been published. You cannot edit anymore.
    </div>
    <div class="page-details">
      <mat-tab-group>
        <!-- general info -->
        <form class="edit-form" [formGroup]="editForm">
          <mat-tab label="General">
            <div class="detail-section section-general-info" style="margin-top: 0%;">

              <div class="content-container">
                <div class="section-partition" style="display: flex;gap:20px;">
                  <div class="partition-left">
                    <mat-form-field class="code">
                      <mat-label>Code</mat-label>
                      <input matInput formControlName="code" [attr.maxlength]="10"
                        [readonly]="luckyDrawInfoModel.id!==undefined && luckyDrawInfoModel.id!==0"
                        pattern="[a-zA-Z0-9\s\W]*" />
                      <mat-error [@if]="editForm.controls['code'].hasError('required')">
                        Code should be entered</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="partition-right">
                    <mat-form-field class="name" style="width: 500px;">
                      <mat-label>Name</mat-label>
                      <input matInput formControlName="name" [attr.maxlength]="50">
                      <mat-error
                        *ngIf="editForm.controls['name'].hasError('required') && !editForm.controls['name'].value">Name
                        should be entered</mat-error>

                    </mat-form-field>
                  </div>
                </div>


                <!-- <div class="section-partition"> -->
                <mat-form-field class="description" style="width:100%;">
                  <mat-label>Description</mat-label>
                  <textarea matInput formControlName="description" [attr.maxlength]="255"
                    style="height: 80px;"></textarea>
                </mat-form-field>
                <mat-form-field class="description" style="width:100%;">
                  <mat-label>Short Description</mat-label>
                  <textarea matInput formControlName="short_description" [attr.maxlength]="100"></textarea>
                </mat-form-field>
              </div>
            </div>

            <!-- Entry Period info start-->
            <div class="detail-section">
              <div class="sub-title">Entry Period</div>
              <div class="content-container">
                <div class="section-partition" style="display: flex;gap:20px;margin-top: 20px;">
                  <mat-form-field class="example-full-width">
                    <mat-label>Start Date</mat-label>
                    <input formControlName="start_date" matInput [matDatepicker]="picker" placeholder="Choose a date"
                      [(ngModel)]="this.luckyDrawInfoModel.start_date" [min]="this.luckyDrawInfoModel.id == 0?currentDate:this.luckyDrawInfoModel.start_date"
                      [ngClass]="{'is-invalid': !isDateInFuture()}" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error [@if]="editForm.controls['start_date'].hasError('required')">
                      Start Date should be entered</mat-error>
                  </mat-form-field>
                  <!-- </div> -->
                  <div class="section-partition" style="display: flex;gap:20px;">
                    <mat-form-field class="example-full-width">
                      <mat-label>End Date</mat-label>
                      <input formControlName="end_date" matInput [matDatepicker]="endDatePicker" placeholder="End Date"
                        [(ngModel)]="this.luckyDrawInfoModel.end_date" [min]="this.luckyDrawInfoModel.id == 0?this.luckyDrawInfoModel.start_date:this.luckyDrawInfoModel.end_date"
                        [ngClass]="{'is-invalid': !isValidEndDate()}"/>

                      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                      <mat-datepicker #endDatePicker></mat-datepicker>
                      <mat-error [@if]="editForm.controls['end_date'].hasError('required')">
                        End Date should be entered</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <!-- Entry Period info end-->

            <!--Other details start-->
            <div class="detail-section">
              <div class="sub-title">Receipt Instructions</div>
              <div class="content-container" style="display: flex; justify-content: space-between;">
                <!-- <mat-form-field appearance="fill" class="quill-editor-field">
                  <mat-label>Instructions on POS receipt</mat-label> -->
                  <!-- <textarea matInput formControlName="pos_receipt_text"
                    style="height: 80px;">
                  </textarea> -->
                  <div class="editor-container" style="margin-top: 20px; gap: 15px; display: flex; flex-direction: column; height: 390px; width: 310px;">
                    <mat-label style="width: 100%;">POS Receipt</mat-label>
                    <quill-editor   [customOptions]="customOptions" [modules]="editorConfig" formControlName="pos_receipt_text" class="description" style="height: 300px;">
                    </quill-editor>
                  </div>
                <!-- </mat-form-field> -->
                <!-- <quill-editor class="description" style="width: 100%; margin-top: 20px; gap: 15px;height: 80px;">
                  </quill-editor> -->
                <!-- <mat-form-field class="description" style="width: 100%; margin-top: 20px; gap: 15px;">
                  <mat-label>Instructions on POS receipt</mat-label>
                  <textarea matInput formControlName="pos_receipt_text"
                    style="height: 80px;"></textarea>
                </mat-form-field> -->
                <div class="editor-container" style="margin-top: 21px; gap: 15px; display: flex; flex-direction: column; height: 390px; width: 310px;">
                  <mat-label>OOS Receipt</mat-label>
                  <quill-editor [modules]="editorConfig" formControlName="oo_receipt_text" class="description" style="height: 300px;">
                  </quill-editor>
                </div>
                <!-- <mat-form-field class="description" style="width: 100%;">
                  <mat-label>Instructions on OO receipt</mat-label>
                  <textarea matInput formControlName="oo_receipt_text"></textarea>
                </mat-form-field> -->
              </div>
            </div>
            <!--Other details end-->

            <!--Statutory details start-->
            <div class="detail-section">
              <div class="sub-title">Statutory</div>
              <div class="content-container" style="display: flex;margin-top: 20px; gap: 15px;">
                <div fxLayout="row" style="margin: 10px 10px 10px 0px;">
                  <button style="width:200px;" mat-raised-button color="primary" (click)="actionOpenEditor('terms')">
                    Terms and Conditions </button>
                  <button style="width:200px;margin-left: 10px;" mat-raised-button color="primary"
                    (click)="actionOpenEditor('privacy')">Privacy Policy</button>
                </div>
              </div>
              <div class="content-container" style="margin-top: 20px;">
                <div class="status-toggle-container">
                  <mat-slide-toggle formControlName="is_valid" class="status-toggle"
                    [(ngModel)]="luckyDrawInfoModel.is_valid"></mat-slide-toggle>
                  <span class="status-toggle-title">Valid</span>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Settings">
            <!-- Settings info start-->
            <div class="detail-section" style="margin-top: 0%;">
              <div class="content-container" style="display: flex;gap: 15px;">
                <mat-form-field style="width: 50%;margin-top:20px;">
                  <mat-label>Target Sales chanel</mat-label>
                  <mat-select formControlName="target_channel" [(ngModel)]="this.luckyDrawInfoModel.extra_info.channel">
                    @for (channel of this.saleChannels; track channel) {
                    <mat-option [value]="channel.value">{{ channel.title }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
            
                <mat-form-field style="width: 50%;margin-top:20px;">
                  <mat-label>Target Audience</mat-label>
                  <mat-select formControlName="target_audience" [(ngModel)]="luckyDrawInfoModel.extra_info.customer">
                    @for (audience of this.targetAudience; track audience) {
                    <mat-option [value]="audience.value">{{ audience.title }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="content-container"  style="display: flex;gap: 15px;">
                <mat-form-field style="width:50%; margin-top:20px;">
                  <mat-label>Qualified Spend</mat-label>
                  <input formControlName="qualified_spend" matInput type="number"
                    [(ngModel)]="this.luckyDrawInfoModel.extra_info.min_order_amount" name="discountAmount">
                </mat-form-field>
             
                <mat-form-field style="width:50%; margin-top:20px;">
                  <mat-label>E-Mail Template</mat-label>
                  <mat-select formControlName="brevo_template_id" placeholder="Mail Template"
                    [(ngModel)]="luckyDrawInfoModel.brevo_template_id">
                    @for (template of mailTemplateList; track template.id) {
                    <mat-option [value]="template.id">
                      {{ template.name }}
                    </mat-option>
                    }
                  </mat-select>
                  <mat-error [@if]="editForm.controls['brevo_template_id'].hasError('required')">
                    E-Mail Template should be selected</mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-tab>
        </form>
      </mat-tab-group>
    </div>
    <!--Statutory details end-->
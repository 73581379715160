import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { UserGroupModel } from './model/user-group.model';
import { UserGroupService } from './user-group.service';
import { MatDialog } from '@angular/material/dialog';
import { UserGroupEditComponent } from './user-group-edit/user-group-edit.component';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListItemModel, SearchParam, SearchFilterComponent } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';
import { trigger } from '@angular/animations';
import { StateManagerService } from 'src/app/common/services/state.manager.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.scss'],
  animations: [
    trigger('if', [
      // ... animation states and styles
    ])
  ]
})
export class UserGroupComponent {
  userGroupList: UserGroupModel[] = [];
  showSearch: boolean = false;
  isLoading = true;
  IsDownloading = false;

  filter: FilterListItemModel[] = [
    {
      title: "Code",
      column: "code",
    },
    {
      title: "Name",
      column: "name",
    },
    {
      title: "Active",
      column: "is_valid",
      type: "boolean",
      values: [
        { title: "Active", value: "1" },
        { title: "Not Active", value: "0" },
      ]
    },

  ];

  displayedColumns = ['item', 'code', 'name', 'is_valid', 'action'];
  pagingData = { length: 0, pageSize: 10, pageIndex: 0 };

  menuOption: FloatingMenuOption = {
    add: true,
    search: false,
    export: true,
    more: true
  }

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedItem?: UserGroupModel;
  showUserGroupSummary: boolean = false;
  selection = new SelectionModel<UserGroupModel>(true, []);
  dataSource = new MatTableDataSource(this.userGroupList);
  constructor(private breakpointObserver: BreakpointObserver,
    private userGroupService: UserGroupService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private router: Router,
    private stateManagerService: StateManagerService) {

    this.userGroupList = [];
  }

  /**
   * initilaize the device model
   */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    });
    this.isLoading = false; // Set to true on page load
    this.IsDownloading = false;

    this.loadData();

  }
  /** 
 * Set the datasource
 */
  setDataSource(userGroupList: UserGroupModel[]) {
    this.dataSource = new MatTableDataSource(userGroupList);
    this.dataSource.sort = this.sort;
  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
  ngAfterViewInit(): void {

    this.loadPreviousState();
    // this.loadData();
  }

  /**
   * loads the previous state if exists
   */
  loadPreviousState(): void {

    var previousState = this.stateManagerService.getState(this.constructor.name);

    if (previousState !== undefined && this.searchFilter != undefined) {

      if (previousState.state.search.filters.scope) {
        this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
        this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
      }

      if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
        this.setSortColumn(previousState.state.search.sort[0].column, previousState.state.search.sort[0].order);
      }

      this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
      this.searchFilter.setSearchFilter(this.searchColumFilter, true);

    } else {
      this.setSortColumn('name', 'asc');
      this.loadData();
    }

  }

  /**
   * Sets the sort column
   * @param column 
   * @param direction 
   */
  setSortColumn(column: string, direction: SortDirection): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: column, order: direction });
    this.sort.sort({
      id: column,
      start: direction,
      disableClear: false,
    });

    // this.sort.active=column;
    // this.sort.direction=direction;


  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.userGroupList.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.userGroupList);
  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }


  /**
   * Builds the request data to be send to API
   * @returns Request Data
   */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      }
    };

    return requestData;
  }

  /**
   * loads the category data based on the conditions
   */
  loadData(): void {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    let response = this.userGroupService.getUserGroupList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        //this.userGroupList = response.data.user_groups;
        this.setDataSource(response.data.user_groups);
        this.pagingData.length = response.data.total_count;
        if (this.pagingData.length == 0) {
          let snackBarData: SnackBarData = {
            message: 'No data found. Please remove or change the filter if any.',
            title: 'No Data!!!',
            type: 'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
          this.selectedItem = undefined;
        } else {
          this.onRowSelected(this.userGroupList[0])
          this.stateManagerService.setState(this.constructor.name, requestData);
        }
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
      this.IsDownloading = false;

    });

  }

  /**
   * On Search toggle button is clicked
   */
  toggleSearch(): void {
    this.showSearch = !this.showSearch
  }

  /**
   * On Add clicked
   * Show the dialog to enter new category
   */
  addClicked(): void {

    this.router.navigate(['user-group-edit', 0]);

  }

  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {

    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }

  /**
 * On edit the item
 * Show the edit dialog
 */
  onEdit(userGroupInfo: UserGroupModel): void {

    this.router.navigate(['user-group-edit', userGroupInfo.id]);
  }
  setPermission(userGroupInfo: UserGroupModel): void {

    this.router.navigate(['system-function', userGroupInfo.id]);
  }

  /**
   * Set the column filter and reaload the daaa
   * @param columFilter 
   */

  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();

  }

  /**
   * On delete menu item selected
   * Confirm the reward-category action and call the api to update
   */
  onDelete(userGroupInfo: UserGroupModel): void {
    const userGroupId = userGroupInfo.id;
    const message = `Are you sure, you want to delete ` + (userGroupInfo.name + "?");
    const dialogData = new ConfirmDialogModel("Confirm Deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult);
      if (dialogResult) {
        this.userGroupService.delete(userGroupInfo).subscribe({
          next: (response) => {
            if (response.status == 'SUCCESS') {
              // handle success
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'The record for ' + userGroupInfo.name + " has been deleted succesfully."
              }
              this.snackBarService.openSnackBar(snckBarData);
              this.loadData();
            } else {
              let snackBarData: SnackBarData = {
                message: response.message,
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
            }
          },
          error: (error) => {
            let snackBarData: SnackBarData = {
              message: 'Failed to update. Connection to the server failed',
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        });
      }
    });
  }

  /**
   *  Set the sort param and relead the data
   * @param $event sort data
   * {"active": "email","direction": "asc"}
   *   
   */
  sortData($event: any): void {
    var sd = this.sort;
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }

  /**
 * On reward-category selected
 * @param userGroupInfo 
 */
  onRowSelected(userGroupInfo: UserGroupModel): void {
    this.selectedItem = userGroupInfo;
    this.showUserGroupSummary = true;
  }

  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
  getSearchParam(): SearchParam {

    return this.searchColumFilter;
  }

  exportClicked() {
    this.isLoading = true;
    this.IsDownloading = true;

    let requestData = this.buildRequestData();

    this.userGroupService.downloadExcel(requestData)
      .subscribe(
        blob => {
          // Download logic
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'user_group.xlsx'; // Adjust filename if needed
          link.click();
          window.URL.revokeObjectURL(url);
          this.isLoading = false;
          this.IsDownloading = false;

        },
        error => {
          // Handle errors
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
          this.isLoading = false;
          // Display user-friendly error message to the user
        }
      );
  }

}


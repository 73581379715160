<app-masters-optionbar> </app-masters-optionbar>

<div class="user-group-component-container page-root-container">
  <div class="page-header">
    <div class="page-title">
      <svg width="24px" height="24px" viewBox="0 0 16 16" version="1.1">
        <rect width="16" height="16" id="icon-bound" fill="none" />
        <path
          d="M14,12.5C14,11.837 13.737,11.201 13.268,10.732C12.799,10.263 12.163,10 11.5,10C9.447,10 6.553,10 4.5,10C3.837,10 3.201,10.263 2.732,10.732C2.263,11.201 2,11.837 2,12.5C2,14.147 2,15 2,15L14,15C14,15 14,14.147 14,12.5ZM12,6L14,6C14.53,6 15.039,6.211 15.414,6.586C15.789,6.961 16,7.47 16,8L16,11L14.663,11C14.101,9.818 12.896,9 11.5,9L10.645,9C11.476,8.267 12,7.194 12,6ZM1.337,11L0,11C0,11 0,9.392 0,8C0,7.47 0.211,6.961 0.586,6.586C0.961,6.211 1.47,6 2,6L4,6C4,7.194 4.524,8.267 5.355,9L4.5,9C3.104,9 1.899,9.817 1.337,11ZM8,3C9.656,3 11,4.344 11,6C11,7.656 9.656,9 8,9C6.344,9 5,7.656 5,6C5,4.344 6.344,3 8,3ZM4.127,4.996C4.085,4.999 4.043,5 4,5C2.896,5 2,4.104 2,3C2,1.896 2.896,1 4,1C4.954,1 5.753,1.67 5.952,2.564C5.061,3.097 4.394,3.966 4.127,4.996ZM10.048,2.564C10.247,1.67 11.046,1 12,1C13.104,1 14,1.896 14,3C14,4.104 13.104,5 12,5C11.957,5 11.915,4.999 11.873,4.996C11.606,3.966 10.939,3.097 10.048,2.564Z" />
      </svg><span style="margin-left:15px;">User Group</span>
    </div>
    <div class="page-actions">
      <button mat-button color="primary" class="btn-add" (click)="addClicked()">
        <mat-icon>person_add</mat-icon>New
      </button>
      <button mat-button color="accent" class="btn-export" (click)="exportClicked()" [disabled]="IsDownloading">
        <img class="download-icon"
          [src]="IsDownloading ? 'assets/images/download-animation/download-anim.svg' : 'assets/images/download-animation/download.svg'">
        Export
      </button>
    </div>
  </div>

  <div class="data-container">
    <app-loader [show]="isLoading"></app-loader>

    <!-- <app-search-filter
      [length]="pagingData.length"
      [pageSize]="pagingData.pageSize"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      [pageIndex]="pagingData.pageIndex"
      [filter]="filter"
      (page)="onePageEvent($event)"
      (onApply)="onFilterApplied($event)"
      [hidePageSize]="true" #searchFilter
    >
    </app-search-filter> -->
    <app-search-filter #searchFilter [pagingData]="pagingData" [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
      (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
    </app-search-filter>
    <section tabindex="0" class="table-container">
      <table matSort mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="item-header">#</th>
          <td mat-cell *matCellDef="let element; let i = index" class="item-cloum">{{
            (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="code">
          <th mat-sort-header="code" mat-header-cell style="width: 80px" *matHeaderCellDef (click)="sortData($event)">
            Code
          </th>
          <td mat-cell *matCellDef="let ug" style="width: 80px">
            {{ ug.code }}
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-sort-header="name" mat-header-cell *matHeaderCellDef (click)="sortData($event)">Name</th>
          <td mat-cell *matCellDef="let ug">{{ ug.name }}</td>
        </ng-container>
        <ng-container matColumnDef="is_valid">
          <th mat-sort-header="is_valid" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="status-header">
            Status
          </th>

          <td mat-cell *matCellDef="let ug" (click)="sortData($event)" class="status-column">
            <div class="ug-active-status">
              <div *ngIf="ug.is_valid === 1" class="status active">
                Active <span></span>
              </div>
              <div *ngIf="ug.is_valid === 0" class="status expired">
                Inactive
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="action" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell class="row-menu-btn" style="width: 50px" *matCellDef="let rowItem">
            <a mat-icon-button class="row-menu-btn" [matMenuTriggerFor]="rowMenu"
              (click)="onRowSelected(rowItem); $event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </a>

            <mat-menu #rowMenu>
              <button mat-menu-item (click)="onDelete(rowItem)">Delete</button>
              <button mat-menu-item (click)="onEdit(rowItem)">Edit</button>
              <button mat-menu-item (click)="setPermission(rowItem)">
                Set Permissions
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row class="table-element-row" *matRowDef="let ug; columns: displayedColumns" (click)="onRowSelected(ug)"
          (dblclick)="onEdit(ug)" [ngClass]="{ 'row-selected': ug.id === selectedItem?.id }"></tr>
      </table>
    </section>
    @if(pagingData.length<=0){ <div class="no-data-container">
      <img src="assets/images/no-data.png" />
      <span class="no-data-info">No data found. Please remove or redefine the filters if any.</span>
  </div>
  }
</div>
</div>
<app-user-group-summary [user_group]="selectedItem" (onEdit)="onEdit($event)"
  (onDelete)="onDelete($event)"></app-user-group-summary>
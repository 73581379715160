<div class="account-info-container" >
    <div class="account-content" (click)="$event.stopPropagation()">
      <div class="face-img">
        <mat-icon> person_pin</mat-icon>
      </div>
      <div class="account-info">
        <span class="account-name">{{user?.name}}</span>
        <span class="account-dtl">{{user?.email}}</span>
      </div>
    </div>
    <div class="log-sign">
      <!-- <a mat-button  href="#" class="btn-logout" (click)="logout()">Log out</a> -->
      <a mat-menu-item (click)="logout()"><mat-icon>exit_to_app</mat-icon>Sign Out</a>
    </div>
  </div>
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { DashboardEditComponent } from '../../dashboard-edit/dashboard-edit.component';

@Component({
  selector: 'app-dashboard-item',
  templateUrl: './dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.scss']
})
export class DashboardItemComponent {

  @Input() dashBoardInfo: any;
  result: any;
  
  constructor(public dialog: MatDialog) {}

  item3_click() :void {
    alert("item 3 is clicked");
  }

  item2_click() :void {
    alert("item 2 is clicked");
  }

  item1_click() :void {
    alert("item 1 is clicked");
  }

  onStatusChange(): void{
  
    const message = `Are you sure you want to change the status to ` + (this.dashBoardInfo.active?"Active":"Inactive");

    const dialogData = new ConfirmDialogModel("Confirm Action", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(!dialogResult){
        this.dashBoardInfo.active=! this.dashBoardInfo.active;
      }
    });
  }

  openEditDialog(): void {
    const dialogRef = this.dialog.open(DashboardEditComponent, {
      width: '400px',
      height: '400px',
      data: this.dashBoardInfo
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Edited item:', result);
      } else {
        console.log('Edit canceled');
      }
    });
  }


}

import { Injectable } from '@angular/core';
import { DashboardModel } from './model/dashboard.model';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  
  constructor() { 

    
  }
  /**
  * 
  */
  getInfoList():DashboardModel[] {

    var infoList=[
      {id:1,title:"Sample info title", content:"This is a sample content for matcard to demonstract the mat card usage.",active:true},
      {id:3,title:"Sample info title", content:"This is a sample content for matcard to demonstract the mat card usage.",active:false},
      {id:4,ttitle:"Sample info title", content:"This is a sample content for matcard to demonstract the mat card usage.",active:true},
      {id:5,ttitle:"Sample info title", content:"This is a sample content for matcard to demonstract the mat card usage.",active:false},
      {id:6,ttitle:"Sample info title", content:"This is a sample content for matcard to demonstract the mat card usage.",active:true},
      {id:7,ttitle:"Sample info title", content:"This is a sample content for matcard to demonstract the mat card usage.",active:true},
      {id:8,ttitle:"Sample info title", content:"This is a sample content for matcard to demonstract the mat card usage.",active:true},
      {id:9,ttitle:"Sample info title", content:"This is a sample content for matcard to demonstract the mat card usage.",active:true},
      {id:10,ttitle:"Sample info title", content:"This is a sample content for matcard to demonstract the mat card usage.",active:true}    
    ];
    return infoList;
  }
}

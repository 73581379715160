<h1 mat-dialog-title class="edit-form-title">
    Purchases Time (Time Based)
    <button mat-icon-button mat-dialog-close aria-label="Close" class="close-btn">
        <mat-icon>close</mat-icon>
    </button>
</h1>

<mat-dialog-content class="edit-form-content">
    <p class="p-text">
        The number of purchases recorded during different bussiness hours. Derived from the most recent RFM calculation.
    </p>
    <table class="day-purchases-table">
        <tr>
            <th class="day-week">Time</th>
            <th># Purchases</th>
        </tr>
        <tr>
            <td class="align-right">Breakfast</td>
            <td>{{customer.purchase_breakfast_time}}</td>
        </tr>
        <tr>
            <td class="align-right">Lunch</td>
            <td>{{customer.purchase_lunch_time}}</td>
        </tr>
        <tr>
            <td class="align-right">Evening Time</td>
            <td>{{customer.purchase_evening_time}}</td>
        </tr>
        <tr>
            <td class="align-right">Dinner</td>
            <td>{{customer.purchase_dinner_time}}</td>
        </tr>
       
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer">
    <button mat-raised-button color="warn" (click)="cancel()">Close</button>
</mat-dialog-actions>

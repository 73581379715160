<h1 mat-dialog-title class="edit-form-title">Browse By</h1>
<mat-divider></mat-divider>
<!-- Top separator line -->
<div class="option-list-container">
  <div class="link-item" (click)="onTypeSelected('product')">Products<mat-icon class="arrow-right">arrow_right</mat-icon></div>
  <mat-divider></mat-divider>
  <a class="link-item" (click)="onTypeSelected('category')">Categories<mat-icon class="arrow-right">arrow_right</mat-icon></a>
</div>
<mat-divider></mat-divider>
<!-- Bottom separator line -->
<mat-dialog-actions align="end" class="footer">
  <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
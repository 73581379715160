import { Injectable } from '@angular/core';
import { Observable, async } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogService } from '../components/confirm-dialog/confirm-dialog.service';

export interface DirtyCheck{
    isDirty():boolean;
}

@Injectable()
export class FormDeactivateGuard {

    constructor(private confirmDialogService: ConfirmDialogService) { }
    /**
     * Checks the components has isDirty implemented. if so checks the dirtyness
     * @param component 
     * @returns 
     */
    canDeactivate(component: any): Observable<boolean> | Promise<boolean> | boolean {

        if (component.isDirty && component.isDirty()) {

            let result = this.confirmNavigation();
            return result;
        }
        return true;
    }
    /**
     * Call the confirm dialog function async
     * @returns 
     */
    async confirmNavigation(): Promise<boolean> {
        const message = "Are you sure you want to leave? Your changes will be lost.";
        const dialogData = new ConfirmDialogModel("Leave page with unsaved changes?", message);
        const result = await this.confirmDialogService.openDialog(dialogData);
        return result;
    }
}
<div class="edit-page-root-container rfm-settings-edit-root-container">
    <div class="page-content">
        <div class="page-header">

            <div class="title">
                {{title}}
            </div>

        </div>
        <div class="page-details">
            <div class="detail-section general-settings">
                <div class="content-container">
                    <div class="sub-title">Reward Code, Name and Other Details<br></div>
        <div>
                    <mat-form-field>
                        <mat-label>RFM computation period (in days)</mat-label>
                        <input matInput [value]="100" [readonly]>
                        
                    </mat-form-field>
                     <mat-form-field>
                        <mat-label>Total number of Score</mat-label>
                        <input matInput [value]="100" [readonly]>
                    </mat-form-field>
                </div>
                </div>
            </div>
            <div class="detail-section">
                <div class="content-container">
                    <div class="sub-title">Recency<br>How many days ago did the customer make a purchase ?</div>
                    <table mat-table [dataSource]="recencyDataList?.rfm_settings">
                        <ng-container matColumnDef="from">
                            <th mat-header-cell style="width: 80px;" *matHeaderCellDef>FROM</th>
                            <td mat-cell *matCellDef="let settings" style="width: 80px;"> {{settings.from}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="to">
                            <th mat-header-cell *matHeaderCellDef> TO </th>
                            <td mat-cell *matCellDef="let settings"> {{settings.to}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="score">
                            <th mat-header-cell *matHeaderCellDef> SCORE </th>
                            <td mat-cell *matCellDef="let settings"> {{settings.score}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="rating">
                            <th mat-header-cell *matHeaderCellDef> RATING </th>
                            <td mat-cell *matCellDef="let settings"> {{settings.rating}}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                        </tr>
                        <tr mat-row class="table-element-row" *matRowDef="let segment; columns: displayedColumns;">
                        </tr>
                    </table>
                </div>
            </div> 
            
            <div class="detail-section">
                <div class="content-container">
                    <div class="sub-title">Frequency<br>During the past 100 days, how many purchase has the customer made?</div>
                    
                    <table mat-table [dataSource]="frequencyDataList?.rfm_settings">
                        <ng-container matColumnDef="from">
                            <th mat-header-cell style="width: 80px;" *matHeaderCellDef>FROM</th>
                            <td mat-cell *matCellDef="let settings" style="width: 80px;"> {{settings.from}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="to">
                            <th mat-header-cell *matHeaderCellDef> TO </th>
                            <td mat-cell *matCellDef="let settings"> {{settings.to}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="score">
                            <th mat-header-cell *matHeaderCellDef> SCORE </th>
                            <td mat-cell *matCellDef="let settings"> {{settings.score}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="rating">
                            <th mat-header-cell *matHeaderCellDef> RATING </th>
                            <td mat-cell *matCellDef="let settings"> {{settings.rating}}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                        </tr>
                        <tr mat-row class="table-element-row" *matRowDef="let segment; columns: displayedColumns;">
                        </tr>
                    </table>
                </div>
            </div> 
      
            <div class="detail-section">
                <div class="content-container">
                    <div class="sub-title">Monetary<br>
                        How much money has the customer spent during the last 100 days?</div>
                    
                    <table mat-table [dataSource]="monetaryDataList?.rfm_settings">
                        <ng-container matColumnDef="from">
                            <th mat-header-cell style="width: 80px;" *matHeaderCellDef>FROM</th>
                            <td mat-cell *matCellDef="let settings" style="width: 80px;"> {{settings.from}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="to">
                            <th mat-header-cell *matHeaderCellDef> TO </th>
                            <td mat-cell *matCellDef="let settings"> {{settings.to}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="score">
                            <th mat-header-cell *matHeaderCellDef> SCORE </th>
                            <td mat-cell *matCellDef="let settings"> {{settings.score}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="rating">
                            <th mat-header-cell *matHeaderCellDef>RATING </th>
                            <td mat-cell *matCellDef="let settings"> {{settings.rating}}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                        </tr>
                        <tr mat-row class="table-element-row" *matRowDef="let segment; columns: displayedColumns;">
                        </tr>
                    </table>
                </div>
            </div> 
      
        </div>
    </div>
</div>
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SegmentModel } from '../../model/segment.model';
import { AppConstants } from 'src/app/common/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { SegmentComponent } from '../../segment.component';

@Component({
  selector: 'app-segment-summary',
  templateUrl: './segment-summary.component.html',
  styleUrls: ['./segment-summary.component.scss']
})
export class SegmentSummaryComponent {

  @Input() segment: any;

  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  show: boolean = false;
  segments: any;

  constructor(
    private dialog: MatDialog
  ) {
    let sessionSegment = sessionStorage.getItem(AppConstants.SESSION_VAR_SEGMENTS);
    this.segments = (sessionSegment !== null) ? JSON.parse(sessionSegment) : [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['segment']) {

    }
  }

  /**
   * Closed the summary panel
   */
  close(): void {
    this.show = !this.show;
  }
  /**
   * Edit the current selected item
   */
  onEditAction() {
    this.onEdit.emit(this.segment);
  }
  /**
   * Delete the current item.
   */
  onDeleteAction() {
    this.onDelete.emit(this.segment);
  }
}

<div class="edit-page-root-container user-group-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
            </div>
        </div>
        <div class="page-details ">
            <div class="detail-section">
                <div class="content-container">
                    <form class="edit-form" [formGroup]="editForm">
                        <div class="form-content-container">
                            <div class="common-ug-partion">
                                <mat-form-field class="code">
                                    <mat-label>Code</mat-label>
                                    <input matInput formControlName="code"  [attr.maxlength]="10">
                                    <mat-error [@if]="editForm.controls['code'].hasError('required')">User Group code
                                        should
                                        be
                                        entered</mat-error>
                                 
                                </mat-form-field>
                                <mat-form-field class="name">
                                    <mat-label>Name</mat-label>
                                    <input matInput formControlName="name"  [attr.maxlength]="50" pattern="[a-zA-Z\s]*">
                                    <mat-error *ngIf="editForm.controls['name'].hasError('required') && !editForm.controls['name'].value">Name should be entered</mat-error>
                                    <mat-error *ngIf="editForm.controls['name'].hasError('pattern')">Only alphabetic characters are allowed</mat-error>                              
                                </mat-form-field>
                            </div>
                            <mat-form-field class="description">
                                <mat-label>Description</mat-label>
                                <textarea matInput formControlName="description"  [attr.maxlength]="200"></textarea>
                              
                            </mat-form-field>
                            <div class="status-toggle-container">
                                <span class="status-toggle-title">Active</span>
                                <mat-slide-toggle formControlName="is_valid" class="status-toggle"></mat-slide-toggle>
                            </div>
                        </div>
                    </form>

                </div>
            </div>


        </div>
    </div>
</div>
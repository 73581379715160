<h1 mat-dialog-title class="edit-form-title">Upload Image</h1>
<mat-dialog-content class="edit-form-content">
  <form class="edit-form">
    <div class="content-container">
      <div class="img-container">
        <img *ngIf="selectedFile" [src]="previewUrl" alt="Image Preview">
      </div>
      <input type="file" (change)="onFileSelected($event)" accept="image/*">
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer">
  <button mat-raised-button (click)="uploadImage()" color="primary">Upload</button>
  <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>

<!-- Snackbar Container -->
<!-- <mat-snack-bar-container></mat-snack-bar-container> -->

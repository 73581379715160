<div class="summary-page-root-container segment-summary-root-container" [style.width]=" show ? '320px' : '0px' ">
    <div [ngClass]="{ 'close-btn' : show , 'open-btn' :!show  }">
        <button mat-icon-button (click)="close()">
            <!-- <mat-icon *ngIf="show">arrow_forward</mat-icon>
            <mat-icon *ngIf="!show">arrow_back</mat-icon> -->
            @if(show){
                <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 24 24" enable-background="new 0 0 24 24"
                    xml:space="preserve" fill="#5F6368">
                    <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"></path>
                    <path fill="none" d="M0,0h24v24H0V0z"></path>
                </svg>
                }@else{
                <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" style="transform: rotate(180deg);"
                    xml:space="preserve" fill="#5F6368">
                    <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"></path>
                    <path fill="none" d="M0,0h24v24H0V0z"></path>
                </svg>
                }
        </button>
    </div>
    <div *ngIf="show && segment!==undefined" class="page-container">
        <div class="page-header">
            <div class="title">{{segment.code}} </div>
        </div>
        <div class="page-content">
            <div class="section description-container">
                <span>{{segment.description}}</span>
            </div>
            <div class="section status-container">               
                <div class="segment-actieve-status">
                    <div *ngIf="segment.is_valid==1" class="status active">
                        Active
                    </div>
                    <div *ngIf="segment.is_valid!==1" class="status inactive">
                        Inactive
                    </div>
                </div>
            </div>
            <div class="section created-info-container">
                <div class="section-content">
                    <span>Creatd At : {{segment.created_at | date:'dd MMM yyyy HH:mm' }} </span>
                    <span>Updated At : {{segment.updated_at | date:'dd MMM yyyy HH:mm' }} </span>
                </div>
            </div>
        </div>
        <div class="page-footer">
            <button mat-button color="primary" class="btn-edit" (click)="onEditAction()"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button color="accent" class="btn-delete" (click)="onDeleteAction()"> <mat-icon>delete</mat-icon>Delete</button>
        </div>
    </div>

    <div class="no-page-data" *ngIf="show && segment==undefined">
        <img src="assets/images/side-bar-no-data.png">
        <span class="no-data-info">Nothing to show. Please select a segment.</span>
    </div>

</div>
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-simple-paginator',
  templateUrl: './simple-paginator.component.html',
  styleUrls: ['./simple-paginator.component.scss']
})
export class SimplePaginatorComponent {

  @Input() pagingData: any;
  @Output() page: EventEmitter<any> = new EventEmitter();

  /**
  * On previous page button clicked
  */
  onPreviousPage(): void {
    this.pagingData.pageIndex = (this.pagingData.pageIndex > 0) ? this.pagingData.pageIndex - 1 : this.pagingData.pageIndex;
    this.page.emit(this.pagingData);
  }

  /**
 * On next page button clicked
 */
  onNextPage(): void {
    let lastPageIndex = Math.ceil(this.pagingData.length / this.pagingData.pageSize) - 1;
    this.pagingData.pageIndex = (lastPageIndex > this.pagingData.pageIndex) ? this.pagingData.pageIndex + 1 : this.pagingData.pageIndex;
    this.page.emit(this.pagingData);
  }

  /**
   * 
   * @returns Build the page info to sho in handeset mode
   */
  getPageInfo(): string {
    let lastPageIndex = Math.ceil(this.pagingData.length / this.pagingData.pageSize);
    let pageInfo = (this.pagingData.pageIndex + 1) + "/" + lastPageIndex;
    return pageInfo;
  }

  /**
   * Check for last page
   * @returns 
   */
  isLastPage(): boolean {
    let lastPageIndex = Math.ceil(this.pagingData.length / this.pagingData.pageSize);
    let currentPage = this.pagingData.pageIndex + 1;
    return currentPage >= lastPageIndex;
  }

  /**
   * Check fo the first page
   * @returns 
   */
  istFirstPage(): boolean {

    return this.pagingData.pageIndex <= 0;
  }
}

<div *ngIf="showRewardMenu" class="submenu-panel" (mouseenter)="gotFocus=true;" (mouseleave)="closeSidenav()" >
    <div class="menuPointer"></div>
    <div class="menu-list-container">
        <mat-list-item class="mat-list-item" routerLink="reward" [routerLinkActive]="['is-active']"  (click)="closeSidenav()">
                <span>Rewards</span>
            
        </mat-list-item>
        <mat-list-item class="mat-list-item" routerLink="reward-category" [routerLinkActive]="['is-active']"  (click)="closeSidenav()">
            <span>Category</span>
        
    </mat-list-item>
    </div>
</div>
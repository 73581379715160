<h1 mat-dialog-title class="edit-form-title">{{title}}</h1>
<mat-dialog-content class="edit-form-content">
    <form class="edit-form" [formGroup]="editForm" (ngSubmit)="save()">
        <div class="content-container">
            <mat-form-field class="user-input fname">
                <mat-label>User ID</mat-label>
                <input matInput formControlName="login_id" maxlength="50" [readonly]="userInfo.login_id!==undefined">
                <mat-error *ngIf="editForm.controls['login_id'].hasError('maxlength')">Maximum length is 50.</mat-error>
            </mat-form-field>
            <mat-form-field class="user-input fname">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="f_name" maxlength="50">
                <mat-error *ngIf="editForm.controls['f_name'].hasError('maxlength')">Maximum length is 50.</mat-error>
            </mat-form-field>
            <mat-form-field class="user-input l_anme">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="l_name" maxlength="50">
                <mat-error *ngIf="editForm.controls['l_name'].hasError('maxlength')">Maximum length is 50.</mat-error>
            </mat-form-field>
            <mat-form-field class="user-input address">
                <mat-label>Address</mat-label>
                <textarea matInput formControlName="address" maxlength="250"></textarea>
                <mat-error *ngIf="editForm.controls['address'].hasError('minlength')">Minimum length is 250.</mat-error>
            </mat-form-field>
            <mat-form-field class="user-input email">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email">
                <mat-error *ngIf="editForm.controls['email'].hasError('email')">Invalid email address.</mat-error>
            </mat-form-field>
            <mat-form-field class="user-input phone">
                <mat-label>Phone</mat-label>
                <input matInput formControlName="phone">
                <mat-error *ngIf="editForm.controls['phone'].hasError('minlength')">Minimum length is 10.</mat-error>
            </mat-form-field>
            <div class="status-toggle-container">
                <span class="status-toggle-title">Active</span>
                <mat-slide-toggle formControlName="active" class="status-toggle"></mat-slide-toggle>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer">
    <button mat-raised-button color="primary" (click)="save()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
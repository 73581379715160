import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UserModel } from '../../model/user.model';
import { AppConstants } from 'src/app/common/app.constants';
import { UserEditDialogComponent } from '../../dialogs/user-edit-dialog/user-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UserComponent } from '../../user.component';
import { SystemSettingsService } from 'src/app/common/services/system-settings.service';

@Component({
  selector: 'app-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.scss']
})
export class UserSummaryComponent {
  @Input() user: any;

  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  show: boolean = false;
  users: any;
  dateTimeFormat: string;

  
  constructor(
    private dialog: MatDialog,
    private userComponent: UserComponent,
    private systemSettingService: SystemSettingsService
  ) {
    let sessionUser =sessionStorage.getItem(AppConstants.SESSION_VAR_TAGS);
    this.users = (sessionUser!==null)?JSON.parse(sessionUser):[];
    this.dateTimeFormat = systemSettingService.getDefaultDateTimeFormat();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user']) {
      this.show = (this.user !== undefined);
      
    }
  }

  close(): void {
    this.show = !this.show;
  }

  onEditAction() {
    this.onEdit.emit(this.user);
  }

  onDeleteAction() {
    this.onDelete.emit(this.user);
  }

}

import { Component } from '@angular/core';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent {

}

import { Injectable } from '@angular/core';
 import { RequestData } from 'src/app/common/models/request-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RfmSettingsService {

 
  constructor(private http: HttpClient) { }

  getList(): Observable<any> {
    return this.http.post(environment.baseUrl+'/rfm-setting/get', ""); 
  }
}

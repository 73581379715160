import { Injectable } from '@angular/core';
import { SegmentModel } from './model/segment.model';
import { RequestData } from 'src/app/common/models/request-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SegmentService {

 
  constructor(private http: HttpClient) { }

  getSegmentById(id:number): Observable<any> {
    return this.http.post(environment.baseUrl+'/segment/get/'+id, '');

  }
  getSegmentList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/segment/get', requestData);

  }
  getRfmRatingList(): Observable<any> {
    return this.http.post(environment.baseUrl+'/rfm-rating/get', '');

  }

  /**
   * 
   * @param segmentInfo 
   * Calls the update API to insert/update the record
   */
  update(segmentInfo: SegmentModel): Observable<any> { 
    if(segmentInfo.hasOwnProperty("id")){
      return this.http.post(environment.baseUrl+'/segment/update', segmentInfo);
    } else{
      return this.http.post(environment.baseUrl+'/segment/add', segmentInfo);
    }
  }

  /**
   * 
   * @param segmentInfo 
   * Calls the API to delete the record
   */
  delete(segmentInfo: SegmentModel): Observable<any>  {
    return this.http.post(environment.baseUrl+'/segment/delete/'+segmentInfo.id, segmentInfo);
  }
}

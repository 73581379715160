<div class="edit-page-root-container user-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
            </div>
        </div>
        <div class="page-details ">
            <div class="detail-section">
                <form class="edit-form" [formGroup]="editForm">
                    <div class="content-container">
                        <div class="common-partition">
                            <mat-form-field class="common-input-width">
                                <mat-label>Login Id</mat-label>
                                <input matInput formControlName="login_id"  [attr.maxlength]="50">
                                <mat-error [@if]="editForm.controls['login_id'].hasError('required')">Login Id should be
                                    entered</mat-error>
                               
                            </mat-form-field>
                            <mat-form-field class="common-input-width">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="name" [attr.maxlength]="50" pattern="[a-zA-Z\s]*">
                                <mat-error *ngIf="editForm.controls['name'].hasError('required') && !editForm.controls['name'].value">Name should be entered</mat-error>
                                    <mat-error [@if]="editForm.controls['name'].hasError('pattern')">Only alphabetic characters are allowed
                                </mat-error>

                               
                            </mat-form-field>
                        </div>
                        <div class="common-partition">
                            <mat-form-field class="common-input-width">

                                <mat-label>Email</mat-label>
                                <input matInput formControlName="email" type="email"  [attr.maxlength]="255">
                                <mat-error [@if]="editForm.controls['email'].hasError('required')">Email should be
                                    entered</mat-error>
                            
                                <mat-error [@if]="editForm.controls['email'].hasError('email')">Invalid email
                                    format</mat-error>

                            </mat-form-field>
                            <mat-form-field class="common-input-width">


                                <mat-label>Phone</mat-label>
                                <input matInput formControlName="phone" type="tel"  [attr.maxlength]="15" pattern="[0-9]*">
                                <mat-error [@if]="editForm.controls['phone'].hasError('required')">Phone no should be
                                    entered</mat-error>
                                <mat-error [@if]="editForm.controls['phone'].hasError('pattern')">Please enter only
                                    numeric characters</mat-error>

                            </mat-form-field>
                        </div>
                        <div class="common-partition">
                            <ng-container
                                *ngIf="userInfo.id === undefined || userInfo.id === 0 else readOnlyPasswordFields">
                                <mat-form-field class="common-input-width">
                                    <mat-label>Password</mat-label>
                                    <input matInput formControlName="password" type="password" [attr.maxlength]="255">
                                    <mat-error *ngIf="editForm.controls['password'].hasError('required')">Password
                                        should be entered</mat-error>
                                </mat-form-field>

                                <mat-form-field class="common-input-width">
                                    <mat-label>Confirm Password</mat-label>
                                    <input matInput formControlName="confirm_password" type="password"  [attr.maxlength]="255">
                                    <mat-error
                                        *ngIf="editForm.controls['confirm_password'].hasError('required')">Confirm
                                        Password should be entered</mat-error>
                                    <!-- <mat-error *ngIf="editForm.hasError('passwordMismatch')">Passwords do not match</mat-error> -->
                                </mat-form-field>
                            </ng-container>
                            <ng-template #readOnlyPasswordFields>
                                <div style="background-color: blue; width:20%; border-radius:5px; color:#fff;">
                                    <button style="color:#fff; font-size:13px;" mat-button class="change-password-btn"
                                        (click)="openChangePasswordDialog()">Change Password</button>

                                </div>

                            </ng-template>
                        </div>

                        <div class="status-toggle-container">
                            <span class="status-toggle-title">Active</span>
                            <mat-slide-toggle formControlName="is_valid" class="status-toggle"></mat-slide-toggle>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
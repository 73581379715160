import { Component, Inject, ViewEncapsulation, NgModule } from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RewardCategoryModel } from '../../model/reward-category.model';
import { RewardCategoryService } from '../../reward-category.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackbarService } from 'src/app/common/services/snackbar.service';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';


@Component({
  selector: 'app-reward-category-edit-dialog',
  templateUrl: './reward-category-edit-dialog.component.html',
  styleUrls: ['./reward-category-edit-dialog.component.scss'],
})

export class RewardCategoryEditDialogComponent {

  title: string;
  editForm!: FormGroup;
  rewardCategoryInfo: RewardCategoryModel;

  constructor(private builder: FormBuilder,
    public dialogRef: MatDialogRef<RewardCategoryEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public item: any,
    private rewardCategoryService: RewardCategoryService, private snackBarService: SnackBarService) {
      this.rewardCategoryInfo = JSON.parse(JSON.stringify(item));
      this.editForm = this.builder.group({
        code: this.builder.control(this.rewardCategoryInfo.code, [Validators.required, Validators.maxLength(50)]),
        name: this.builder.control(this.rewardCategoryInfo.name, [Validators.required]),
        description: this.builder.control(this.rewardCategoryInfo.description, [Validators.required]),
        is_valid: this.builder.control(this.rewardCategoryInfo.is_valid, [])
      });
    this.title = this.getTitle();
    
  }

  /**
   * On save
   * if the details are valid call the api to update the changes.
   */
  save(): void {

    if (this.editForm.valid) {
      // this.rewardCategoryInfo = this.editForm.value;
      Object.assign(this.rewardCategoryInfo, this.editForm.value);
      this.rewardCategoryService.update(this.rewardCategoryInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.dialogRef.close(this.rewardCategoryInfo);
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.rewardCategoryInfo.name +  " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData:SnackBarData={
              message:response.message,
              title:'Failed!!!',
              type:'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          // handle error
          let snackBarData:SnackBarData={
            message:'Failed to update. Connection to the server failed',
            title:'Failed!!!',
            type:'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }
  }

  /**
   * Close the edit dialog
   */
  cancel(): void {
    this.dialogRef.close();
  }
  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.item.id == undefined) ? "New" : "Editing : " + this.item.name;
    return title;
  }
  
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {

  constructor(private http: HttpClient) { }

   /**
   * set default date and time format
   */
   getDefaultDateTimeFormat():string{
    return 'dd-MM-yyyy, h:mm a';
  }
  getDefaultDateFormat():string{
    return 'dd-MM-yyyy';
  }
}


import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/common/app.constants';
// import { SelectionModel } from 'src/app/common/models/selection.model';
@Component({
  selector: 'app-customer-segment-edit-dialog',
  templateUrl: './customer-segment-edit-dialog.component.html',
  styleUrls: ['./customer-segment-edit-dialog.component.scss']
})


export class CustomerSegmentEditDialogComponent implements OnInit {
 
  segments: any[] = [];
   
  displayedColumns: string[] = [ 'selection','name'];
  selectedSegment: any;
  dataSource: MatTableDataSource<any>;

  constructor(
    public dialogRef: MatDialogRef<CustomerSegmentEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

     this.segments =  data.segments;
     this.dataSource = new MatTableDataSource(this.segments);
  }

  ngOnInit(): void {
    
  }
  
   
  /**
  * Close the edit dialog
  */
  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.selectedSegment);
  }

   onRowSelected(segment:any):void{
      this.selectedSegment=segment;
   }

   /**
   * Apply the filter on table contents4
   * 
   * @param filterValue 
   */
  applyFilter(event: any) {
    let filterValue = event.target.value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}

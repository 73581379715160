import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserModel } from '../../model/user.model';
import { UserService } from '../../user.service';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent {
  editForm!: FormGroup;
  userInfo: UserModel;

  constructor(
    private builder: FormBuilder,
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private item: any,
    private userService: UserService,
    private snackBarService: SnackBarService
  ) {
    console.log('Dialog data:', item);

    this.userInfo = JSON.parse(JSON.stringify(item));
    this.buildForm();
  }

  buildForm(): void {
    this.editForm = this.builder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', Validators.required],
    },);
  }

  /**
   * On save
   * if the details are valid call the API to update the changes.
   */
  save(): void {
    if (this.editForm.valid) {
      const password = this.editForm.value.password;
      const confirmPassword = this.editForm.value.confirm_password; // Assuming you have a confirmPassword field in your form
      const userId = this.userInfo.id;
      
      if (password === confirmPassword) {
        // Passwords match, proceed with the update
        if (userId !== undefined) {
          // Call the service to update only the password
          this.userService.updatePassword(userId, password).subscribe(
            response => {
              if (response.status === 'SUCCESS') {
                this.snackBarService.openSnackBar({
                  type: 'success',
                  title: 'Password Updated',
                  message: 'Your password has been updated successfully.'
                });
                this.dialogRef.close();
              } else {
                this.snackBarService.openSnackBar({
                  type: 'error',
                  title: 'Failed to Update Password',
                  message: response.message
                });
              }
            },
            error => {
              console.error('Error updating password:', error);
              this.snackBarService.openSnackBar({
                type: 'error',
                title: 'Failed to Update Password',
                message: 'An error occurred while updating the password.'
              });
            }
          );
        } else {
          console.error('User ID is undefined.');
          // Handle the case where userId is undefined, perhaps show an error message
        }
      } else {
        // Passwords do not match, show an error message
        this.snackBarService.openSnackBar({
          type: 'error',
          title: 'Password Mismatch',
          message: 'The entered password and confirm password do not match.'
        });
      }
    } else {
      // Handle form validation errors
    }
  }
  
  
  


  

  /**
   * Close the edit dialog
   */
  cancel(): void {
    this.dialogRef.close();
  }
}

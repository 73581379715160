import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarData } from './model/snackbar.model';
import { SnackBarComponent } from './snackbar.component';

@Injectable({
  providedIn: 'root'
})


export class SnackBarService {

  constructor(private matSnackBar: MatSnackBar) { }
/**
 * shows the snackbar
 * set defaults values
 * @param data 
 */
  openSnackBar(data: SnackBarData) {
    this.matSnackBar.openFromComponent(SnackBarComponent, {
      data: data,
      duration: (data.duration == undefined ? 5000 : data.duration),
      panelClass: [(data.type == "success" ? "success-snack-notification" : ((data.type == "error") ? "error-snack-notification" : "warning-snack-notification"))],
      horizontalPosition: (data.horizontalPosition == undefined ? "end" : data.horizontalPosition),
      verticalPosition: (data.verticalPosition == undefined ? "top" : data.verticalPosition),
    });
  }
} 

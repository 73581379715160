import { Component, OnInit } from "@angular/core";
import { SystemFunctionService } from "./system-function.service";
import { SnackBarService } from "src/app/common/components/snackbar/snackbar.service";
import { SnackBarData } from "src/app/common/components/snackbar/model/snackbar.model";
import { GroupPermission, SelectAllType } from "./model/system-function.model";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-system-function",
  templateUrl: "./system-function.component.html",
  styleUrls: ["./system-function.component.scss"],
})
export class SystemFunctionComponent implements OnInit {
  isLoading = true;
  groupFunctionList: any[] = [];
  groupPermissions: GroupPermission[] = [];
  displayedColumns: string[] = [
    "function_name",
    "can_view",
    "can_add",
    "can_edit",
    "can_delete",
    "can_execute",
    "can_publish",
    "can_export",
    "can_filter",
  ];
  dataSource: any[] = [];
  selectedFunctionGroup: string | "all" = "all";
  selectAll: SelectAllType = {
    view: false,
    add: false,
    edit: false,
    delete: false,
    execute: false,
    publish: false,
    filter: false,
    export: false,
  };
  userGroupId: number | undefined;
  groupNames: any;

  constructor(
    private systemService: SystemFunctionService,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.selectAll.view = false;
  }

  ngOnInit(): void {
    let userGroupId = this.route.snapshot.paramMap.get("id");
    this.loadSystemFunction(userGroupId);

  }
  /**
    * On toggle item selected and deselected
    */
  toggleAll(column: keyof SelectAllType): void {
    var controlField = "is_" + column.toString() + "_applicable";
    const valueField = "can_" + column.toString();
    this.dataSource.forEach((element) => {
      // Skip disabled fields
      if (element[controlField] !== 0) {
        element[valueField] = this.selectAll[column];
      }
    });
  }
  /**
    * checkboxes values are changing according to type
    */
  checkboxChanged(column: keyof SelectAllType, changedElement: any): void {
    const controlField = "is_" + column.toString() + "_applicable";
    const valueField = "can_" + column.toString();
    let allSelected = true;
    for (const element of this.dataSource) {
      // Skip disabled fields
      if (element[controlField] !== 0 && element[valueField] === false) {
        allSelected = false;
        break;
      }
    }
    this.selectAll[column] = allSelected;
  }
  /**
    * checkboxes status value indications
    */
  updateCheckbox(column: string, element: any): void {
    if (element[column] === 3) {
      element[column] = true; // Checked
    } else if (element[column] === 1) {
      element[column] = true; // Unchecked
    } else if (element[column] === 0) {
      element[column] = null; // Disabled
    }
  }
  /**
    * redirect back
    */
  navBack(): void {
    this.router.navigate(["/user-group"]);
  }
  /**
    * load all permission data
    */
  loadSystemFunction(userGroupId: any): void {

    this.isLoading = true;
    this.systemService.getSystemById(userGroupId).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.groupFunctionList = response.data.system_function_group;
          this.groupPermissions = response.data.group_permissions;
          this.updateDataSource();
          this.isLoading=false;

        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to load System Functions.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },

    });
  }
  /**
    * updating data on drop down change
    */
  updateDataSource(): void {
    if (this.selectedFunctionGroup === "all") {
      this.dataSource = this.groupPermissions;
    } else {
      this.dataSource = this.groupPermissions.filter((group: { function_group: string }) => group.function_group === this.selectedFunctionGroup
      );
    }
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {
    this.systemService.update(this.groupPermissions).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          let snackBarData: SnackBarData = {
            type: "success",
            title: "Saved",
            message:
              "The Permissions has been updated successfully.",
          };
          this.snackBarService.openSnackBar(snackBarData);
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (_error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to update. Connection to the server failed",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
      
      
    });

  }
}


<mat-card class="info-card">
    <mat-card-title-group class="item-title-container">
        <mat-card-title class="item-title">
           {{userInfo.f_name}} {{userInfo.l_name}} 
        </mat-card-title>
        <a mat-icon-button [matMenuTriggerFor]="menu" class="item-menu">
            <mat-icon>more_vert</mat-icon>
        </a>
    </mat-card-title-group>
    <mat-card-content >
        <div class="item-content" (click)="onEditClick()" >
            <span>Address: {{userInfo.address}}</span>
            <span> Phone: {{userInfo.phone}}</span>
            <span> Mail: {{userInfo.email}}</span>
        </div>
        <div class="status-toggle-container">
            <span class="status-toggle-title">Active</span>
            <mat-slide-toggle class="status-toggle" [(ngModel)]="userInfo.active" (change)="onStatusChange();" ></mat-slide-toggle>
        </div>
    </mat-card-content>
</mat-card>

<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onEditClick()">Edit</button>
    <button mat-menu-item (click)="onDeleteClick()">Delete</button>
</mat-menu>
<h1 mat-dialog-title class="edit-form-title">Customer Segments </h1>
<mat-dialog-content class="edit-form-content">
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="selection">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element" class="selection-mark">
                <div *ngIf="element.id===selectedSegment?.id" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512" id="tick">
                        <path fill="crimson"
                            d="M223.9 329.7c-2.4 2.4-5.8 4.4-8.8 4.4s-6.4-2.1-8.9-4.5l-56-56 17.8-17.8 47.2 47.2L340 177.3l17.5 18.1-133.6 134.3z">
                        </path>
                    </svg>
                </div>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-element-row"
            (click)="onRowSelected(row)" [ngClass]=" {'row-selected' : row.id===selectedSegment?.id}">
        </tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer">
    <button mat-raised-button color="primary" (click)="save()" [disabled]="selectedSegment==undefined" >OK</button>
    <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
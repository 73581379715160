import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { SystemSettingsService } from 'src/app/common/services/system-settings.service';

@Component({
  selector: 'app-user-group-summary',
  templateUrl: './user-group-summary.component.html',
  styleUrls: ['./user-group-summary.component.scss']
})
export class UserGroupSummaryComponent {
  @Input() user_group: any;

  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  show: boolean = false;
  dateTimeFormat: string;

  constructor(private systemSettingService: SystemSettingsService) {
    this.dateTimeFormat = systemSettingService.getDefaultDateTimeFormat();
   }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user_group']) {
    //  this.show = (this.user_group !== undefined);

    }
  }

  /**
   * Closes the side panel
   */
  close(): void {
    this.show = !this.show;
  }
  /**
   * Edit the current selected item
   */
  onEditAction() {
    this.onEdit.emit(this.user_group);
  }

  /**
   * Delete the currnet selected item.
   */
  onDeleteAction() {
    this.onDelete.emit(this.user_group);
  }
}

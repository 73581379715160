<!--<div class="reward-summary-container" [style.width]=" show ? '320px' : '0px' ">
    <div [ngClass]="{ 'close-btn' : show , 'open-btn' :!show  }">
        <button mat-icon-button (click)="close()">
            <mat-icon *ngIf="show">arrow_forward</mat-icon>
            <mat-icon *ngIf="!show">arrow_back</mat-icon>
        </button>
    </div>-->
<div class="summary-page-root-container reward-summary-root-container" [style.width]=" show ? '320px' : '0px' "
    style="overflow-y: auto">
    <div [ngClass]="{ 'close-btn' : show , 'open-btn' :!show  }">
        <button mat-icon-button (click)="close()">
            <!-- <mat-icon *ngIf="show">arrow_forward</mat-icon>
            <mat-icon *ngIf="!show">arrow_back</mat-icon> -->
            @if(show){
                <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 24 24" enable-background="new 0 0 24 24"
                    xml:space="preserve" fill="#5F6368">
                    <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"></path>
                    <path fill="none" d="M0,0h24v24H0V0z"></path>
                </svg>
                }@else{
                <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" style="transform: rotate(180deg);"
                    xml:space="preserve" fill="#5F6368">
                    <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"></path>
                    <path fill="none" d="M0,0h24v24H0V0z"></path>
                </svg>
                }
        </button>
    </div>
    <div *ngIf="show && reward!==undefined" class="page-container" style="overflow-y: auto">
        <div class="page-header">
            <div class="title">{{reward.code}} </div>
        </div>
        <div class="page-content">

            <div class="section ">
                <div class="section-title">
                    DESCRIPTION
                </div>
                <div class="section-content ">

                    <p>{{reward.description}}</p>

                </div>
                <div class="section-footer"></div>
            </div>
            @if(reward.success_rate.recipients_count > 0 || reward.success_rate.claimants_count > 0){
            <div class="section status-container">
                <div class="section-title"></div>
                <div class="section-content ">
                    <ngx-charts-bar-vertical [view]="[200,200]" [results]="barChartData" [xAxis]="showXAxis"
                        [showGridLines]="false">
                    </ngx-charts-bar-vertical>

                </div>
                <div class="section-footer"></div>
            </div>

            }

            <div class="section">
                <div class="section-title">
                    AVAILABLE FOR
                </div>
                <div class="section-content">
                    {{ reward.sales_chanels.includes(1) ? 'Online' : '' }}
                    {{ reward.sales_chanels.includes(2) ? (reward.sales_chanels.includes(1) ? ', ' : '') + 'In Store' :
                    '' }}
                    {{ !reward.sales_chanels.includes(1) && !reward.sales_chanels.includes(2) ? 'In Store and Online' :
                    '' }}
                </div>
                <div class="section-footer"></div>
            </div>
            <div class="section">
                <div class="section-title">
                    REWARD TYPE
                </div>
                <div class="section-content">
                    {{ reward.reward_type === 1 ? 'Discount based on Order Value' : reward.reward_type === 5 ?
                    'Free/Discount Item based on order value' : reward.reward_type === 3 ? 'Item Discount':
                    reward.reward_type === 4 ? 'Buy X Get Y':'Not Defined'}}

                </div>
                <div class="section-footer"></div>
            </div>
            <div class="section status-container">
                <div class="section-title">
                    STATUS
                </div>
                <div class="section-content">
                    <div class="reward-active-status">
                        @if (reward.status === 1) {
                        <div class="status active">
                            Active <span></span>
                        </div>
                        } @else if (reward.status === 2) {
                        <div class="status exipred">
                            Expired
                        </div>
                        } @else {
                        <div class="status inactive">
                            Unpublished
                        </div>
                        }
                    </div>
                    <span style="margin-top:5px;"> {{ getUpdatedDate(reward) | date:dateFormat }}
                    </span>
                </div>
                <div class="section-footer"></div>
            </div>


            <div class="section-content" style="margin-left: 10px; margin-top: 10px">
                <ng-container *ngIf="
          reward.created_by !== null ||
          reward.created_by === 2 ||
          reward.created_by === 1
        ">
                    <strong>Created</strong><br /> Admin &nbsp;&nbsp;&nbsp;&nbsp;
                    {{
                    reward.created_at
                    | date
                    : dateTimeFormat
                    }} </ng-container><br /><br />
                <ng-container *ngIf="
          reward.updated_by !== null ||
          reward.updated_by === 2 ||
          reward.updated_by === 1
        ">
                    <strong>Updated</strong><br /> Admin &nbsp;&nbsp;&nbsp;&nbsp;
                    {{
                    reward.updated_at
                    | date
                    : dateTimeFormat
                    }}
                </ng-container>
            </div>
        </div>
        <div class="page-footer">
            <button mat-button color="primary" class="btn-edit" (click)="onEditAction()"
                [disabled]="reward.status !== 0"><mat-icon>edit</mat-icon>Edit</button>
            <button mat-button color="accent" class="btn-delete" (click)="onDeleteAction()"
                [disabled]="reward.status !== 0">
                <mat-icon>delete</mat-icon>Delete</button>
        </div>
    </div>

    <div class="no-page-data" *ngIf="show && reward==undefined">
        <img src="assets/images/side-bar-no-data.png">
        <span class="no-data-info">Nothing to show. Please select a reward.</span>
    </div>

</div>
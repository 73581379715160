import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-winner-status-confirmation-dialog',
  templateUrl: './winner-status-confirmation-dialog.component.html',
  styleUrl: './winner-status-confirmation-dialog.component.scss'
})
export class WinnerStatusConfirmationDialogComponent {
  
  constructor(
    public dialogRef: MatDialogRef<WinnerStatusConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  
 public data: any) {}

  onConfirmClick():void {
    this.dialogRef.close(true);
  }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }
}

<h1 mat-dialog-title class="edit-form-title">
    Purchases Trend(Day Based)
    <button mat-icon-button mat-dialog-close aria-label="Close" class="close-btn">
        <mat-icon>close</mat-icon>
    </button>
</h1>

<mat-dialog-content class="edit-form-content">
    <p class="p-text">
        The number of purchases recorded on various weekdays. Derived from the most recent RFM calculation.
    </p>
    <table class="day-purchases-table">
        <tr>
            <th class="day-week">Day of the Week</th>
            <th># Purchases</th>
        </tr>
        <tr>
            <td class="align-right">Sunday</td>
            <td>{{customer.purchase_sun}}</td>
        </tr>
        <tr>
            <td class="align-right">Monday</td>
            <td>{{customer.purchase_mon}}</td>
        </tr>
        <tr>
            <td class="align-right">Tuesday</td>
            <td>{{customer.purchase_tue}}</td>
        </tr>
        <tr>
            <td class="align-right">Wednesday</td>
            <td>{{customer.purchase_wed}}</td>
        </tr>
        <tr>
            <td class="align-right">Thursday</td>
            <td>{{customer.purchase_thu}}</td>
        </tr>
        <tr>
            <td class="align-right">Friday</td>
            <td>{{customer.purchase_fri}}</td>
        </tr>
        <tr>
            <td class="align-right">Saturday</td>
            <td>{{customer.purchase_sat}}</td>
        </tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer">
    <button mat-raised-button color="warn" (click)="cancel()">Close</button>
</mat-dialog-actions>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserModel } from '../../model/user.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { UserEditDialogComponent } from '../../dialogs/user-edit-dialog/user-edit-dialog.component';
import { UserService } from '../../user.service';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';

@Component({
  selector: 'app-user-list-item',
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.scss']
})
export class UserListItemComponent {

  @Input() userInfo: any;
  @Output() edit: EventEmitter<any> = new EventEmitter();

  
  result: any;
  userSe: any;

  constructor(private dialog: MatDialog, private userService:UserService, private snackBarService:SnackBarService) { }

  /**
   * On edit the item
   * Show the edit dialog
   */
  onEditClick(): void {

    this.edit.emit(this.userInfo);
  }

  /**
   * On delete menu item selected
   * Confirm the user action and call the api to update
   */
  onDeleteClick(): void {
    const message = `Are you sure, you want to delete ` + (this.userInfo.fname + " " + this.userInfo.lname + "?");
    const dialogData = new ConfirmDialogModel("Confirm Deletion", message);
    
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (!dialogResult) {
        console.log('deleted');
      }
    });
  }


  /**
   * On status changed
   * Confirm the user action and call the api to update
   */
  onStatusChange(): void {

    const message = `Are you sure you want to change the status to ` + (this.userInfo.active ? "Active" : "Inactive");
    const dialogData = new ConfirmDialogModel("Confirm Status Change", message);
    
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
       
        this.userService.update(this.userInfo);
        let snckBarData:SnackBarData ={
          type: 'success',
          title: 'Status Update',
          message: 'Status has been updaated succesfully for ' + this.userInfo.fname + " " + this.userInfo.lname
        }
        this.snackBarService.openSnackBar(snckBarData);
      }else{
        this.userInfo.active = !this.userInfo.active;
      }
    });
  }
}

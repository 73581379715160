import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-reward-product-items-dialog',
  templateUrl: './reward-product-items-dialog.component.html',
  styleUrls: ['./reward-product-items-dialog.component.scss']
})
export class RewardProductItemsDialogComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['select', 'name'];
  selection: SelectionModel<any>;
  browseBy: any;
  showSelected: boolean = false;


  constructor(
    public dialogRef: MatDialogRef<RewardProductItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dataSource = new MatTableDataSource<any>(data.items);
    this.selection = new SelectionModel<any>(true, data.selectedItems ?? []);
    this.browseBy = data.browseBy;
    

  }


  ngOnInit(): void {
    // this.dataSource = new MatTableDataSource<any>(this.data.items);
    // this.selection = new SelectionModel<any>(true, this.data.selectedItems);

    // console.log('Received data:', this.data);
    // console.log('DataSource Data:', this.dataSource.data);
    
    
  }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  
 isRowSelected(row: any): boolean {
  return this.selection.isSelected(row);
}
  showSelectedItems(): void {
    if (this.showSelected) {
      // If checkbox is checked, update displayed data based on selected items
      this.dataSource.data = this.dataSource.data.filter(item => this.isRowSelected(item));
    } else {
      this.dataSource = new MatTableDataSource<any>(this.data.items);

  }
  }

  toggleAllRows(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  save(): void {
    const selectedItems = this.selection.selected;
    this.dialogRef.close({ selectedItems: selectedItems });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}

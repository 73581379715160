<div class="edit-page-root-container reward-edit-root-container">
  <div class="page-content">
    <div class="page-header ">
      <button mat-button class="nav-button" (click)="navBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="title">
        {{ getTitle() }}
      </div>
      <div class="action-btn-container">
        @if(permission.can_publish){
        <button mat-flat-button class="action-btn btn-browse" (click)="publishLuckyDraw(luckyDrawInfoModel.id)"
          *ngIf="allowPublishManually == true && isStatusNotOneOrTwo" [hidden]="!isStatusNotOneOrTwo">
          PUBLISH
        </button>
        }
        <button mat-flat-button class="action-btn btn-discard" (click)="navBack()" *ngIf="isStatusNotOneOrTwo"
          [hidden]="!isStatusNotOneOrTwo">
          DISCARD
        </button>

        <button mat-flat-button class="action-btn btn-save" (click)="save()" *ngIf="isStatusNotOneOrTwo"
          [hidden]="!isStatusNotOneOrTwo">
          SAVE
        </button>
      </div>

    </div>
    <!-- Add a notification element at the top -->
    <div id="notification" class="notification"
      style="display: none; text-align: center; margin-top:10px; background: yellow; color:red; padding:15px;">
      This Draw has already been published. You cannot edit anymore.
    </div>
    <div class="page-details">

      <!-- general info -->
      <form class="edit-form" [formGroup]="editForm">

        <div class="detail-section section-general-info" style="margin-top: 0%;">

          <div class="content-container">
            <div class="section-partition" style="display: flex;gap:20px;">
              <div class="partition-left">
                <mat-form-field class="code">
                  <mat-label>Code</mat-label>
                  <input matInput formControlName="code" [attr.maxlength]="10"
                    [readonly]="luckyDrawInfoModel.id!==undefined && luckyDrawInfoModel.id!==0"
                    pattern="[a-zA-Z0-9\s\W]*" />
                  <mat-error [@if]="editForm.controls['code'].hasError('required')">
                    Code should be entered</mat-error>
                </mat-form-field>
              </div>
              <div class="partition-right">
                <mat-form-field class="name" style="width: 500px;">
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="name" [attr.maxlength]="50">
                  <mat-error
                    *ngIf="editForm.controls['name'].hasError('required') && !editForm.controls['name'].value">Name
                    should be entered</mat-error>

                </mat-form-field>
              </div>
            </div>


            <!-- <div class="section-partition"> -->
            <mat-form-field class="description" style="width:100%;">
              <mat-label>Description</mat-label>
              <textarea matInput formControlName="description" [attr.maxlength]="255" style="height: 80px;"></textarea>
            </mat-form-field>
            <mat-form-field class="description" style="width:100%;">
              <mat-label>Main Draw</mat-label>
              <mat-select formControlName="crm_lucky_draw_hdr_id" placeholder="Main Draw"
                [(ngModel)]="luckyDrawInfoModel.crm_lucky_draw_hdr_id">
                @for (draw of mainDrawList; track draw.id) {
                <mat-option [value]="draw.id">
                  {{ draw.name }}
                </mat-option>
                }
              </mat-select>
              <mat-error [@if]="editForm.controls['crm_lucky_draw_hdr_id'].hasError('required')">
                Draw should be selected</mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
      <!--Reward settings start-->
      <!-- Entry Period info start-->
      <div class="detail-section">
        <div class="sub-title">Reward Settings</div>
        <div class="content-container">
          <div class="section-partition" style="display: flex;gap:30px;margin-top: 20px;">
            <!-- <div class="reward-title">Add reward</div> -->
            <button style="width:150px;" mat-raised-button color="primary" (click)="openRewardBrowseDialog()">
             Rewards </button>
          </div>
          <div class="section-content order-value-discount-item">
            <div class="input-container">
              <div class=" offer-item-list-container">
                @if (this.selectedItems.length == 0) {
                  <div class="input-row offer-item-container" style="color: red;justify-content: center;font-size: 14px;margin-top: 7px;">
                    <p>No Rewards were added. Add one or more rewards.</p>
                  </div>
                }@else {
                @for (offer of this.selectedItems; track offer.crm_reward_id; let i = $index) {
                <div class="input-row offer-item-container" style="margin-top: 10px;">
                  <input class="qty-input" type="number" id="myCheckbox{{i}}" [(ngModel)]="offer.count" required
                    [disabled]="isDisabled()" />
                  X
                  <span class="duplicate-item"><b>{{ getRewardName(offer.crm_reward_id)
                      }}</b></span>
                  <mat-icon class="delete-button"
                    style="margin: auto;margin-right: 10px;color: #ff7a7a;cursor: pointer;"
                    (click)="removeFromReward(offer.crm_reward_id)">delete_forever</mat-icon>
                </div>
                }
              }
              </div>
            </div>
          </div>
          <!-- <div class="input-container offer-item-list-container">
                  <div class="section-content order-value-discount-item">
                    @for (offer of selectedItems; track offer.id; let i = $index) {
                    <div class="input-row offer-item-container">
                      <input class="qty-input" type="number" id="myCheckbox{{i}}" [(ngModel)]="offer.count" required readonly
                        [disabled]="isDisabled()" />
                      X
                      <span><b>{{ offer.name}}</b></span>
                      <mat-icon class="delete-button" (click)="removeFromReward(offer.id)">delete_forever</mat-icon>
                    </div>
                    }
                  </div>
                </div> -->
        </div>
      </div>
      <!--Reward settings end-->

      <!-- Entry Period info start-->
      <div class="detail-section">
        <div class="sub-title">Entries Between</div>
        <div class="content-container">
          <div class="section-partition" style="display: flex;gap:20px;margin-top: 20px;">
            <mat-form-field class="example-full-width">
              <mat-label>From</mat-label>
              <input matInput [matDatepicker]="picker" placeholder="Choose a date" [(ngModel)]="date_from"
                [ngClass]="{'is-invalid': !isDateInFuture()}" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              @if (!date_from) {
              <mat-error>Start Date should be entered</mat-error>
              }
            </mat-form-field>
            <!-- </div> -->
            <div class="section-partition" style="display: flex;gap:20px;">
              <mat-form-field class="example-full-width">
                <mat-label>To</mat-label>
                <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" [(ngModel)]="this.date_to"
                  [min]="this.luckyDrawInfoModel.id == 0?this.date_from:this.date_from"
                  [ngClass]="{'is-invalid': !isValidEndDate()}" />

                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
                @if (!date_to) {
                <mat-error>End Date should be entered</mat-error>
                }
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- Validity info end-->


    </div>
    <!--Statutory details end-->
import { Component, ViewEncapsulation } from '@angular/core';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { SegmentModel } from './model/segment.model';
import { SegmentService } from './segment.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import { FilterListItemModel, SearchParam } from 'src/app/common/components/search-filter/search-filter.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss']
})
export class SegmentComponent {

  segmentList: any[];
  ratingList = [];
  showSearch: boolean = false;
  isLoading = true;
  filter: FilterListItemModel[] = [
    {
      title: "Code",
      column: "code",
    },
    {
      title: "Name",
      column: "name",
    },
    {
      title: "Recency",
      column: "recency_rating_id",
      type: "list",
      values: []
    },
    {
      title: "Frequency",
      column: "frequency_rating_id",
      type: "list",
      values: []
    },
    {
      title: "Monetary",
      column: "monetary_rating_id",
      type: "list",
      values: []
    },
    {
      title: "Active",
      column: "is_valid",
      type: "boolean",
      values: [
        { title: "Active", value: "1" },
        { title: "Not Active", value: "0" },
      ]
    },
  ];

  displayedColumns = ['code', 'name', 'recency', 'frequency', 'monetary', "loyalty_per_dollar", "is_valid","action"];
  pagingData = { length: 0, pageSize: 10, pageIndex: 0 };
  menuOption: FloatingMenuOption = {
    add: true,
    search: false,
    export: true,
    more: true
  }

  isHandset: boolean = false;
  searchColumFilter: SearchParam = {};
  columnSortData: SearchSort[] = [];
  selectedItem?: SegmentModel;
  showSegmentSummary: boolean = false;
  selection = new SelectionModel<SegmentModel>(true, []);

  constructor(private breakpointObserver: BreakpointObserver,
    private segmentService: SegmentService,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,
    private router: Router) {

    this.segmentList = [];
  }

  /**
   * initilaize the device model
   */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    });
    this.loadRating();
    this.loadData();
  }
  loadRating() {

    let response = this.segmentService.getRfmRatingList().subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.ratingList = response.data.rating_list;

        let values = (this.ratingList.map((rating: any) => {
          return { title: rating.name, value: rating.id };
        }));

        this.filter[2].values = values;
        this.filter[3].values = values;
        this.filter[4].values = values;
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.segmentList.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.segmentList);
  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }


  /**
   * Builds the request data to be send to API
   * @returns Request Data
   */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      }
    };

    return requestData;
  }

  /**
   * loads the segment data based on the conditions
   */
  loadData(): void {
    this.isLoading = true;
    let requestData = this.buildRequestData();
    let response = this.segmentService.getSegmentList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.segmentList = response.data.segments;
        this.pagingData.length = response.data.total_count;
        if (this.pagingData.length == 0) {
          let snackBarData: SnackBarData = {
            message: 'No data found. Please remove or change the filter if any.',
            title: 'No Data!!!',
            type: 'warn'
          }
          this.snackBarService.openSnackBar(snackBarData);
          this.selectedItem = undefined;
        } else {
          this.onRowSelected(this.segmentList[0])
        }
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
      this.isLoading = false;
    });

  }

  /**
   * On Search toggle button is clicked
   */
  toggleSearch(): void {
    this.showSearch = !this.showSearch
  }

  /**
   * On Add clicked
   * Show the dialog to enter new segment
   */
  addClicked(): void {

    this.router.navigate(['segment-edit', 0]);

  }

  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {

    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }

  /**
 * On edit the item
 * Show the edit dialog
 */
  onEdit(segmentInfo: SegmentModel): void {

    this.router.navigate(['segment-edit', segmentInfo.id]);
  }

  /**
   * Set the column filter and reaload the daaa
   * @param columFilter 
   */

  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();

  }

  /**
   * On delete menu item selected
   * Confirm the segment action and call the api to update
   */
  onDelete(segmentInfo: SegmentModel): void {
    const segmentId = segmentInfo.id;
    const message = `Are you sure, you want to delete ` + (segmentInfo.name + "?");
    const dialogData = new ConfirmDialogModel("Confirm Deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult);
      if (dialogResult) {
        this.segmentService.delete(segmentInfo).subscribe({
          next: (response) => {
            if (response.status == 'SUCCESS') {
              // handle success
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'The record for ' + segmentInfo.name + " has been deleted succesfully."
              }
              this.snackBarService.openSnackBar(snckBarData);
              this.loadData();
            } else {
              let snackBarData: SnackBarData = {
                message: response.message,
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
            }
          },
          error: (error) => {
            let snackBarData: SnackBarData = {
              message: 'Failed to update. Connection to the server failed',
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        });
      }
    });
  }

  /**
   *  Set the sort param and relead the data
   * @param $event sort data
   * {"active": "email","direction": "asc"}
   *   
   */
  sortData(sd: any): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }

  /**
 * On segment selected
 * @param segmentInfo 
 */
  onRowSelected(segmentInfo: SegmentModel): void {
    this.selectedItem = segmentInfo;
    this.showSegmentSummary = true;
  }

  /**
* Creates the column filter conditions based on search criteria
* @returns Filter condions for columns
*/
  getSearchParam(): SearchParam {

    return this.searchColumFilter;
  }

}

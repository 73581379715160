import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RewardCategoryModel } from '../../model/reward-category.model';
import { AppConstants } from 'src/app/common/app.constants';
import { RewardCategoryEditDialogComponent } from '../../dialogs/reward-category-edit-dialog/reward-category-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RewardCategoryComponent } from '../../reward-category.component';
import { SystemSettingsService } from 'src/app/common/services/system-settings.service';

@Component({
  selector: 'app-reward-category-summary',
  templateUrl: './reward-category-summary.component.html',
  styleUrls: ['./reward-category-summary.component.scss']
})
export class RewardCategorySummaryComponent {
  @Input() rewardCategory: any;

  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  show: boolean = false;
  rewardCategories: any;
  dateTimeFormat: string;
 
  constructor(
    private dialog: MatDialog,
    private rewardCategoryComponent: RewardCategoryComponent,
    private systemSettingService: SystemSettingsService
  ) {
    let sessionTag =sessionStorage.getItem(AppConstants.SESSION_VAR_TAGS);
    this.rewardCategories = (sessionTag!==null)?JSON.parse(sessionTag):[];
    this.dateTimeFormat = systemSettingService.getDefaultDateTimeFormat();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rewardCategory']) {
     // this.show = (this.rewardCategory !== undefined);
      
    }
  }

  close(): void {
    this.show = !this.show;
  }

  onEditAction() {
    this.onEdit.emit(this.rewardCategory);
  }

  onDeleteAction() {
    this.onDelete.emit(this.rewardCategory);
  }
}

import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { SystemSettingsService } from 'src/app/common/services/system-settings.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent {
  @Input() order: any;

  // @Output() onEdit: EventEmitter<any> = new EventEmitter();
  // @Output() onDelete: EventEmitter<any> = new EventEmitter();

  show: boolean = false;
  dateFormat: string;

  constructor(private systemSettingService: SystemSettingsService) { 
    this.dateFormat = systemSettingService.getDefaultDateFormat();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['order']) {
      //this.show = (this.order !== undefined);

    }
  }

  /**
   * Closes the side panel
   */
  close(): void {
    this.show = !this.show;
  }
  /**
   * Edit the current selected item
   */
  // onEditAction() {
  //   this.onEdit.emit(this.order);
  // }

  /**
   * Delete the currnet selected item.
   */
  // onDeleteAction() {
  //   this.onDelete.emit(this.order);
  // }
}


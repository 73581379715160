import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RewardService } from '../../reward.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { RewardProductItemsDialogComponent } from '../reward-product-items-dialog/reward-product-items-dialog.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-reward-product-browse-dialog',
  templateUrl: './reward-product-browse-dialog.component.html',
  styleUrls: ['./reward-product-browse-dialog.component.scss']
})
export class RewardProductBrowseDialogComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<RewardProductBrowseDialogComponent>,
    // private rewardService: RewardService,
    // private snackBarService: SnackBarService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any // Inject the data passed from the RewardEditComponent
  ) {

  }

  ngOnInit(): void {
   // console.log('Received items data:', this.items);
  }
  cancel(): void {
    this.dialogRef.close();
  }
  onTypeSelected(type:any):void{
    this.dialogRef.close({type:type});
    
  }


}

<ng-container *ngIf="isLoggedIn(); then dashboard; else login">
</ng-container>
<ng-template #dashboard>
    <div class="main-container">
        <app-toolbar class="app-toolbar" (toggleSidenav)="sidenav.toggleSidenav()"></app-toolbar>
        <app-sidenav class="app-sidenav" #sidenav></app-sidenav>
    </div>
</ng-template>
<ng-template #login>
    <app-login></app-login>
</ng-template>
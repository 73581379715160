import { Component, Inject, ViewEncapsulation, NgModule, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterService } from '../../filter.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { MatTableDataSource } from '@angular/material/table';
import { FilterModel } from '../../model/filter.model';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-search-filter-load-dialog',
  templateUrl: './search-filter-load-dialog.component.html',
  styleUrls: ['./search-filter-load-dialog.component.scss']
})
export class SearchFilterLoadDialogComponent {
  searchFilterList: any;
  selectedItem: any;
  selectedFilter?: FilterModel;
  @Output() remove: EventEmitter<any> = new EventEmitter();

  displayedColumns: string[] = ['name', 'is_favorite', 'no_of_filters','action'];
  dataSource: MatTableDataSource<any>;


  constructor(private builder: FormBuilder,
    public dialogRef: MatDialogRef<SearchFilterLoadDialogComponent>,
    private filterService: FilterService,
    @Inject(MAT_DIALOG_DATA) public item: any,
    public dialog: MatDialog,
    private snackBarService: SnackBarService,) {
    this.searchFilterList = JSON.parse(JSON.stringify(item));
    this.dataSource = new MatTableDataSource(this.searchFilterList);

  }


  /**
 * Close the edit dialog
 */
  cancel(): void {
    this.dialogRef.close();
  }

  save(selectedFilter: any): void {
    this.dialogRef.close(selectedFilter);
  }


  /**
   * Apply the filter on table contents4
   * 
   * @param filterValue 
   */
  applyFilter(event: any) {
    let filterValue = event.target.value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  onRowSelected(filterInfo: any): void {
    this.selectedFilter = filterInfo;
  }
  /**
   * Delete this filter
   * Remove the items from fiter array.
   */
  onDelete(filterInfo: FilterModel): void {
    const filterId = filterInfo.id;
    const message = `Are you sure, you want to delete ` + (filterInfo.name + "?");
    const dialogData = new ConfirmDialogModel("Confirm Deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult);
      if (dialogResult) {
        this.filterService.delete(filterInfo).subscribe({
          next: (response) => {
            if (response.status == 'SUCCESS') {
              // handle success
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'The record for ' + filterInfo.name + " has been deleted succesfully."
              }
              this.snackBarService.openSnackBar(snckBarData);
            } else {
              let snackBarData: SnackBarData = {
                message: response.message,
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
            }
          },
          error: (error) => {
            let snackBarData: SnackBarData = {
              message: 'Failed to update. Connection to the server failed',
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        });
      }
    });
  }

}

import { Injectable } from "@angular/core";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class SystemFunctionService {

  constructor(private http: HttpClient) { }

  /**
   *
   * @returns The list of users
   * Calls the API to get the list of users from server
   */
  getSystemById(id: number): Observable<any> {
    return this.http.post(environment.baseUrl + '/user-group-permission/get/' + id, '');

  }
  /**
 * 
 * @param systemInfo 
 * Calls the update API to insert/update the record
 */
  update(systemInfo: any): Observable<any> {
    if (Array.isArray(systemInfo) && systemInfo.some(element => element.hasOwnProperty("auto_id") && element.auto_id !== null)) {
      return this.http.post(environment.baseUrl + '/user-group-permission/update', systemInfo);

    }

    return this.http.post(environment.baseUrl + '/user-group-permission/add', systemInfo);
  }


}


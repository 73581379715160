<button class="ext-sort" mat-flat-button (click)="isVisible=!isVisible"><mat-icon>sort</mat-icon>Sort</button>
<div  *ngIf="isVisible" (click)="isVisible=!isVisible" class="overlay"></div>
<div *ngIf="isVisible" class="sort-option-contaiiner">
    <div class="header">

    </div>
    <div class="content">

    </div>
    <div class="footer">
        <button mat-button color="warn">Reset</button>
        <button mat-button color="primary">Apply</button>
        <button mat-button color="accent" (click)="isVisible=!isVisible">Cancel</button>
    </div>
</div>
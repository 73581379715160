import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from './auth/auth.guard';
import { UserComponent } from './pages/user/user.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { OrderComponent } from './pages/order/order.component';
import { RewardCategoryComponent } from './pages/reward-category/reward-category.component';
import { RewardComponent } from './pages/reward/reward.component';
import { TagComponent } from './pages/tag/tag.component';
import { DiscountComponent } from './pages/discount/discount.component';
import { SegmentComponent } from './pages/segment/segment.component';
import { UserGroupComponent } from './pages/user-group/user-group.component';
import { TagEditComponent } from './pages/tag/tag-edit/tag-edit.component';
import { FormDeactivateGuard } from './common/guards/form-deactivate.guard';
import { SegmentEditComponent } from './pages/segment/segment-edit/segment-edit.component';
import { RewardCategoryEditComponent } from './pages/reward-category/reward-category-edit/reward-category-edit.component';
import { RewardEditComponent } from './pages/reward/reward-edit/reward-edit.component';
import { UserGroupEditComponent } from './pages/user-group/user-group-edit/user-group-edit.component';
import { RfmSettingsComponent } from './pages/rfm-settings/rfm-settings.component';
import { AnalysisComponent } from './pages/analysis/analysis.component';
import { ReportComponent } from './pages/report/report.component';
import { CustomerEditComponent } from './pages/customer/customer-edit/customer-edit.component';
import { UserEditComponent } from './pages/user/user-edit/user-edit.component';
import { SystemFunctionComponent } from './pages/system-function/system-function.component';
import { LuckyDrawSettingEditComponent } from './pages/lucky-draw-settings/lucky-draw-settings-edit/lucky-draw-settings-edit.component';
import { LuckyDrawEntryComponent } from './pages/lucky-draw-entry/lucky-draw-entry.component';

import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { LuckyDrawSettingComponent } from './pages/lucky-draw-settings/lucky-draw-settings.component';
import { LuckyDrawComponent } from './pages/lucky-draw/lucky-draw.component';
import { LuckyDrawEditComponent } from './pages/lucky-draw/luckydraw-edit/luckydraw-edit.component';
import { LuckyDrawWinnerComponent } from './pages/lucky-draw-winner/lucky-draw-winner.component';
const routes: Routes = [
  { component: LoginComponent, path: 'login' },
  { component: AccessDeniedComponent, path: 'access-denied' },

  { component: DashboardComponent, path: '', canActivate: [AuthGuard] },
  { component: DashboardComponent, path: 'home', canActivate: [AuthGuard] },
  { component: RewardComponent, path: 'reward', canActivate: [AuthGuard] },
  { component: CustomerComponent, path: 'customer', canActivate: [AuthGuard] },
  { component: CustomerEditComponent, path: 'customer-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: OrderComponent, path: 'order/:custid', canActivate: [AuthGuard] },
  { component: OrderComponent, path: 'order', canActivate: [AuthGuard] },


  { component: TagComponent, path: 'tag', canActivate: [AuthGuard] },
  { component: TagEditComponent, path: 'tag-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: RewardEditComponent, path: 'reward-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: SegmentComponent, path: 'segment', canActivate: [AuthGuard] },
  { component: SegmentEditComponent, path: 'segment-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: DiscountComponent, path: 'discount', canActivate: [AuthGuard] },
  { component: UserComponent, path: 'users', canActivate: [AuthGuard] },
  { component: UserEditComponent, path: 'user-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },

  { component: UserGroupComponent, path: 'user-group', canActivate: [AuthGuard] },
  { component: RewardCategoryComponent, path: 'reward-category', canActivate: [AuthGuard] },
  { component: RfmSettingsComponent, path: 'rfm-setting', canActivate: [AuthGuard] },
  { component: RewardCategoryEditComponent, path: 'reward-category-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: UserGroupEditComponent, path: 'user-group-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: ReportComponent, path: 'report', canActivate: [AuthGuard] },
  { component: AnalysisComponent, path: 'analysis', canActivate: [AuthGuard] },
  { component: SystemFunctionComponent, path: 'system-function/:id', canActivate: [AuthGuard] },
  { component: LuckyDrawWinnerComponent, path: 'draw-winner/:drawId', canActivate: [AuthGuard] },
  { component: LuckyDrawWinnerComponent, path: 'draw-winner', canActivate: [AuthGuard] },

  { component: LuckyDrawSettingComponent, path: 'draw-setting', canActivate: [AuthGuard] },
  { component: LuckyDrawSettingEditComponent, path: 'draw-setting-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  { component: LuckyDrawEntryComponent, path: 'draw-entry', canActivate: [AuthGuard] },
  { component: LuckyDrawComponent, path: 'draw', canActivate: [AuthGuard] },
  { component: LuckyDrawEditComponent, path: 'draw-edit/:id', canActivate: [AuthGuard], canDeactivate: [FormDeactivateGuard] },
  
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [FormDeactivateGuard]
})
export class AppRoutingModule { }

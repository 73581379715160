import { Injectable } from '@angular/core';
import { LdEntryModel } from './model/lucky-draw-entry.model';
import { RequestData } from 'src/app/common/models/request-data.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class LuckyDrawEntryService {

  constructor(private http: HttpClient) { }

  getList(requestData:RequestData): Observable<any> {
    return this.http.post(environment.baseUrl+'/lucky-draw/get-list', requestData);

  }


  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.baseUrl + '/lucky-draw/export-entry-list', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }
  getLuckyDrawHdr(): Observable<any>{
    let search={"search":{"filters":{"column":[],"scope":{"limit":10,"offset":0}},"sort":[]}};
    return this.http.post(environment.baseUrl+'/lucky-draw/get', search);
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-reward-submenu',
  templateUrl: './reward-submenu.component.html',
  styleUrls: ['./reward-submenu.component.scss']
})


export class RewardSubmenuComponent {

  gotFocus:boolean=false;
  showRewardMenu:boolean=false;

  @Input() 
  set show(value:boolean){
    if(!value){
      setTimeout(() => {
        this.showRewardMenu=(this.gotFocus?true:false)
      }, 100);
      
    }else
      this.showRewardMenu=value;
  }

  closeSidenav():void{
    this.showRewardMenu=false;
    this.gotFocus=false;
  }

}

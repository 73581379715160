 <h1 mat-dialog-title class="edit-form-title fixed-title">{{ browseBy == 'product' ? 'Products' : 'Category' }}</h1>
<mat-dialog-content class="edit-form-content">
    <mat-form-field class="fixed-filter">
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
    </mat-form-field>
    <mat-checkbox [(ngModel)]="showSelected" (change)="showSelectedItems()">Show selected items only</mat-checkbox>
    <div class="scrollable-content">

        <table mat-table [dataSource]="dataSource">

            <!-- Tick Icon Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef style="width: 50px;">
                    <!-- Empty header cell for the tick icon -->
                </th>
                <td mat-cell *matCellDef="let row" (click)="selectRow(row)" [class.selected-row]="isRowSelected(row)">
                    <!-- Display mat-tick icon if the row is selected -->
                    <mat-icon *ngIf="isRowSelected(row)" style="color: green;">done</mat-icon>
                </td>

            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Product</th>
                <td mat-cell *matCellDef="let item" [ngClass]="{ 'selected-row': selection.isSelected(item) }" (click)="selectRow(item)" >
                    {{ item.name }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer">
    <button mat-raised-button color="primary" (click)="save()">OK</button>
    <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
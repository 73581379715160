import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  title: string;
  message: string;
  showNoButton:boolean=true;
  noButtonCaption: string='No';
  yesButtonCaption: string='Yes';

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.showNoButton= (data.showNoButton !==  undefined && data.showNoButton == false ) ? false : true;
    this.noButtonCaption=(data.noButtonCaption !==  undefined) ? data.noButtonCaption : 'No';
    this.yesButtonCaption=(data.yesButtonCaption !==  undefined ) ? data.yesButtonCaption : 'Yes';
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
  showNoButton: boolean=true;
  noButtonCaption: string='No';
  yesButtonCaption: string='Yes';

  constructor(public title: string, public message: string) {
  }
}

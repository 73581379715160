<div class="edit-page-root-container segment-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" (click)="save()">SAVE</button>
            </div>
        </div>
        <div class="page-details">
            <form class="edit-form" [formGroup]="editForm">
                <div class="detail-section">
                    <div class="content-container">
                        <mat-form-field class="code">
                            <mat-label>Code</mat-label>
                            <input matInput formControlName="code" maxlength="50"
                                [readonly]="segmentInfo.id!==undefined && segmentInfo.id!==0">
                            <mat-error *ngIf="editForm.controls['code'].hasError('required')">Segment code should be
                                entered</mat-error>
                            <mat-error *ngIf="editForm.controls['code'].hasError('maxlength')">Maximum length is
                                10.</mat-error>
                        </mat-form-field>
                        <mat-form-field class="name">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name">
                            <mat-error *ngIf="editForm.controls['name'].hasError('required')">Segment name should be
                                entered</mat-error>
                            <mat-error *ngIf="editForm.controls['name'].hasError('maxlength')">Maximum length is
                                50.</mat-error>
                        </mat-form-field>
                        <mat-form-field class="description">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description"></textarea>
                            <mat-error *ngIf="editForm.controls['description'].hasError('maxlength')">Maximum length is
                                200.</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="detail-section">
                    <div class="content-container">
                        <div class="sub-title">This segment will be assigned to the customer with these RFM ratings.*<br>
                        </div>
                        <mat-form-field>
                            <mat-label>Recency Rating</mat-label>
                            <mat-select formControlName="recency_rating_id">
                                <mat-option *ngFor="let option of ratingList" [value]="option.id">
                                  {{ option.name }}
                                </mat-option>
                              </mat-select>
                              <mat-error *ngIf="editForm.controls['recency_rating_id'].hasError('required')">Rating should be
                                selected.</mat-error>
                          
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Frequency Rating</mat-label>
                            <mat-select formControlName="frequency_rating_id">
                                <mat-option *ngFor="let option of ratingList" [value]="option.id">
                                  {{ option.name }}
                                </mat-option>
                              </mat-select>
                              <mat-error *ngIf="editForm.controls['frequency_rating_id'].hasError('required')">Rating should be
                                selected.</mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Monetary Rating</mat-label>
                            <mat-select formControlName="monetary_rating_id">
                                <mat-option *ngFor="let option of ratingList" [value]="option.id">
                                  {{ option.name }}
                                </mat-option>
                              </mat-select>
                              <mat-error *ngIf="editForm.controls['monetary_rating_id'].hasError('required')">Rating should be
                                selected.</mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="detail-section">
                    <div class="content-container">
                        <div class="sub-title">Loyality points per each dollar
                        </div> 
                        <div class="loyality-point-content">
                            <span>Every one dollar purchase will accure</span>
                            <mat-form-field class="loyalitypoint">
                                <mat-label>Points</mat-label>
                                <input matInput type="number" formControlName="loyalty_per_dollar" maxlength="5">
                                <mat-error *ngIf="editForm.controls['loyalty_per_dollar'].hasError('required')">Points should be
                                    entered.</mat-error>
                            </mat-form-field>
                            <span>LOYALITY points.</span>
                        </div>
                        
                        
                    </div>
                </div>


                <div class="detail-section">
                    <div class="content-container">
                        <div class="status-toggle-container">
                            <span class="status-toggle-title">Active</span>
                            <mat-slide-toggle formControlName="is_valid" class="status-toggle"></mat-slide-toggle>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
<div class="master-option-bar-root-container" [style.width]="show ? 'var(--left-side-optionbar-width)' : '25px'">
    <div class="menu-list-container">
        <!-- Masters List -->
        <div class="section-container">
            <div class="content item-content">

                <!-- <mat-list-item class="mat-list-item" routerLink="/users" [routerLinkActive]="['is-active']"
                [matTooltip]="!show ? 'Draw' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                <div class="mat-list-item-contents">
                    <img src="assets/images/menu/svg/spinwin.svg">
                    <span class="master-title">Draw</span>
                </div>
            </mat-list-item> -->

                <mat-list-item class="mat-list-item" routerLink="/draw-setting" [routerLinkActive]="['is-active']"
                    [matTooltip]="!show ? 'Settings' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip" (click)="resetState()">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/ld-setup.svg">
                        <span class="master-title">Settings</span>
                    </div>
                </mat-list-item>



                <mat-list-item class="mat-list-item" routerLink="/draw-entry" [routerLinkActive]="['is-active']"
                    [matTooltip]="!show ? 'Lucky Draw Entry' : ''" matTooltipPosition="right"
                    matTooltipClass="mat-tooltip" (click)="resetState()">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/entries.svg">
                        <span class="master-title">Entries</span>
                    </div>
                </mat-list-item>

                <mat-list-item class="mat-list-item" routerLink="/draw" [routerLinkActive]="['is-active']"
                    [matTooltip]="!show ? 'Lucky Draw Entry' : ''" matTooltipPosition="right"
                    matTooltipClass="mat-tooltip" (click)="resetState()">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/draw.svg">
                        <span class="master-title">Draw</span>
                    </div>
                </mat-list-item>


                <!-- <mat-list-item class="mat-list-item" routerLink="/draw" [routerLinkActive]="['is-active']"
                [matTooltip]="!show ? 'draw' : ''" matTooltipPosition="right"
                matTooltipClass="mat-tooltip" (click)="resetState()">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/draw.svg">
                        <span class="master-title">Draw</span>
                    </div>
                </mat-list-item> -->

            </div>
        </div>
    </div>
</div>
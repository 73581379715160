import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ColumnFilter } from 'src/app/common/models/request-data.model';

import { AppConstants } from 'src/app/common/app.constants';
import { SearchFilterLoadDialogComponent } from 'src/app/common/components/search-filter/dialogs/search-filter-load-dialog/search-filter-load-dialog.component';
import { SearchFilterSaveDialogComponent } from 'src/app/common/components/search-filter/dialogs/search-filter-save-dialog/search-filter-save-dialog.component';
import { FilterService } from 'src/app/common/components/search-filter/filter.service';
import { FilterListItemModel, SearchParam } from 'src/app/common/components/search-filter/search-filter.component';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-reward-cust-filter-dialog',
  templateUrl: './reward-cust-filter-dialog.component.html',
  styleUrls: ['./reward-cust-filter-dialog.component.scss']
})
export class RewardCustFilterDialogComponent {

  selectedColumnFilter: ColumnFilter[] = [];
  selectedFilters: FilterListItemModel[][] = [];
  searchInfo: any;
  filter?: FilterListItemModel[] = [];
  module: any;
  filterInfo: string = "0";
  user: any;

  constructor(public dialogRef: MatDialogRef<RewardCustFilterDialogComponent>,
    private filterService: FilterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private customerService: CustomerService,) {
      
    this.module = data.module;
    this.filter = data.filterOptions;
    this.setFilter(data.columnFilter)
    let sesUser = sessionStorage.getItem(AppConstants.SESSION_VAR_USER);
    this.user = (sesUser !== null) ? JSON.parse(sesUser) : { id: 0 };
    this.searchInfo = { id: 0, name: "", isFavorite: 0, module: this.module, filter: undefined, user_id: this.user.id };


  }


  saveClicked(): void {

    this.searchInfo.filter = this.selectedColumnFilter;
    const dialogRef = this.dialog.open(SearchFilterSaveDialogComponent, {
      width: '90%',
      maxWidth: '500px',
      height: '295px',
      data: this.searchInfo
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.searchInfo = result;
      } else {
        console.log('filter canceled');
      }
    });
  }

  /**
   * Remove all filters from selected filter array.
   */
  onFilterReset(): void {
    this.selectedFilters.length = 0;
    this.selectedColumnFilter.length = 0;
    this.searchInfo = { id: 0, name: "", isFavorite: 0, module: this.module, filter: undefined, user_id: this.user.id };

  }

  /**
   * Add new filter 
   */
  onFilterAdd(): void {

    let newFilter = JSON.parse(JSON.stringify(this.filter));
    this.selectedFilters.push(newFilter);
    this.selectedColumnFilter.push({ column: "", operator: "", value: "" })
  }

  /**
   * 
   * @param filter Remove the filter from selcted filters
   */
  onFilterRemove(index: any): void {
    this.selectedFilters.splice(index, 1);
    this.selectedColumnFilter.splice(index, 1);

  }

  /**
   * Emit the event with the selected filters
   */
  onFilterApply(): void {
    let searchFilterData: SearchParam = { advSearch: this.selectedColumnFilter, freeSearch: undefined };
    this.filterInfo = this.selectedColumnFilter.length + "";
    this.dialogRef.close(this.selectedColumnFilter);
  }

  cancel(): void {
    this.dialogRef.close();
  }


  /**
     * Loads predefined filter from saved list
     */
  onFilterLoad(): void {

    let searchInfo = { search: { filters: { simpleSearch: [], advSearch: [{ column: "module", operator: "=", value: this.module }, { column: "user_id", operator: "=", value: this.user.id }], scope: { limit: 50, offset: 0 } }, sort: [] } }
    this.filterService.getFilter(searchInfo).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          let searchFilter = response.data.filters;
          const dialogRef = this.dialog.open(SearchFilterLoadDialogComponent, {
            width: '90%',
            maxWidth: '500px',
            height: '500px',
            data: searchFilter
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.searchInfo = result;
              this.searchInfo.filter = JSON.parse(result.filter);
              this.setFilter(this.searchInfo.filter);

            } else {
              console.log('filter canceled');
            }
          });


        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        // handle error
        let snackBarData: SnackBarData = {
          message: 'Failed to update. Connection to the server failed',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }
  /**
   * Set the filters from pre loaded json
   */
  setFilter(columFilters: ColumnFilter[], apply?: boolean): void {
    this.selectedFilters.length = 0;
    this.selectedColumnFilter.length = 0;
    columFilters.forEach(f => {
      let newFilter = JSON.parse(JSON.stringify(this.filter));
      this.selectedFilters.push(newFilter);
      this.selectedColumnFilter.push({ column: f.column, operator: f.operator, value: f.value })
    });
    if (apply) {
      this.onFilterApply();
    }
  }

  /**
   * Run the filter
   */
  onFilterRun():void{
    let searchFilterData ={search:{filters:{simpleSearch:[],advSearch:this.selectedColumnFilter,scope:{limit:0,offset:0}},sort:[]}};
    this.customerService.getList(searchFilterData).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          let custCount= response.data.total_count;
          const message = `Your filter return ` +custCount+ ` customers.`;
          const dialogData = new ConfirmDialogModel("Filter Result", message);
          dialogData.showNoButton=false;
          dialogData.yesButtonCaption='OK';
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: "400px",
            data: dialogData
          });
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed to run filter",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to run filter",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      }
    });

  }
}

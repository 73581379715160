<app-lucky-draw-menu></app-lucky-draw-menu>
<div class="lucky-draw-entry-component-container page-root-container">
  <div class="page-header">
    <div class="page-title">
      <img style="height: 24px;" src="assets/images/menu/svg/entries.svg">
      <span>Draw Entries</span>
    </div>
    <div class="page-actions">
      @if(permission.can_export){

      <button mat-button color="accent" class="btn-export" (click)="exportClicked()"
      [disabled]="IsDownloading">
      <img class="download-icon"
              [src]="IsDownloading ? 'assets/images/animated/download/download-anim.svg' : 'assets/images/animated/download/download-no-anim.svg'">
      Export
</button>
      }
      
    </div>
  </div>

  <div class="data-container ">
    <app-loader [show]="isLoading"></app-loader>
    <app-search-filter #searchFilter [pagingData]="pagingData" [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
      (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
    </app-search-filter>

    <section tabindex="0" class="table-container">
      <table matSort mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef (click)="sortData($event)" class="item-header">#</th>
          <td mat-cell *matCellDef="let entry; let i = index" class="item-column">{{
            (pagingData.pageIndex*pagingData.pageSize) + i + 1 }}</td>
        </ng-container> (matSortChange)="sortData($event)">

        <ng-container matColumnDef="name">
          <th mat-sort-header="f_name" mat-header-cell *matHeaderCellDef (click)="sortData($event)" [attr.rowspan]="2"
            style="min-width:100px;">
            Customer</th>
          <td mat-cell *matCellDef="let entry" style="min-width:100px;">
            <div class="col-customer"><span class="cust-name">{{entry.f_name}} {{entry.l_name}} </span>
              <span class="cust-email">{{ entry.email }}</span>
          </div>
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="email">
          <th mat-sort-header="email" mat-header-cell *matHeaderCellDef (click)="sortData($event)">
            Email
          </th>
          <td mat-cell *matCellDef="let entry">{{ entry.email }}</td>
        </ng-container> -->
        <ng-container matColumnDef="member">
          <th mat-sort-header="crm_customer_id"  mat-header-cell *matHeaderCellDef (click)="sortData($event)" style="text-align: center;width:80px;">
            Member
          </th>
          <td mat-cell *matCellDef="let entry" style="padding-right:40px; text-align:center;width:80px;">
            @if(entry.crm_customer_id>0){  
                <span style='font-size:20px;'>&#10004;</span>
          } @else { <span style="display:none"></span> }
           
          </td>
        </ng-container>
        <ng-container matColumnDef="source">
          <th mat-sort-header="order_source" mat-header-cell *matHeaderCellDef (click)="sortData($event)" style="text-align: center;width:100px">
            Order
          </th>
          <td mat-cell *matCellDef="let entry" style="padding-right:40px; text-align:center;width:100px">
            <div class="ld-active-status">
              @if(entry.order_source==1){             
                <div class="status unpublished">
                Instore <span></span>
              </div>
              }@else if(entry.order_source=== 2){
              <div class="status offer">
                Online <span></span>
              </div>

              }
              @else{

              }
            </div>
            

          </td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-sort-header="order_amount" class="amount-header" style="text-align: right;width:120px;" (click)="sortData($event)" mat-header-cell
            *matHeaderCellDef>
            Amount
          </th>
          <td mat-cell *matCellDef="let entry" style="text-align:right;padding-right:40px;width:120px;">
            {{ entry.order_amount | currency:'USD':'symbol':'1.2-2' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="draw">
          <th mat-sort-header="ld_hdr.name" mat-header-cell *matHeaderCellDef (click)="sortData($event)">
            Draw
          </th>
          <td mat-cell *matCellDef="let entry">{{ entry.name }}</td>
        </ng-container>
        <ng-container matColumnDef="entry_time">
          <th mat-sort-header="entry_time" style="width:170px;" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="start-date-header">
            Entry Date
          </th>
          <td mat-cell *matCellDef="let entry" class="start-date-column" style="width:170px;">
            {{ entry.entry_time | date:'dd-MM-yyyy HH:mm' }}

          </td>
        </ng-container>
        <ng-container matColumnDef="start_date">
          <th mat-sort-header="ld_hdr.start_date" style="width:150px;" mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="start-date-header">
            Start Date
          </th>
          <td mat-cell *matCellDef="let entry" style="width:150px;" class="start-date-column">
            <!-- {{ getUpdatedDate(item) }} -->
            {{ entry.start_date | date: dateFormat }}

          </td>
        </ng-container>
        <ng-container matColumnDef="end_date">
          <th mat-sort-header="ld_hdr.end_date"  style="width:150px;"mat-header-cell *matHeaderCellDef (click)="sortData($event)"
            class="start-date-header">
            End Date
          </th>
          <td mat-cell *matCellDef="let entry" style="width:150px;" class="start-date-column">
            <!-- {{ getUpdatedDate(item) }} -->
            {{ entry.end_date | date: dateFormat }}

          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
        </tr>
        <tr mat-row class="table-element-row" *matRowDef="let entry; columns: displayedColumns;"
          (click)="onRowSelected(entry)" [ngClass]=" {'row-selected' : entry.id===selectedItem?.id}">
        </tr>
      </table>
    </section>


    @if(pagingData.length<=0){ <div class="no-data-container">
      <img src="assets/images/no-data.png">
      <span class="no-data-info">No data found. Please remove or redefine the filters if
        any.</span>
  </div>
  }
</div>

</div>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  sKey = "exsd2342Dsd";

  constructor(private http: HttpClient) { }

  /* Save Customer details in to session Storage*/
  saveToSession(key: string, data: any): void {
    var cdata =(data && environment.production==true)? CryptoJS.AES.encrypt(data.toString(), this.sKey).toString():data;
    sessionStorage.setItem(key, cdata);
  }

  /* Load Customer details from session Storage*/
  loadFromSession(key: string): any {
    var data = sessionStorage.getItem(key)
    if (data && environment.production==true) {
      const bytes = CryptoJS.AES.decrypt(data, this.sKey);
      data = bytes.toString(CryptoJS.enc.Utf8);
    }
    return data;
  }

  /* Clear Customer details from session Storage when LOGOUT*/
  clearSession() {
    sessionStorage.clear();
  }

  clearSessionByName(name: string): void {
    sessionStorage.removeItem(name);
  }
  /* Save Customer ID in to local Storage*/
  saveToLocalStorage(customerId: any): any {
    localStorage.setItem('customerId', customerId);
  }

  /* Load CustomerID from session Storage*/
  loadFromLocalStorage(): any {
    return localStorage.getItem('customerId');
  }

  /* Clear Customer ID from local Storage when LOGOUT*/
  clearlocalStorage() {
    localStorage.clear();
  }
}

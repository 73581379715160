import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
 selector: 'app-luckydraw-products-dialog',
 templateUrl: './luckydraw-products-dialog.component.html',
 styleUrls: ['./luckydraw-products-dialog.component.scss']
})
export class LuckyDrawProductsDialogComponent implements OnInit {
 dataSource: MatTableDataSource<any>;
 displayedColumns: string[] = ['select', 'name'];
 selection: SelectionModel<any>;
 browseBy: any;
 highlightedRows: any[] = [];
 showSelected: boolean = false;
 


 constructor(
    public dialogRef: MatDialogRef<LuckyDrawProductsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
 ) {
    this.dataSource = new MatTableDataSource<any>(data.items);
    this.selection = new SelectionModel<any>(true, data.selectedItems ?? []);
    this.browseBy = data.browseBy;
 }

 ngOnInit(): void {
    // If needed, add any initialization logic here

 }

 applyFilter(event: Event): void {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}


 isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
 }
 showSelectedItems(): void {
  if (this.showSelected) {
    // If checkbox is checked, update displayed data based on selected items
    this.dataSource.data = this.dataSource.data.filter(item => this.isRowSelected(item));
  } else {
    this.dataSource = new MatTableDataSource<any>(this.data.items);

}
}




 toggleAllRows(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
 }

 isRowSelected(row: any): boolean {
    return this.selection.isSelected(row);
 }

 selectRow(row: any): void {
  this.selection.clear(); // Clear previous selections
  this.selection.select(row); // Select the current row
  this.highlightedRows = [row]; // Update the list of highlighted rows
 }
  
 save(): void {
   const selectedItems = this.selection.selected;
   this.dialogRef.close({ selectedItems: selectedItems });
 }

 cancel(): void {
    this.dialogRef.close();
    
 }
}

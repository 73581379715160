<div class="system-function-component-container">
  <div class="page-header">
    <button mat-button class="nav-button" (click)="navBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>

    <div class="page-title">Set Permissions</div>

    <div class="action-btn-container" style="margin-right:10px;">
      <button mat-flat-button class="action-btn btn-discard" style="background:red;color:white;
     " (click)="navBack()">DISCARD</button> &nbsp;
      <button mat-flat-button class="action-btn btn-save" style="background:green;color:white"
        (click)="save()">SAVE</button>
    </div>
  </div>

  <div class="data-container">
    <app-loader [show]="isLoading"></app-loader>

    <div style="width: 70%;">
      <mat-form-field style="margin-left: 20px;">
        <mat-select placeholder="Select System Group" [(ngModel)]="selectedFunctionGroup"
          (ngModelChange)="updateDataSource()">
          <mat-option value="all">All</mat-option>
          <mat-option *ngFor="let group of groupFunctionList" [value]="group.system_group">
            {{ group.system_group }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container>
      <ng-container>
        <section class="table-container">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Function Column -->
            <ng-container matColumnDef="function_name">
              <th mat-header-cell *matHeaderCellDef> Function </th>
              <td mat-cell *matCellDef="let element">
                <span>{{ element.function_name }}</span>
              </td>
            </ng-container>

            <!-- View Column -->
            <ng-container matColumnDef="can_view">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox [(ngModel)]="selectAll.view" (change)="toggleAll('view')"></mat-checkbox>
                View
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.can_view" [disabled]="element.is_view_applicable === 0"
                  [ngClass]="{'grey-background': element.is_view_applicable === 0}"
                  [indeterminate]="element.can_view === null" (change)="checkboxChanged('view', element)">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Add Column -->
            <ng-container matColumnDef="can_add">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox [(ngModel)]="selectAll.add" (change)="toggleAll('add')"></mat-checkbox>
                Add
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.can_add" [disabled]="element.is_add_applicable === 0"
                  [ngClass]="{'grey-background': element.is_add_applicable === 0}"
                  [indeterminate]="element.can_add === null">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Edit Column -->
            <ng-container matColumnDef="can_edit">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox [(ngModel)]="selectAll.edit" (change)="toggleAll('edit')"></mat-checkbox>
                Edit
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.can_edit" [disabled]="element.is_edit_applicable === 0"
                  [ngClass]="{'grey-background': element.is_edit_applicable === 0}"
                  [indeterminate]="element.can_edit === null">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Delete Column -->
            <ng-container matColumnDef="can_delete">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox [(ngModel)]="selectAll.delete" (change)="toggleAll('delete')"></mat-checkbox> Delete
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.can_delete" [disabled]="element._applicable === 0"
                  [ngClass]="{'grey-background': element.is_delete_applicable === 0}"
                  [indeterminate]="element.can_delete === null">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Execute Column -->
            <ng-container matColumnDef="can_execute">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox [(ngModel)]="selectAll.execute" (change)="toggleAll('execute')"></mat-checkbox> Execute
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.can_execute" [disabled]="element.is_execute_applicable === 0"
                  [ngClass]="{'grey-background': element.is_execute_applicable === 0}"
                  [indeterminate]="element.can_execute === null">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Publish Column -->
            <ng-container matColumnDef="can_publish">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox [(ngModel)]="selectAll.publish" (change)="toggleAll('publish')"></mat-checkbox> Publish
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.can_publish" [disabled]="element.is_publish_applicable === 0"
                  [ngClass]="{'grey-background': element.is_publish_applicable === 0}"
                  [indeterminate]="element.can_publish === null">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Filter Column -->
            <ng-container matColumnDef="can_filter">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox [(ngModel)]="selectAll.filter" (change)="toggleAll('filter')"></mat-checkbox> Filter
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.can_filter" [disabled]="element.is_filter_applicable === 0"
                  [ngClass]="{'grey-background': element.is_filter_applicable === 0}"
                  [indeterminate]="element.can_filter === null">
                </mat-checkbox>
              </td>
            </ng-container>
            <!-- Export Column -->
            <ng-container matColumnDef="can_export">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox [(ngModel)]="selectAll.export" (change)="toggleAll('export')"></mat-checkbox> Export
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-checkbox [(ngModel)]="element.can_export" [disabled]="element.is_export_applicable === 0"
                  [ngClass]="{'grey-background': element.is_export_applicable === 0}"
                  [indeterminate]="element.can_export === null">
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Add more columns for other functions as needed -->

            <!-- Table Body -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </section>
      </ng-container>
    </ng-container>
  </div>
</div>
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RewardCategoryModel } from '../model/reward-category.model';
import { RewardCategoryService } from '../reward-category.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { trigger } from '@angular/animations';
 

@Component({
  selector: 'app-reward-category-edit',
  templateUrl: './reward-category-edit.component.html',
  styleUrls: ['./reward-category-edit.component.scss'],
  animations: [
    trigger('if', [
      // ... animation states and styles
    ])
  ]
})
export class RewardCategoryEditComponent   implements DirtyCheck{

  title: string = "New Reward Category";
  editForm: FormGroup;
  rewardCategoryInfo: RewardCategoryModel = { is_valid: 1 };
  isFormDirty: boolean = false;

  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private rewardCategoryService: RewardCategoryService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,) {

    let categoryId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.rewardCategoryInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.rewardCategoryInfo.name, [Validators.required, Validators.maxLength(50)]),
      description: this.builder.control(this.rewardCategoryInfo.description),
      is_valid: this.builder.control(this.rewardCategoryInfo.is_valid, [])
    });

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (categoryId !== null && parseInt(categoryId) !== 0) {
      this.setRewardCategoryInfo(categoryId);
    }
  }

  ngOnInit(): void { }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty():boolean{
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
   
      this.router.navigate(['/reward-category']);
    
  }

  /**
   * If tas is is null set an empty reward category info
   * if reward category id is not null call api to rtrive the info
   * @param categoryId 
   */
  setRewardCategoryInfo(categoryId: any): void {

    let requestData = { search: { filters: { simpleSearch: [], advSearch: [{ column: "id", operator: "=", value: categoryId }], scope: { limit: 10, offset: 0 } }, sort: [] } };
    let response = this.rewardCategoryService.getCategoryList(requestData).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.rewardCategoryInfo = response.data.categories[0];
          this.editForm.patchValue(this.rewardCategoryInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.rewardCategoryInfo.id == 0) ? "New Reward Category" : "Editing : " + this.rewardCategoryInfo.name;
    return title;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {
    if (!this.editForm.valid){ let snackBarData: SnackBarData = {
      message: "Some of fields are empty or conatains invalid data.",
      title: "Failed!!!",
      type: "error",
    }; 
    this.snackBarService.openSnackBar(snackBarData);
    return;}
    if (this.editForm.valid) {
      // this.rewardCategoryInfo = this.editForm.value;
      Object.assign(this.rewardCategoryInfo, this.editForm.value);
      this.rewardCategoryService.update(this.rewardCategoryInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.rewardCategoryInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }
  }
}

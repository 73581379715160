<div class="segment-component-container">
        <div class="page-header">
                <div class="page-title">Segments</div>
                <div class="page-actions">
                        <button mat-button color="primary" class="btn-add" (click)="addClicked()"><mat-icon>person_add</mat-icon>New</button>
                        <button mat-button color="accent" class="btn-export"> <mat-icon>save_alt</mat-icon>Export</button>
                </div>
        </div>
    
        <div class="data-container ">
            <app-loader [show]="isLoading"></app-loader>
                <!-- <app-search-filter [length]="pagingData.length" [pageSize]="pagingData.pageSize"
                        [pageSizeOptions]="[5,10,20,50,100]" [pageIndex]="pagingData.pageIndex" [filter]="filter"
                        (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)"  [hidePageSize]="true">
                </app-search-filter> -->
                <app-search-filter #searchFilter [pagingData]="pagingData" 
                [pageSizeOptions]="[5,10,20,50,100]" [filter]="filter"
                (page)="onePageEvent($event)" (onApply)="onFilterApplied($event)" [hidePageSize]="true">
        </app-search-filter>
                <ng-container *ngIf="pagingData.length>0; else nodata">
                        <ng-container *ngIf="!isHandset">
                                <section tabindex="0" class="table-container">
                                        <table matSort mat-table [dataSource]="segmentList"
                                                (matSortChange)="sortData($event)">
                                                <ng-container matColumnDef="code">
                                                        <th mat-sort-header="code" mat-header-cell  style="width: 80px;"
                                                                *matHeaderCellDef>Code</th>
                                                        <td mat-cell *matCellDef="let segment"  style="width: 80px;"> {{segment.code}}
                                                        </td>
                                                </ng-container>
    
                                                <ng-container matColumnDef="name">
                                                        <th mat-sort-header="name" mat-header-cell
                                                                *matHeaderCellDef> Name </th>
                                                        <td mat-cell *matCellDef="let segment"> {{segment.name}}
                                                        </td>
                                                </ng-container>
    
                                                <ng-container matColumnDef="recency">
                                                    <th mat-sort-header="recency" mat-header-cell
                                                            *matHeaderCellDef> Recency </th>
                                                    <td mat-cell *matCellDef="let segment"> {{segment.recency_rating?.name                                                }}
                                                    </td>
                                                </ng-container>
    
                                                <ng-container matColumnDef="frequency">
                                                    <th mat-sort-header="frequency" mat-header-cell
                                                            *matHeaderCellDef> Frequency </th>
                                                    <td mat-cell *matCellDef="let segment"> {{segment.frequency_rating?.name                                                }}
                                                    </td>
                                                </ng-container>
    
                                                <ng-container matColumnDef="monetary">
                                                    <th mat-sort-header="monetary" mat-header-cell
                                                            *matHeaderCellDef> Monetary </th>
                                                    <td mat-cell *matCellDef="let segment"> {{segment.monetary_rating?.name                                                }}
                                                    </td>
                                                </ng-container>
    
                                                <ng-container matColumnDef="loyalty_per_dollar">
                                                    <th mat-sort-header="loyalty_per_dollar" mat-header-cell
                                                            *matHeaderCellDef> LPD </th>
                                                    <td mat-cell *matCellDef="let segment"> {{segment.loyalty_per_dollar }}
                                                    </td>
                                                </ng-container>
    
                                                <ng-container matColumnDef="is_valid">
                                                    <th mat-sort-header="is_valid" mat-header-cell
                                                            *matHeaderCellDef> Active </th>
                                                    <td mat-cell *matCellDef="let segment"> 
                                                            <div *ngIf="segment.is_valid==1" class="status active">
                                                                    Yes
                                                            </div>
                                                            <div *ngIf="segment.is_valid!==1" class="status unpublished">
                                                                    No
                                                            </div>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="action" stickyEnd>
                                                    <th mat-header-cell *matHeaderCellDef> </th>
                                                    <td mat-cell class="row-menu-btn" style="width:50px"
                                                            *matCellDef="let rowItem">
                                                            <a mat-icon-button class="row-menu-btn"
                                                            [matMenuTriggerFor]="rowMenu"
                                                            (click)="onRowSelected(rowItem);$event.stopPropagation();">
                                                            <mat-icon>more_vert</mat-icon>
                                                    </a>
                                                            
                                                            <mat-menu #rowMenu>
                                                                    <button mat-menu-item
                                                                            (click)="onDelete(rowItem)">Delete</button>
                                                                    <button mat-menu-item
                                                                            (click)="onEdit(rowItem)">Edit</button>
                                                            </mat-menu>
                                                    </td>
                                            </ng-container>
    
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                                                </tr>
                                                <tr mat-row class="table-element-row"
                                                        *matRowDef="let segment; columns: displayedColumns;"
                                                        (click)="onRowSelected(segment)"
                                                        (dblclick)="onEdit(segment)"
                                                        [ngClass]=" {'row-selected' : segment.id===selectedItem?.id}">
                                                </tr>
                                        </table>
                                </section>
                        </ng-container>
    
                </ng-container>
                <ng-template #nodata>
                        <div class="no-data-container">
                                <img src="assets/images/no-data.png">
                                <span class="no-data-info">No data found. Please remove or redefine the filters if
                                        any.</span>
                        </div>
                </ng-template>
        </div>
    
    </div>
    <app-segment-summary [segment]="selectedItem" (onEdit)="onEdit($event)" (onDelete)="onDelete($event)"></app-segment-summary>
<mat-dialog-content class="filter-popup-content">
    <div class="filter-content">
        <ng-container *ngIf="selectedFilters.length>0; else nofilter">
            <div class="filter-content-container">
                <div class="filter-item-container" *ngFor="let filter of selectedFilters; let i = index">
                    <app-filter-item  class="filter-item-content"
                        [filter]="filter" [search]="selectedColumnFilter[i]"
                        (remove)="onFilterRemove(i)"></app-filter-item>
                    <div class="add-icon-container" (click)="onFilterAdd()">
                        <svg style="width: 32px; height:32px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                            id="plus">
                            <path fill="#595bd4"
                                d="M14.6602,0.0001 C18.0602,0.0001 20.0002,1.9201 20.0002,5.3301 L20.0002,5.3301 L20.0002,14.6701 C20.0002,18.0601 18.0702,20.0001 14.6702,20.0001 L14.6702,20.0001 L5.3302,20.0001 C1.9202,20.0001 0.0002,18.0601 0.0002,14.6701 L0.0002,14.6701 L0.0002,5.3301 C0.0002,1.9201 1.9202,0.0001 5.3302,0.0001 L5.3302,0.0001 Z M9.9902,5.5101 C9.5302,5.5101 9.1602,5.8801 9.1602,6.3401 L9.1602,6.3401 L9.1602,9.1601 L6.3302,9.1601 C6.1102,9.1601 5.9002,9.2501 5.7402,9.4001 C5.5902,9.5601 5.5002,9.7691 5.5002,9.9901 C5.5002,10.4501 5.8702,10.8201 6.3302,10.8301 L6.3302,10.8301 L9.1602,10.8301 L9.1602,13.6601 C9.1602,14.1201 9.5302,14.4901 9.9902,14.4901 C10.4502,14.4901 10.8202,14.1201 10.8202,13.6601 L10.8202,13.6601 L10.8202,10.8301 L13.6602,10.8301 C14.1202,10.8201 14.4902,10.4501 14.4902,9.9901 C14.4902,9.5301 14.1202,9.1601 13.6602,9.1601 L13.6602,9.1601 L10.8202,9.1601 L10.8202,6.3401 C10.8202,5.8801 10.4502,5.5101 9.9902,5.5101 Z"
                                transform="translate(2 2)" class="color200e32 svgShape"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #nofilter>
            <div class="empty-filter">
                <div class="empty-filter-contaiiner">
                    <img class="filter-icon" src="assets/images/no-filter.png">
                    <span class="no-filter-info">
                        No filters have been created yet. To create new filters, click on the <span
                            class="btn-add" (click)="onFilterAdd()">Add</span> button, or if you have saved filters, click on the <span
                            class="btn-load" (click)="onFilterLoad()">Load</span> button.
                    </span>
                </div>
            </div>
        </ng-template>
    </div>
    <!-- <div class="filter-footer"> 
    <button  [style.background]="selectedColumnFilter.length==0?'lightgray':null" class="filter-template-button save"
        mat-button color="warn" (click)="saveClicked()">Save</button>
    <button class="filter-template-button btn-load" mat-button color="warn" (click)="onFilterLoad()">Load </button>
    <button class="btn-reset" mat-button color="warn" (click)="onFilterReset()">Reset</button>
    <button class="btn-add" mat-button color="primary" (click)="onFilterAdd()">Add</button>
    <button class="btn-apply" mat-button color="accent" (click)="onFilterApply()">Apply</button>
    <button class="btn-apply" mat-button color="accent" (click)="cancel()">Cancel</button>
</div> -->
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer">
    <div class="filter-footer">
        <button [style.background]="selectedColumnFilter.length==0?'lightgray':null" class="filter-template-button save"
            mat-button color="warn" (click)="saveClicked()">Save</button>
        <button class="filter-template-button btn-load" mat-button color="warn" (click)="onFilterLoad()">Load </button>
        <button class="btn-reset" mat-button color="warn" (click)="onFilterReset()">Reset</button>
        <!-- <button class="btn-add" mat-button color="primary" (click)="onFilterAdd()">Add</button> -->
         <button class="btn-add" mat-button color="primary" (click)="onFilterRun()">Run</button>
        <button class="btn-apply" mat-button color="accent" (click)="onFilterApply()">Apply</button>
        <button class="btn-apply" mat-button color="accent" (click)="cancel()">Cancel</button>
    </div>

</mat-dialog-actions>
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from './confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private dialog: MatDialog) { }

  openDialog(dialogData: ConfirmDialogModel): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "400px",
        data: dialogData,
        disableClose:true
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
        resolve(dialogResult);
      });

    });
  }
}

export const environment = {
    production: false,
    baseUrl: 'https://uat.crm.mojosoft.co.in/ws',
    userId: 'crm',
    password: 'stpierresCRM123#@!uat',
    appKey:"e568458e9121478125d076b344e26c9e",
    assetsUrl:"https://uat.crm.mojosoft.co.in/resources",
    version:"UAT 0.0.1",
    buildNo:202411022217,
  };
  

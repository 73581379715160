import { Injectable } from "@angular/core";
import { UserModel } from "./model/user.model";
import { RequestData } from "src/app/common/models/request-data.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  /**
   *
   * @returns The list of users
   * Calls the API to get the list of users from server
   */
  getUserList(requestData: RequestData): Observable<any> {
    return this.http.post(environment.baseUrl + "/spuser/get", requestData);
  }
  getId(id: number): Observable<any> {
    return this.http.post(environment.baseUrl + "/spuser/getById/" + id, "");
  }
  /**
   *
   * @param userInfo
   * Calls the update API to insert/update the record
   */
  update(userInfo: UserModel): Observable<any> {
    if (userInfo.hasOwnProperty("id")) {
      return this.http.post(environment.baseUrl + "/spuser/update", userInfo);
    } else {
      return this.http.post(environment.baseUrl + "/spuser/add", userInfo);
    }
  }

    /**
   *
   * @param userInfo
   * Calls the API to update password the record
   */
   // New method to update only the password
   updatePassword(userId: number, newPassword: string): Observable<any> {
    const userInfo: UserModel = { id: userId, password: newPassword };
    return this.http.post(environment.baseUrl + "/spuser/updatePassword", userInfo);
  }
  /**
   *
   * @param userInfo
   * Calls the API to delete the record
   */
  delete(userInfo: UserModel): Observable<any> {
    return this.http.post(
      environment.baseUrl + "/spuser/delete/" + userInfo.id,
      userInfo
    );
  }
  downloadExcel(requestData: any): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // const url = 'http://yourdomain.com/api/customers/export'; // Adjust URL

    return this.http.post(environment.baseUrl + '/spuser/export', requestData, { headers, responseType: 'blob' })
      .pipe(
        //catchError(this.handleError)
      );

  }
}

<div class="master-option-bar-root-container" [style.width]="show ? 'var(--left-side-optionbar-width)' : '25px'">
    <div class="menu-list-container">
        <!-- Masters List -->
        <div class="section-container">
            <div class="content item-content">
                <!-- <mat-list-item class="mat-list-item" routerLink="/reward-category" [routerLinkActive]="['is-active']"
                    (click)="resetState()" [matTooltip]="!show ? 'Reward Category' : ''" matTooltipPosition="right"
                    matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/reward-category.svg">
                        <span class="master-title">Reward Category</span>
                    </div>
                </mat-list-item>
                
                <mat-list-item class="mat-list-item" routerLink="/users" [routerLinkActive]="['is-active']"
                    [matTooltip]="!show ? 'Users' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/user.svg">
                        <span class="master-title">Users</span>
                    </div>
                </mat-list-item>
                <mat-list-item class="mat-list-item" routerLink="/user-group" [routerLinkActive]="['is-active']"
                    [matTooltip]="!show ? 'User Groups' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                    <div class="mat-list-item-contents">
                        <img src="assets/images/menu/svg/user.svg">
                        <span class="master-title">User Groups</span>
                    </div>
                </mat-list-item> -->
                <mat-list-item class="mat-list-item" routerLink="/users" [routerLinkActive]="['is-active']"
                [matTooltip]="!show ? 'Draw' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                <div class="mat-list-item-contents">
                    <img src="assets/images/menu/svg/spinwin.svg">
                    <span class="master-title">Draw</span>
                </div>
            </mat-list-item>

                <mat-list-item class="mat-list-item" routerLink="/lucky-draw" [routerLinkActive]="['is-active']"
                [matTooltip]="!show ? 'Settings' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
                <div class="mat-list-item-contents">
                    <img src="assets/images/menu/svg/spinwin.svg">
                    <span class="master-title">Settings</span>
                </div>
            </mat-list-item>
    


        <mat-list-item class="mat-list-item" routerLink="/lucky-draw-entry" [routerLinkActive]="['is-active']"
        [matTooltip]="!show ? 'Lucky Draw Entry' : ''" matTooltipPosition="right" matTooltipClass="mat-tooltip">
        <div class="mat-list-item-contents">
            <img src="assets/images/menu/svg/spinwin.svg">
            <span class="master-title">Entries</span>
        </div>
    </mat-list-item>



            </div>
        </div>
    </div>
</div>
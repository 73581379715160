<div class="snack-bar-content">
    <div class="header">
        <div class="icon-container">
            <mat-icon *ngIf="data.type=='success'"> done_outline </mat-icon>
            <mat-icon *ngIf="data.type=='error'"> error_outline </mat-icon>
            <mat-icon *ngIf="data.type=='warn'"> notifications </mat-icon>
        </div>
        <span class="title">{{data.title}}</span>
        <button mat-icon-button class="close-button" (click)="closeSnackbar()">
            <mat-icon>close</mat-icon>
          </button>
    </div>
    <p class="information">
       
        {{ data.message }}
    </p>
</div>
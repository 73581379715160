<h1 mat-dialog-title class="edit-form-title">{{title}}</h1>
<mat-dialog-content class="edit-form-content">
    <form class="edit-form" [formGroup]="editForm" (ngSubmit)="save()">
        <div class="content-container">
            <mat-form-field class="code">
                <mat-label>Code</mat-label>
                <input matInput formControlName="code" maxlength="50">
                <mat-error *ngIf="editForm.controls['code'].hasError('maxlength')">Maximum length is 50.</mat-error>
            </mat-form-field>
            <mat-form-field class="name">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name">
                <mat-error *ngIf="editForm.controls['name'].hasError('maxlength')">Maximum length is 50.</mat-error>
            </mat-form-field>
            <mat-form-field class="description">
                <mat-label>Description</mat-label>
                <input matInput formControlName="description">
                <mat-error *ngIf="editForm.controls['description'].hasError('maxlength')">Maximum length is 200.</mat-error>
            </mat-form-field>            
            <div class="status-toggle-container">
                <span class="status-toggle-title">Active</span>
                <mat-slide-toggle formControlName="is_valid" class="status-toggle"></mat-slide-toggle>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="footer">
    <button mat-raised-button color="primary" (click)="save()">Save</button>
    <button mat-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>

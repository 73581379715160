<div class="action-control-container">
    <a *ngIf="menu.more"  class="floating-btn more-btn" (click)="toggleShowMore()">
        <!-- <div class="icon-container"> -->
            <mat-icon>more_horiz</mat-icon>
        <!-- </div> -->
    </a>
    <div *ngIf="showMore" class="show-more">
        <a *ngIf="menu.export"  class="floating-btn export-btn" (click)="exportClick()">
            <!-- <div class="icon-container"> -->
                <mat-icon>save_alt</mat-icon>
            <!-- </div> -->
        </a>
    </div>
    <a  *ngIf="menu.search"  class="floating-btn search-btn" (click)="searchClick()">
        <!-- <div class="icon-container"> -->
            <mat-icon>search</mat-icon>
        <!-- </div> -->
    </a>
    <a *ngIf="menu.add"  class="floating-btn add-btn" (click)="addClick()">
        <!-- <div class="icon-container"> -->
            <mat-icon>add</mat-icon>
        <!-- </div> -->
    </a>
</div>
<h1 mat-dialog-title>
   {{data.title}}
</h1>
<div mat-dialog-content>
  <p>{{ data.message }}</p>
</div>

<div mat-dialog-actions class="mdc-action-container">
  <div *ngIf="data.showActionButton">
  <button mat-button  (click)="onCancel()">Cancel</button>
  <button mat-button  mat-raised-button color="primary" color="primary" (click)="onConfirm()">Yes</button>
</div>
<div *ngIf="!data.showActionButton">
  <button mat-button  mat-button  mat-raised-button color="primary" color="primary" (click)="onCancel()">OK</button>
</div>

</div>